import React from 'react'

const TopLevelList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Executive Officer (CEO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Operating Officer (COO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Financial Officer (CFO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Medical Officer (CMO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Nursing Officer (CNO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Information Officer (CIO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Quality Officer (CQO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Human Resources Officer (CHRO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Strategy Officer (CSO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Technology Officer (CTO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Marketing Officer (CMO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Compliance Officer (CCO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Patient Experience Officer (CPEO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Legal Officer (CLO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Population Health Officer (CPHO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Innovation Officer (CIO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Research Officer (CRO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Administrative Officer (CAO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Communications Officer (CCO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Medical Informatics Officer (CMIO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Revenue Officer (CRO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>President</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President</li>
                            <li><i class="fa-solid fa-circle-check"></i>Executive Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Senior Vice President</li>
                            <li><i class="fa-solid fa-circle-check"></i>Associate Administrator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Administrator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Executive Vice President</li>
                            <li><i class="fa-solid fa-circle-check"></i>Regional Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>General Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Group Practice Administrator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hospital Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Division Chief</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Nursing Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director of Finance</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director of Operations</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director of Clinical Services</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director of Quality Improvement</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director of Patient Services</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director of Business Development</li>
                            <li><i class="fa-solid fa-circle-check"></i>Activities Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Administrative Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Admissions Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Assisted Living Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Behavioral Health Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Case Management Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Clinical Officer (CCO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Medical Director (CMD)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief Medical Information Officer (CMIO)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chief of Staff</li>
                            <li><i class="fa-solid fa-circle-check"></i>Clinic Office Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Clinical Data Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Clinical Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Clinical Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Clinician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Coding Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Diagnostic Imaging Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Dietary Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director, Administration</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director, Business Development</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director, Clinical Development</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director, Clinical Operations</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director, Clinical Pharmacology</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director, Clinical Services</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director, Customer Engagement</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director, Emergency Services</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director, Laboratory Services</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director, Nursing</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director, Patient Experience</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director, Resident Programs</li>
                            <li><i class="fa-solid fa-circle-check"></i>Director, Therapy Operations</li>
                            <li><i class="fa-solid fa-circle-check"></i>Executive Medical Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Field Reimbursement Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Health Information Management Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Healthcare Account Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Healthcare Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Home Health Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hospice Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hospital Chief Executive Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Laboratory Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Laboratory Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Managed Care Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Manager, Care Management</li>
                            <li><i class="fa-solid fa-circle-check"></i>Market Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Marketing and Admissions Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Memory Care Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Mental Health Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Nutrition Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Nutrition Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Operations Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Operations Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Patient Access Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Patient Access Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Patient Account Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Patient Safety Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Patient Services Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Physical Therapy Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Physician Practice Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Physician Relations Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Population Health Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Postdoctoral Fellow</li>
                            <li><i class="fa-solid fa-circle-check"></i>Practice Administrator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Practice Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pricing Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Program Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Program Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Program Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Project Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Quality Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>RN Care Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>RN Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Recreation Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Regional Business Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Rehabilitation Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Reimbursement Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Resident Care Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Resident Service Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Respiratory Therapy Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Revenue Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Revenue Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Service Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Social Services Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Treatment Center Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Unit Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Utilization Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Account Management</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Analytics</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Brand Marketing</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Client Services</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Clinical Operations</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Corporate Communications</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Digital Strategy</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Engineering</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Finance</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Growth</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Information Technology</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Operations</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, People</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Product Management</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Professional Services</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Quality</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Recruiting</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Research</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Safety</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Sales</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Strategic Partnerships</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vice President, Total Rewards</li>
                            <li><i class="fa-solid fa-circle-check"></i>Wellness Director</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopLevelList