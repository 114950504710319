import React from 'react'
import Header from '../Components/Common/Header'
import InnerBanner from '../Components/Common/InnerBanner'
import Affiliations from '../Components/Common/Affiliations'
import Footer from '../Components/Common/Footer'
import B2CEntryContent from '../Components/B2C/B2CEntryContent'
import KeyAttributesAccordionB2B from '../Components/B2C/KeyAttributesAccordionB2C'
import RecruimentProcess from '../Components/B2B/RecruimentProcess'
import B2BImageCard from '../Components/B2B/B2BImageCard'

const B2Cpage = () => {
    return (
        <>
            <Header />
            <InnerBanner title={'business to consumer'} menu={'Business To Consumer'} />
            <div className="section-padding">
                <div className="container">
                    <B2CEntryContent />
                    <B2BImageCard/>
                </div>
            </div>
            <KeyAttributesAccordionB2B/>
            <RecruimentProcess/>
            <Affiliations />
            <Footer />
        </>
    )
}

export default B2Cpage