import React from 'react';
import ConsultancyContent from '../../media/ConsultancyContent.json'
import apiCall from '../../modules/apiCall';

const TaxRepresentation = () => {
    const [data, setData] = React.useState([])
    React.useEffect(() => {
        apiCall("/comp/ConsultancyContent")
            .then((res) => console.log(res))
            .catch((err) => console.log(err))
    }, [])
    return (
        <>
            <div className="section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="entry-content">
                                <p>Our Tax representation services are designed to provide assistance and support to individuals or businesses facing tax-related issues or disputes with tax authorities. We offer this with the help our highly experienced&nbsp; tax professionals, including tax attorneys, enrolled agents, or certified public accountants (CPAs) with expertise in tax law and regulations.</p>
                            </div>
                        </div>
                    </div>
                    <div className="research-consultancy-list">
                        <div className="row">
                            {ConsultancyContent.taxrepresentation.map((item, i) => (
                                <div className="col-lg-6 g-5">
                                    <div className="concultancy-item-box" data-aos="fade-up" data-aos-duration="800">
                                        <div className="image-box color-dark image-box-style2">
                                            <div className="caption">
                                                <div className="thumb-service">
                                                    <div className="thumb" style={{
                                                        backgroundImage: `${item.bgimg}`
                                                    }}></div>
                                                </div>
                                                <div className="title-wrap">
                                                    <h3>{item.title}</h3>
                                                    <div className="content">
                                                        <p>{item.content}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default TaxRepresentation