import React from 'react'
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import AccordionItem1 from '../SubAccordionItem/Accordionitem1';
import CountryProgress from '../SubAccordionItem/CountryProgress';
import AccordionList2 from './AccordionList2';


const AccordionItem = ({ header, ...rest }) => (
    <Item
        {...rest}
        header={
            <>
                <div className='sub-chevron-icon'>
                    <i class="fa-solid fa-angles-down"></i>
                </div>
                {header}

            </>
        }
        className={"item"}
        buttonProps={{
            className: ({ isEnter }) =>
                `${"subitemBtn key"} ${isEnter && "itemBtnExpanded sub"}`,
        }}
        contentProps={{ className: "itemContent" }}
        panelProps={{ className: "itemPanel" }}
    />
);
const AccordionList1 = (props) => {
    return (
        <>
            <div className={"styles.accordion"}>
                <Accordion transition transitionTimeout={350}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            {props.child.map((item) => (
                                <AccordionItem header={item.SubHeading}>
                                  {!!item.matrix ? <CountryProgress child={item.SubChild} /> : !!item.submatrix ? <AccordionList2 child={item.SubChild} /> : <AccordionItem1 child={item.SubChild} />}
                                </AccordionItem>
                            ))}
                        </div>
                    </div>
                </Accordion>
            </div>
        </>
    )
}
export default AccordionList1

