import React from 'react'
import Header from '../Components/Common/Header'
import Affiliations from '../Components/Common/Affiliations'
import Footer from '../Components/Common/Footer'
import HeroSlider from '../Components/Cati/HeroSlider'
import CatiIntroduction from '../Components/Cati/CatiIntroduction'
import Industry from '../Components/Cati/Industry'
import Infrastructure from '../Components/Cati/Infrastructure'
import CatiCapabilities from '../Components/Cati/CatiCapabilities'
import CatiExpertise from '../Components/Cati/CatiExpertise'
import CatiOperation from '../Components/Cati/CatiOperation'
import Language from '../Components/Cati/Language'
import KeyBenifit from '../Components/Cati/KeyBenifit'
import CompanySlider from '../Components/Cati/CompanySlider'
import Experience from '../Components/Cati/Experience'
import OurQualitative from '../Components/Cati/OurQualitative'
import ProjectQuality from '../Components/Cati/ProjectQuality'
import CatiSupport from '../Components/Cati/CatiSupport'

const CatiPage = () => {
  return (
    <>
      <Header />
      <HeroSlider />
      <CatiIntroduction />
      <Industry />
      <Infrastructure />
      <CatiCapabilities />
      <CatiExpertise />
      <CatiOperation />
      <Language />
      <KeyBenifit />
      <CompanySlider />
      <Experience />
      <OurQualitative />
      <ProjectQuality />
      <CatiSupport />
      <Affiliations />
      <Footer />
    </>
  )
}

export default CatiPage