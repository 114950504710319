import React from 'react'
import Header from '../Components/Common/Header'
import InnerBanner from '../Components/Common/InnerBanner'
import CardImage from "../Components/TaxConsulting/CardImage";
import Affiliations from '../Components/Common/Affiliations'
import Footer from '../Components/Common/Footer'

const TaxConsultingPage = () => {
  return (
    <>
        <Header/>
        <InnerBanner title={'tax consulting'} menu={'Tax Consulting'}/>
        <CardImage/>
        <Affiliations/>
        <Footer/>
    </>
  )
}

export default TaxConsultingPage