import React from 'react'
import Header from '../Components/Common/Header'
import InnerBanner from '../Components/Common/InnerBanner'
import Affiliations from '../Components/Common/Affiliations'
import Footer from '../Components/Common/Footer'
import AnalyticCardImage from '../Components/AnalyticSolution/AnalyticCardImage'

const AnalyticSolutionPage = () => {
  return (
    <>
        <Header/>
        <InnerBanner title={'analytics solutions'} menu={'Analytics Solution'}/>
        <AnalyticCardImage/>
        <Affiliations/>
        <Footer/>
    </>
  )
}

export default AnalyticSolutionPage