import React from 'react'
import ProcessItem from './ProcessItem'

const Process = () => {
    return (
        <>
            <div className="" id='process'>
                <div className="container">
                    <div className="row">
                        <div className="col-12" data-aos="fade-down" data-aos-duration="800">
                            <div className="section-heading light text-center">
                                <h5>how we work</h5>
                                <h2>our <span>process</span></h2>
                            </div>
                        </div>
                    </div>

                    <ProcessItem />
                </div>
            </div>
        </>
    )
}

export default Process