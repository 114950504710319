import React from 'react'

const LifestyleList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Hobbies &amp; Interests</li>
                            <li><i class="fa-solid fa-circle-check"></i>Leisure Activities</li>
                            <li><i class="fa-solid fa-circle-check"></i>Movie Genre</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radio Listner</li>
                            <li><i class="fa-solid fa-circle-check"></i>News Paper /Magazines Readers</li>
                            <li><i class="fa-solid fa-circle-check"></i>Book/Novles Reader</li>
                            <li><i class="fa-solid fa-circle-check"></i>Preferred types of music</li>
                            <li><i class="fa-solid fa-circle-check"></i>Frequency of cinema visits</li>
                            <li><i class="fa-solid fa-circle-check"></i>DVD purchase / month</li>
                            <li><i class="fa-solid fa-circle-check"></i>Intensity of sport / week</li>
                            <li><i class="fa-solid fa-circle-check"></i>Practiced sports disciplines</li>
                            <li><i class="fa-solid fa-circle-check"></i>Gambling practice</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sports equipment owned or intend to purchase</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sports participation</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sporting events</li>
                            <li><i class="fa-solid fa-circle-check"></i>Live performance (i.e., music, theatre, dance, etc.)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LifestyleList