import React from "react";
import Career from '../../media/career.json'
import Image from "../Common/Image";
import { HashLink } from "react-router-hash-link";

const CareerInfo = () => {
  return (
    <>
      {Career.career.map((n, i) => {
        return (
          <>
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 g-5">
              <div className="career-info-item" data-aos="fade-up" data-aos-duration="800">
                <HashLink smooth to={'#applyForm'}>
                  <button>
                    <div className="icon">
                      <Image folder="career" link={n.link} />
                    </div>
                    <div className="title">
                      <h4>{n.name} </h4>
                    </div>
                  </button>
                </HashLink>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default CareerInfo;
