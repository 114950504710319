import React from 'react'
import ConsultancyContent from '../../media/ConsultancyContent.json'
import apiCall from '../../modules/apiCall'

const TaxPlanning = () => {
    const [data, setData] = React.useState([])
    React.useEffect(() => {
        apiCall("/comp/ConsultancyContent")
            .then((res) => console.log(res))
            .catch((err) => console.log(err))
    }, [])
    return (
        <>
            <div className="section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="entry-content">
                                <p>Our Tax consultants team work closely with you to develop effective tax planning strategies. They analyse financial situations, business operations, and investment decisions to identify opportunities for minimizing tax liabilities legally. This involve structuring business transactions, optimizing deductions and credits, and exploring tax incentives or exemptions.</p>

                                <p>Our tax planning services encompass various strategies and techniques aimed at minimizing tax liabilities and maximizing after-tax income. Here are some common types of tax planning services we offer :</p>
                            </div>
                        </div>
                    </div>
                    <div className="research-consultancy-list">
                        <div className="row">
                            {ConsultancyContent.taxplanning.map((item, i) => (
                                <div className="col-lg-6 g-5" key={i}>
                                    <div className="concultancy-item-box" data-aos="fade-up" data-aos-duration="800">
                                        <div className="image-box color-dark image-box-style2">
                                            <div className="caption">
                                                <div className="thumb-service">
                                                    <div className="thumb" style={{
                                                        backgroundImage: `${item.bgimg}`
                                                    }}></div>
                                                </div>
                                                <div className="title-wrap">
                                                    <h3>{item.title}</h3>
                                                    <div className="content">
                                                        <p>{item.content}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TaxPlanning