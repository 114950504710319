import React, { useState } from 'react'

import 'swiper/css/swiper.css';
import Swiper from 'react-id-swiper';

// custom css
import './BannerSlider.css';

// slider configuration
const HeroSliderConfigs = {
    containerClass: 'swiper-container hero-slider',
    parallax: true,
    centeredSlides: true,
    speed: 2000,
    spaceBetween: 0,
    autoplay:
    {
        delay: 5000,
    },
    loop: true,
    effect: 'slide'
};

const BannerSlide = () => {
    const [parallaxSwiper, setParallaxSwiper] = useState(null);
    const parallaxAmount = parallaxSwiper ? parallaxSwiper.width * 0.95 : 0;
    const parallaxOpacity = 0.5;
    return (
        <Swiper {...HeroSliderConfigs} getSwiper={setParallaxSwiper}>
            {/* 1 */}
            <div className="hero-slide">
                <div
                    className="slide-image"
                    data-swiper-parallax={parallaxAmount}
                    data-swiper-parallax-opacity={parallaxOpacity}
                >
                    <img src="assets/img/bannerslide/r1.webp" alt="image1"></img>
                </div>
                <div className="col-md-10 text-center text-white" data-aos="fade-up" data-aos-duration="800">
                    <h1 className="text-uppercase mb-2 font-weight-bold">Best Expert Market Research <br/>Consultancy Services</h1>
                    <p className="mb-5 small">We deliver insights by combining Research Outcomes, Real-Time Data and Smart Thinking to enable you to make insightful decisions.We transform your Research into Actionable Strategies with our trusted & Highly Experienced Consultancy Expertise
                    </p>
                </div>
            </div>
            {/* 2 */}
            <div className="hero-slide">
                <div
                    className="slide-image"
                    data-swiper-parallax={parallaxAmount}
                    data-swiper-parallax-opacity={parallaxOpacity}
                >
                    <img src="assets/img/bannerslide/r2.webp" alt="image2"></img>
                </div>
                <div className="col-md-10 text-center text-white">
                    <h1 className="text-uppercase mb-2 font-weight-bold">Qualitative & Quantitative <br/>Research Design</h1>
                    <p className="mb-5 small">
                        We design your Research for Impactful Decision-making with our trusted Qualitative & Quantitative Solutions
                    </p>
                </div>
            </div>
            {/* 3 */}
            <div className="hero-slide">
                <div
                    className="slide-image"
                    data-swiper-parallax={parallaxAmount}
                    data-swiper-parallax-opacity={parallaxOpacity}
                >
                    <img src="assets/img/bannerslide/r3.webp" alt="image3"></img>
                </div>
                <div className="col-md-10 text-center text-white">
                    <h1 className="text-uppercase mb-2 font-weight-bold">Experienced & Dynamic Mind <br/>Teams</h1>
                    <p className="mb-5 small">
                        We have a dedicated and experienced team who are well trained, skilled and capable of providing high quality services.
                    </p>
                </div>
            </div>

            {/* 4 */}
            <div className="hero-slide">
                <div
                    className="slide-image"
                    data-swiper-parallax={parallaxAmount}
                    data-swiper-parallax-opacity={parallaxOpacity}
                >
                    <img src="assets/img/bannerslide/r4.webp" alt="image3"></img>
                </div>
                <div className="col-md-10 text-center text-white">
                    <h1 className="text-uppercase mb-2 font-weight-bold">Survey <br/>Scripting & Hosting </h1>
                    <p className="mb-5 small">
                        We Simplify Survey Creation: Get Expert Programming for Reliable Data Collection with us
                    </p>
                </div>
            </div>

            {/* 6 */}
            <div className="hero-slide">
                <div
                    className="slide-image"
                    data-swiper-parallax={parallaxAmount}
                    data-swiper-parallax-opacity={parallaxOpacity}
                >
                    <img src="assets/img/bannerslide/r6.webp" alt="image3"></img>
                </div>
                <div className="col-md-10 text-center text-white">
                    <h1 className="text-uppercase mb-2 font-weight-bold">100% Human Translation with <br/>1 year Guarantee</h1>
                    <p className="mb-5 small">
                        Frequent Research Solutions having the wide experience of all Indian and Foreign languages.
                    </p>
                </div>
            </div>

            {/* 7 */}
            <div className="hero-slide">
                <div
                    className="slide-image"
                    data-swiper-parallax={parallaxAmount}
                    data-swiper-parallax-opacity={parallaxOpacity}
                >
                    <img src="assets/img/bannerslide/r7.webp" alt="image3"></img>
                </div>
                <div className="col-md-10 text-center text-white">
                    <h1 className="text-uppercase mb-2 font-weight-bold">Online Data Collection <br/>(Panel Sampling & CAWI)</h1>
                    <p className="mb-5 small">
                        We unlock your Potential reach for your choice audience through our Global Audience: Get Reliable Data Collection with us at your Fingertips
                    </p>
                </div>
            </div>

            {/* 8 */}
            <div className="hero-slide">
                <div
                    className="slide-image"
                    data-swiper-parallax={parallaxAmount}
                    data-swiper-parallax-opacity={parallaxOpacity}
                >
                    <img src="assets/img/bannerslide/r8.webp" alt="image3"></img>
                </div>
                <div className="col-md-10 text-center text-white">
                    <h1 className="text-uppercase mb-2 font-weight-bold">CATI <br/>Computer Assisted Telephone Interviewing </h1>
                    <p className="mb-5 small">
                        We provide Personalized, Proactive ,Quality & Meaningful CATI Data Collection Services for Actionable Results
                    </p>
                </div>
            </div>

            {/* 9 */}
            <div className="hero-slide">
                <div
                    className="slide-image"
                    data-swiper-parallax={parallaxAmount}
                    data-swiper-parallax-opacity={parallaxOpacity}
                >
                    <img src="assets/img/bannerslide/r13.webp" alt="image3"></img>
                </div>
                <div className="col-md-10 text-center text-white">
                    <h1 className="text-uppercase mb-2 font-weight-bold">Data Processing & <br/>Analysis Solutions</h1>
                    <p className="mb-5 small">
                        We serve Precision, Accuracy, Actionability & Professional Data Processing and Analysis Solution
                    </p>
                </div>
            </div>

            {/* 10 */}
            <div className="hero-slide">
                <div
                    className="slide-image"
                    data-swiper-parallax={parallaxAmount}
                    data-swiper-parallax-opacity={parallaxOpacity}
                >
                    <img src="assets/img/bannerslide/r11.webp" alt="image3"></img>
                </div>
                <div className="col-md-10 text-center text-white">
                    <h1 className="text-uppercase mb-2 font-weight-bold">Interactive Data Charts & <br/>Dynamic Graphs</h1>
                    <p className="mb-5 small">
                        We Transform Complexity into Clarity with our Interactive Data Charts & Graphic Solutions
                    </p>
                </div>
            </div>

            {/* 11 */}
            <div className="hero-slide">
                <div
                    className="slide-image"
                    data-swiper-parallax={parallaxAmount}
                    data-swiper-parallax-opacity={parallaxOpacity}
                >
                    <img src="assets/img/bannerslide/r4.webp" alt="image3"></img>
                </div>
                <div className="col-md-10 text-center text-white">
                    <h1 className="text-uppercase mb-2 font-weight-bold">Professional Software Development Solutions</h1>
                    <p className="mb-5 small">
                        We customized Software Solutions for your unique needs and provide comprehensive Software Development solutions
                    </p>
                </div>
            </div>

            {/* 12 */}
            <div className="hero-slide">
                <div
                    className="slide-image"
                    data-swiper-parallax={parallaxAmount}
                    data-swiper-parallax-opacity={parallaxOpacity}
                >
                    <img src="assets/img/bannerslide/r12.webp" alt="image3"></img>
                </div>
                <div className="col-md-10 text-center text-white">
                    <h1 className="text-uppercase mb-2 font-weight-bold">Responsive and Future-proof <br/>Website Solutions</h1>
                    <p className="mb-5 small">
                        Elevate Your Online Presence with our expert Website Development Solutions
                    </p>
                </div>
            </div>

            {/* 13 */}
            <div className="hero-slide">
                <div
                    className="slide-image"
                    data-swiper-parallax={parallaxAmount}
                    data-swiper-parallax-opacity={parallaxOpacity}
                >
                    <img src="assets/img/bannerslide/r7.webp" alt="image3"></img>
                </div>
                <div className="col-md-10 text-center text-white">
                    <h1 className="text-uppercase mb-2 font-weight-bold">Digital Marketing Solutions</h1>
                    <p className="mb-5 small">
                    Accelerate Your Online Growth with our Results-Driven Digital Marketing Solutions.We Provide Powerhouse Solutions for your Digital Marketing Success
                    </p>
                </div>
            </div>

        </Swiper>
    );
}

export default BannerSlide