import React from 'react'

const OrganizationList = () => {
  return (
    <>
         <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i className="fa-solid fa-circle-check"></i>Micro</li>
                            <li><i className="fa-solid fa-circle-check"></i>Small</li>
                            <li><i className="fa-solid fa-circle-check"></i>Medium</li>
                            <li><i className="fa-solid fa-circle-check"></i>Large</li>
                            <li><i className="fa-solid fa-circle-check"></i>Very Large</li>
                        </ul>
                    </div>
                </div>
            </div>
    </>
  )
}

export default OrganizationList