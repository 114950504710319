import React from 'react'

const AccordionItem1 = (props) => {
  return (
    <>
      {props.child.map((item) => (
        <p>{item}</p>
      ))}
    </>
  )
}

export default AccordionItem1