import React from 'react'
import IndustryCard from './IndustryCard';

const Industry = () => {
    return (
        <div className="section-padding-skew-2" id='industry'>
            <div className='container'>
                <div className="row">
                    <div className="col-12" data-aos="fade-down" data-aos-duration="800">
                        <div className="section-heading text-center">
                            <h2>industry we <span>cover</span></h2>
                        </div>
                    </div>

                    <IndustryCard/>
                </div>
            </div>
        </div>
    );
}

export default Industry