export default class API {
    #headers = {
        "Content-Type": "application/json"
    };
    #endpoint = "";
    constructor() {
        let token = localStorage.getItem("token");
        if (!token) return;
        this.header = {
            Authorization: `Bearer ${token}`
        }
    }
    async execute(path, method = "GET", data = null) {
        // if (method!=="GET" && !data) return false;
        let options = {
            method,
            headers: this.headers,
        }
        if (!!data)
            Object.assign(options, { body: JSON.stringify(data) });
        const url = `${this.endpoint}${path}`;
        // console.log("making api call");
        let resp = (await (await fetch(url, options)).json())
        if (!!resp.message && resp.message === "Invalid Token") {
            localStorage.removeItem("token");
            // location.reload()
        }
        return resp;
    }
    async get(e) {
        return this.execute(e)
    }
    async post(e, t) {
        return this.execute(e, "POST", t)
    }
    async put(e, t) {
        return this.execute(e, "PUT", t)
    }
    async patch(e, t) {
        return this.execute(e, "PATCH", t)
    }
    async delete(e, t) {
        return this.execute(e, "DELETE", t)
    }
    async copy(e, t) {
        return this.execute(e, "COPY", t)
    }
    async getHTML(e) {
        return (await fetch(e)).text()
    }
    get headers() {
        return this.#headers
    }
    set headers(e) {
        this.#headers = e
    }
    get endpoint() {
        return this.#endpoint
    }
    set endpoint(e) {
        this.#endpoint = e
    }
    set header(e) {
        Object.assign(this.#headers, e)
    }
}