import React from 'react'

const TravelList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Business vs. pleasure travel</li>
                            <li><i class="fa-solid fa-circle-check"></i>Travel planning responsibility</li>
                            <li><i class="fa-solid fa-circle-check"></i>Method of travel planning and booking</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vacation destinations</li>
                            <li><i class="fa-solid fa-circle-check"></i>Travel activities</li>
                            <li><i class="fa-solid fa-circle-check"></i>Frequency of domestic and international travel</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hotels/motels</li>
                            <li><i class="fa-solid fa-circle-check"></i>Frequency of airline travel</li>
                            <li><i class="fa-solid fa-circle-check"></i>Frequent flyer membership rental</li>
                            <li><i class="fa-solid fa-circle-check"></i>Car rental companies</li>
                            <li><i class="fa-solid fa-circle-check"></i>Airlines</li>
                            <li><i class="fa-solid fa-circle-check"></i>Frequency of car</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cruise lines</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TravelList