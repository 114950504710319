import React from 'react'
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import AccordionItem1 from '../SubAccordionItem/Accordionitem1';
import CountryProgress from '../SubAccordionItem/CountryProgress';

const AccordionItem = ({ header, ...rest }) => (
    <Item
        {...rest}
        header={
            <>
                <div className='sub-chevron-icon'>
                <i class="fa-solid fa-angle-right"></i>
                </div>
                {header}

            </>
        }
        className={"item"}
        buttonProps={{
            className: ({ isEnter }) =>
                `${"subitemBtn matrics"} ${isEnter && "itemBtnExpanded matrics"}`,
        }}
        contentProps={{ className: "itemContent" }}
        panelProps={{ className: "itemPanel" }}
    />
);
const AccordionList2 = (props) => {
    return (
        <>
            <div className={"styles.accordion"}>
                <Accordion transition transitionTimeout={350}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            {props.child.map((item) => (
                                <AccordionItem header={item.ChildHeading}>
                                    <CountryProgress child={item.SubChild} />
                                </AccordionItem>
                            ))}

                        </div>
                    </div>
                </Accordion>
            </div>
        </>
    )
}
export default AccordionList2

