import React from 'react'
import operations from "../../media/cati/cati_operations.json"
import Image from '../Common/Image'
const CatiOperation = () => {
    return (
        <>
            <div className="section-padding" id='cati-operation'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading text-center">
                                <h2>CATI Operations & QC Procedure</h2>
                                <p>Our typical CATI /CAWI project operation consists of following steps Setup</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        {operations.Operations.Components.map((item) => (
                            <div className="col-sm-6 col-lg-3 g-5">
                                <div className="img-item">
                                    <div className="img">
                                        <Image link={item.img} folder="cati" className="img-fluid" />
                                    </div>
                                    <div className="content">
                                        <h6>{item.heading}</h6>
                                        <p>{item.paragraph}</p>
                                    </div>
                                </div>
                            </div>

                        ))}


                    </div>
                </div>
            </div>
        </>
    )
}

export default CatiOperation