import React, { useContext, useState } from 'react'
import submitForm from "../../modules/submitForm";
import { PopupContext } from '../../PopupContext';
import SuccessPopupCareer from '../Popup/SuccessPopupCareer';
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Common/Loader';
import ErrorPopupCareer from '../Popup/ErrorPopupCareer';


const ApplyForm = () => {
  const popup = useContext(PopupContext)
  const [loading, setloading] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [resume, setresume] = useState("")
  const [message, setMessage] = useState("")
  const [error, setError] = useState("")

  const handleSubmit = async (e) => {
    let frm = e.target.form;
    if (!frm) return console.log("misconfigured");
    const action = frm.getAttribute("action");
    const method = frm.getAttribute("method") || "post";
    let data = {};
    let errors = [];
    frm.querySelectorAll("[name]").forEach((fld) => {
      if (!fld.validity.valid) errors.push(fld);
      if (["checkbox", "radio"].indexOf(fld.type) === -1) { data[fld.name] = fld.value; return; }
      if (fld.checked) data[fld.name] = fld.value
    });
    if (errors.length) {
      errors[0].focus();
      return false;
    }
    if (resume === "") {
      errors.push(fld)
    }
    data.file = resume
    setloading(true)
    const result = await axios.post(
      "http://frequent.localhost/mail/send/career",
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    )
    setloading(false)
    if (result.data.status === "success") {
      popup.handleSuccessToggle()
    } else if (result.data.status === "error") {
      popup.handleErrorToggle()
    }
  };

  const handleFile = (e) => {
    const { size, type } = e.target.files[0]
    if (size > 5000000) {
      setError("File Size Exceeds Limits**")
    } else if (type !== "application/pdf") {
      setError("File Type Not Expected**")
    } else if (size <= 5000000 && type === "application/pdf") {
      setError("")
      setresume(e.target.files[0])
    }
  }

  return (
    <>
      <ToastContainer position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light" />
      <SuccessPopupCareer {...popup} />
      <ErrorPopupCareer {...popup} />
      <div className="col-md-6 g-5" id="applyForm">
        <div class="contact-form ms-lg-4" data-aos="fade-up" data-aos-duration="800">
          <h4>Apply Today</h4>
          <form id="contact-from" method={"post"}
            action={"/mail/send/career"}
            onSubmit={(e) => {
              e.preventDefault();
            }} className="mt-5">
            <div className="mb-3">
              <frfs-text label="Full Name" name="name" placeholder="Please Enter Your Name" required="true" editable="true" />
            </div>
            <div className="mb-3">
              <frfs-email label="Email Address" name="email" required="true" placeholder="Please Enter Your Email" editable="true" />
            </div>

            <div className="mb-3">
              <frfs-tel label="Mobile Number" name="phone" cn="in" placeholder="Please Enter Your Contact Number" editable="true" required="true" />
            </div>
            <div className="mb-3">
              <frfs-text label="Designation" name="designation" placeholder="Please Enter Your Designation" required="true" editable="true" />
            </div>
            <div className="mb-3">
              <label>Upload Resume</label>
              <input
                type="file"
                name="resume"
                accept="application/pdf"
                required
                placeholder="Your CV"
                onChange={handleFile}
              />
            </div>
            <span className="error">{error}</span>

            <div className="mb-3">
              <frfs-text label="Message" name="message" placeholder="Please Enter Your Message" required="true" editable="true" />
            </div>

            <div className="mb-3">
              <button
                className="form-btn"
                type="button"
                disabled={loading}
                onClick={handleSubmit}
              > {loading ?
                <span>
                  Submit
                  <Loader />
                </span>
                :
                "submit"
                }
              </button>
            </div>
          </form>
        </div >
      </div >
    </>
  )
}

export default ApplyForm
