import React from 'react'

const AgeList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i className="fa-solid fa-circle-check"></i>18-24 years</li>
                            <li><i className="fa-solid fa-circle-check"></i>25-34 years</li>
                            <li><i className="fa-solid fa-circle-check"></i>35-44 years</li>
                            <li><i className="fa-solid fa-circle-check"></i>45-54 years</li>
                            <li><i className="fa-solid fa-circle-check"></i>55-64 years</li>
                            <li><i className="fa-solid fa-circle-check"></i>65+ years</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AgeList