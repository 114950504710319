import React from 'react'

const FooterBottom = () => {
    return (
        <>
            <div className="">
                <div className="row footer-bottom">
                    <div className="col-md-12">
                        <div className="footer-copyright text-center">
                            <p>Copyright 2017 - {new Date().getFullYear()} © Frequent Research Fieldwork
                                Solutions Private Limited. All Right Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FooterBottom