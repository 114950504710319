import React,{useContext}from 'react'
import { PopupContext } from '../../PopupContext'
const SuccessPopupEnquiry = (props) => {
    return (
        <>
            <div class="success-popup-body" style={{ display: props.showSuccess ? "block" : "none" }}>
                <div className="popup-success">
                    <div className="head">
                        <h3>Thank You</h3>
                    </div>
                    <div className="body">
                        <i class="fa-solid fa-hands-praying"></i>
                        <p>Your submission has been received. Our Sales Representative will contact you soon.</p>
                        <p>Or You can mail your new bid at <a href="mailto:bids@frequentresearch.com">bids@frequentresearch.com</a></p>
                        <button type='button' className='success-close' onClick={()=>{props.handleSuccessToggle()}}>OK</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SuccessPopupEnquiry