import React from 'react'

const MaintenanceList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Maintenance Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Facilities Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Maintenance Supervisor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Maintenance Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>HVAC Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Electrician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Plumber</li>
                            <li><i class="fa-solid fa-circle-check"></i>Carpenter</li>
                            <li><i class="fa-solid fa-circle-check"></i>Painter</li>
                            <li><i class="fa-solid fa-circle-check"></i>General Maintenance Worker</li>
                            <li><i class="fa-solid fa-circle-check"></i>Boiler Operator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Groundskeeper</li>
                            <li><i class="fa-solid fa-circle-check"></i>Equipment Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Biomedical Equipment Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Building Engineer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Fire Systems Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Energy Management Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Locksmith</li>
                            <li><i class="fa-solid fa-circle-check"></i>Roofing Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pest Control Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Elevator Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Irrigation Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Audiovisual Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Landscaping Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Welder</li>
                            <li><i class="fa-solid fa-circle-check"></i>Generator Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Building Automation Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Carpentry Assistant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Electrical Assistant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Plumbing Assistant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Painting Assistant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Mechanical Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Energy Conservation Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Refrigeration Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Systems Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Facilities Planner</li>
                            <li><i class="fa-solid fa-circle-check"></i>Maintenance Scheduler</li>
                            <li><i class="fa-solid fa-circle-check"></i>Equipment Maintenance Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Maintenance Project Coordinator</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MaintenanceList