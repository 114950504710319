import React from 'react'

const PharmacyList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Director of Pharmacy</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacist-in-Charge</li>
                            <li><i class="fa-solid fa-circle-check"></i>Clinical Pharmacist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Staff Pharmacist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Supervisor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Assistant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medication Safety Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Informatics Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Drug Utilization Review Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Oncology Pharmacist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Infectious Diseases Pharmacist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Critical Care Pharmacist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Ambulatory Care Pharmacist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Transplant Pharmacist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Retail Pharmacy Manager (if the hospital has an on-site retail pharmacy)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Investigational Drug Pharmacist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Operations Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medication Reconciliation Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Clinical Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Residency Program Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Anticoagulation Clinic Pharmacist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pediatric Pharmacist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Geriatric Pharmacist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Psychiatric Pharmacist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Outpatient Pharmacy Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Automation Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Purchasing Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Business Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Billing Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Compounding Pharmacist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Compliance Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Analytics Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Research Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Technician Supervisor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medication Therapy Management Pharmacist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Quality Assurance Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Regulatory Affairs Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Benefits Manager</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PharmacyList