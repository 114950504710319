import React from 'react'
import ConsultancyContent from '../../media/ConsultancyContent.json'
import apiCall from '../../modules/apiCall'

const AdvanceAnalytic = () => {
    const [data, setData] = React.useState([])
    React.useEffect(() => {
        apiCall("/comp/ConsultancyContent")
            .then((res) => console.log(res))
            .catch((err) => console.log(err))
    }, [])
    return (
        <>
            <div className="section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="entry-content">
                                <p>Our Diagnostic analytics team aims to answer the question of why certain events or outcomes occurred. It involves analysing data to identify the root causes of problems or anomalies. Our Diagnostic analytics techniques include data drilling, root cause analysis, and hypothesis testing to gain insights into factors influencing specific outcomes.</p>

                                <p>Our diagnostic analytics services involve analyzing data to understand why certain events or outcomes occurred in the past. This process are done to focus on identifying the root causes of problems, investigating anomalies, and providing deeper insights into data patterns.</p>
                            </div>
                        </div>
                    </div>
                    <div className="research-consultancy-list">
                        <div className="row">
                            {ConsultancyContent.advanceanalytics.map((item, i) => (
                                <div className="col-lg-6 g-5" key={i}>
                                    <div className="concultancy-item-box" data-aos="fade-up" data-aos-duration="800">
                                        <div className="image-box color-dark image-box-style2">
                                            <div className="caption">
                                                <div className="thumb-service">
                                                    <div className="thumb" style={{
                                                        backgroundImage: `${item.bgimg}`
                                                    }}></div>
                                                </div>
                                                <div className="title-wrap">
                                                    <h3>{item.title}</h3>
                                                    <div className="content">
                                                        <p>{item.content}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdvanceAnalytic