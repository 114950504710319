import React from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';

const FooterTopCard = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <>
            <div className="row footer-above">
                <div className="col-12 col-md-4">
                    <div className="footer-card">
                        <div className="call d-flex align-items-center h-100">
                            <div className="icon">
                                <i className="fa-solid fa-phone-volume d-flex justify-content-center align-items-center"></i>
                            </div>
                            <div className="title ps-4">
                                <h4>Call us</h4>
                                <a href="tel:+91 7678151292">+91 7678151292 , </a>
                                <a href="tel:+91 8130015743">+91 8130015743</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-4">
                    <div className="footer-card">
                        <div className="call d-flex align-items-center h-100">
                            <div className="icon">
                            <i className="fa-solid fa-envelope d-flex justify-content-center align-items-center"></i>
                            </div>
                            <div className="title ps-4">
                                <h4>write to us</h4>
                                <a href="mailto:bids@frequentresearch.com">bids@frequentresearch.com</a> <br />
                                <a href="mailto:info@frequentresearch.com">info@frequentresearch.com</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-4">
                    <div className="footer-card">
                        <div className="call d-flex align-items-center h-100">
                            <div className="icon">
                            <i className="fa-solid fa-location-dot d-flex justify-content-center align-items-center"></i>
                            </div>
                            <div className="title ps-4">
                                <h4>address</h4>
                                <a href="https://goo.gl/maps/terJSSQngLHM9pk19" target="_blank">B 96, Pushpanjali Enclave, Pitampura
                                <br/> Delhi - 110034</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FooterTopCard