import React from 'react'

const RevenueList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul className="multy-list with-icon">
                            <li><b>Micro Enterprise</b>
                                <ul>
                                    <li><span><img decoding="async" src="assets/img/money.jpg" alt="company revenue" /></span>Less than $100,000</li>
                                    <li><span><img decoding="async" src="assets/img/money.jpg" alt="company revenue" /></span>$100,000 to $500,000</li>
                                    <li><span><img decoding="async" src="assets/img/money.jpg" alt="company revenue" /></span>$500,000 to $1 million</li>
                                    <li><span><img decoding="async" src="assets/img/money.jpg" alt="company revenue" /></span>$1 million to $5 million</li>
                                    <li><span><img decoding="async" src="assets/img/money.jpg" alt="company revenue" /></span>More than $5 Million</li>
                                </ul>
                            </li>
                            <li><b>Small Enterprise</b>
                                <ul>
                                    <li><span><img decoding="async" src="assets/img/money.jpg" alt="company revenue" /></span>Less than $1 million</li>
                                    <li><span><img decoding="async" src="assets/img/money.jpg" alt="company revenue" /></span>$1 million to $10 million</li>
                                    <li><span><img decoding="async" src="assets/img/money.jpg" alt="company revenue" /></span>$10 million to $50 million</li>
                                    <li><span><img decoding="async" src="assets/img/money.jpg" alt="company revenue" /></span>$50 million to $100 million</li>
                                    <li><span><img decoding="async" src="assets/img/money.jpg" alt="company revenue" /></span>More than $100 Million</li>
                                </ul>
                            </li>
                            <li><b>Medium Enterprise</b>
                                <ul>
                                    <li><span><img decoding="async" src="assets/img/money.jpg" alt="company revenue" /></span>$10 million to $50 million</li>
                                    <li><span><img decoding="async" src="assets/img/money.jpg" alt="company revenue" /></span>$50 million to $100 million</li>
                                    <li><span><img decoding="async" src="assets/img/money.jpg" alt="company revenue" /></span>$100 million to $500 million</li>
                                    <li><span><img decoding="async" src="assets/img/money.jpg" alt="company revenue" /></span>$500 million to $1 billion</li>
                                </ul>
                            </li>
                            <li><b>Large Enterprise</b>
                                <ul>
                                    <li><span><img decoding="async" src="assets/img/money.jpg" alt="company revenue" /></span>$1 billion to $5 billion</li>
                                    <li><span><img decoding="async" src="assets/img/money.jpg" alt="company revenue" /></span>$5 billion to $10 billion</li>
                                    <li><span><img decoding="async" src="assets/img/money.jpg" alt="company revenue" /></span>$10 billion to $50 billion</li>
                                    <li><span><img decoding="async" src="assets/img/money.jpg" alt="company revenue" /></span>$50 billion and above</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RevenueList