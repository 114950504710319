import React from 'react'

const JobList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i className="fa-solid fa-circle-check"></i>Owner/President/Stakeholder</li>
                            <li><i className="fa-solid fa-circle-check"></i>C-Suits/CIO/CTO/CHRO/COO</li>
                            <li><i className="fa-solid fa-circle-check"></i>VP/SVP</li>
                            <li><i className="fa-solid fa-circle-check"></i>BDM</li>
                            <li><i className="fa-solid fa-circle-check"></i>ITDM</li>
                            <li><i className="fa-solid fa-circle-check"></i>HRDM</li>
                            <li><i className="fa-solid fa-circle-check"></i>FDM</li>
                            <li><i className="fa-solid fa-circle-check"></i>Marketing DMS</li>
                            <li><i className="fa-solid fa-circle-check"></i>SME/SBO</li>
                            <li><i className="fa-solid fa-circle-check"></i>Teachers</li>
                            <li><i className="fa-solid fa-circle-check"></i>Others</li>

                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JobList