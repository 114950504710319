import React from 'react'

const MedicalList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Allergist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Anaesthesiologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Andrologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cardiologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cardiac Electrophysiologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Dermatologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Dietitian/Dietician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Emergency Room (ER) Doctor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Endocrinologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Epidemiologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Family Medicine Physician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Gastroenterologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Geriatrician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hyperbaric Physician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hematologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hepatologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Immunologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Infectious Disease Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Intensivist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Internal Medicine Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Maxillofacial Surgeon / Oral Surgeon</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Examiner</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Geneticist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Neonatologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Nephrologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Neurologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Neurosurgeon</li>
                            <li><i class="fa-solid fa-circle-check"></i>Nuclear Medicine Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Obstetrician/Gynecologist (OB/GYN)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Occupational Medicine Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Oncologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Ophthalmologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Orthopedic Surgeon / Orthopedist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Otolaryngologist (aka ENT Specialist)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Osteopath</li>
                            <li><i class="fa-solid fa-circle-check"></i>Palliative Care Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Parasitologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pathologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Perinatologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Periodontist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pediatrician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Physiatrist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Plastic Surgeon</li>
                            <li><i class="fa-solid fa-circle-check"></i>Psychiatrist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pulmonologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Rheumatologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sleep Doctor / Sleep Disorders Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Spinal Cord Injury Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sports Medicine Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgeon</li>
                            <li><i class="fa-solid fa-circle-check"></i>Thoracic Surgeon</li>
                            <li><i class="fa-solid fa-circle-check"></i>Urologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vascular Surgeon</li>
                            <li><i class="fa-solid fa-circle-check"></i>Veterinarian</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MedicalList