import React from 'react'
import Header from '../Components/Common/Header'
import Footer from '../Components/Common/Footer'
import InnerBanner from '../Components/Common/InnerBanner'
import MissionVision from '../Components/About/MissionVision'
import CoreValue from '../Components/About/CoreValue'
import Team from '../Components/About/Team'
import Affiliations from '../Components/Common/Affiliations'


const AboutPage = () => {
    return (
        <>
            <Header />
            <InnerBanner title={"abouts us"} menu={'About Us'}/>
            <MissionVision/>
            <CoreValue/>
            <Team/>
            <Affiliations/>
            <Footer />
        </>
    )
}

export default AboutPage