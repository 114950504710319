import React from 'react'

const LanguageList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>English</li>
                            <li><i class="fa-solid fa-circle-check"></i>Spanish</li>
                            <li><i class="fa-solid fa-circle-check"></i>Other (please specify)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LanguageList