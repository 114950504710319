import React from 'react'
import BannerSlide from '../Components/Homepage/BannerSlide'
import Header from '../Components/Common/Header'
import AboutBrief from '../Components/Homepage/AboutBrief'
import Process from '../Components/Homepage/Process'
import Solution from '../Components/Homepage/Solution'
import Counter from '../Components/Homepage/Counter'
import Industry from '../Components/Homepage/Industry'
import Testimonial from '../Components/Homepage/Testimonial'
import RoadmapCarousel from '../Components/Homepage/RoadmapCarousel'
import Footer from '../Components/Common/Footer'
import HeroContact from '../Components/Homepage/HeroContact'
import Affiliations from '../Components/Common/Affiliations'
import FormPopup from '../Components/Popup/FormPopup'
import SuccessPopup from '../Components/Popup/SuccessPopupCareer'

const SLIDE_COUNT = 8;
const slides = Array.from(Array(SLIDE_COUNT).keys());

const HomePage = () => {

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 px-0">
            <BannerSlide />
          </div>
          <HeroContact />
        </div>
      </div>
      <AboutBrief />
      <Process />
      <Solution />
      <Counter />
      <Industry />
      <Testimonial />
      <RoadmapCarousel slides={slides} />
      <Affiliations/>
      <Footer />
      <FormPopup/>
      <SuccessPopup/>
    </>
  )
}

export default HomePage