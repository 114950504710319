import React from 'react'
import { useEffect } from 'react'
import industry from "../../media/industry.json"
import Image from '../Common/Image';

const IndustryCard = () => {
    console.log("industry ", industry);
    const [visible, setVisible] = React.useState("manufacturing")
    useEffect(() => {
        var tabLinks = document.querySelectorAll(".tablinks");
        var tabContent = document.querySelectorAll(".tabcontent");


        tabLinks.forEach(function (el) {
            el.addEventListener("click", openTabs);
        });


        function openTabs(el) {
            var btnTarget = el.currentTarget;
            var country = btnTarget.dataset.country;

            tabContent.forEach(function (el) {
                el.classList.remove("active");
            });

            tabLinks.forEach(function (el) {
                el.classList.remove("active");
            });

            document.querySelector("#" + country).classList.add("active");

            btnTarget.classList.add("active");
        }
    }, [visible])
    return (
        <>
            <section id="wrapper">
                <div className="content">
                    {/* <!-- Tab links --> */}
                    <div className="tabs" data-aos="fade-up" data-aos-duration="800">
                        <button className="tablinks active" data-country="Manufacturing"><p data-title="Manufacturing">Manufacturing</p></button>
                        <button className="tablinks" data-country="Agriculture"><p data-title="Agriculture">Agriculture</p></button>
                        <button className="tablinks" data-country="Service"><p data-title="Service">Service</p></button>
                        <button className="tablinks" data-country="Govts"><p data-title="Govt">Govt & Defense</p></button>
                    </div>

                    <div className="wrapper_tabcontent" data-aos="fade-up" data-aos-duration="800">
                        {/* <!-- TAB CONTENT AGRICULTURE --> */}
                        <div id="Agriculture" className={visible === "agriculture" ? "tabcontent active" : "tabcontent"}>
                            <h3 onClick={() => { setVisible("agriculture") }}>Agriculture</h3>
                            <div className="row">
                                {industry.agriculture.map((n) => {
                                    return <>
                                        <div className='col-6 col-md-4 col-lg-2 gx-4 gy-5'>
                                            <div className="industry-item">
                                                <div className="icon">
                                                    <Image link={n.industrylink} folder="Industry" />
                                                </div>
                                                <div className="card-content">
                                                    <h4>{n.industryname}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                })}
                            </div>
                        </div>

                        {/* <!-- TAB CONTENT MANUFACTURING --> */}
                        <div id="Manufacturing" className={visible === "manufacturing" ? "tabcontent active" : "tabcontent"}>
                            <h3 onClick={() => { setVisible("manufacturing") }}>Manufacturing</h3>
                            <div className="row">
                                {industry.manufacturing.map((n) => {
                                    return <>
                                        <div className='col-6 col-md-4 col-lg-2 gx-4 gy-5'>
                                            <div className="industry-item">
                                                <div className="icon">
                                                    <Image link={n.industrylink} folder="Industry" />
                                                </div>
                                                <div className="card-content">
                                                    <h4>{n.industryname}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                })}
                            </div>
                        </div>

                        {/* <!-- TAB CONTENT SERVICE --> */}
                        <div id="Service" className={visible === "services" ? "tabcontent active" : "tabcontent"}>
                            <h3 onClick={() => { setVisible("services") }}>Service</h3>
                            <div className="row">
                                {industry.service.map((n) => {
                                    return <>
                                        <div className='col-6 col-md-4 col-lg-2 gx-4 gy-5'>
                                            <div className="industry-item">
                                                <div className="icon">
                                                    <Image link={n.industrylink} folder="Industry" />
                                                </div>
                                                <div className="card-content">
                                                    <h4>{n.industryname}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                })}
                            </div>
                        </div>

                        {/* <!-- TAB CONTENT GOVT AND DEFENSE --> */}
                        <div id="Govts" className={visible === "govts" ? "tabcontent active" : "tabcontent"}>
                            <h3>Govt & Defense</h3>
                            <div className="row">
                                {industry.govt.map((n) => {
                                    return <>
                                        <div className='col-6 col-md-4 col-lg-2 gx-4 gy-5'>
                                            <div className="industry-item">
                                                <div className="icon">
                                                    <Image link={n.industrylink} folder="Industry" />

                                                </div>
                                                <div className="card-content">
                                                    <h4>{n.industryname}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default IndustryCard