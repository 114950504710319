import React from 'react'

const PetList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Pet Ownerships</li>
                            <li><i class="fa-solid fa-circle-check"></i>Type of pets owned</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pet products (food, toys, grooming, etc.)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Frequency of pet-related purchases</li>
                            <li><i class="fa-solid fa-circle-check"></i>Involvement in pet-related purchaseds </li>
                            <li><i class="fa-solid fa-circle-check"></i>Type of pet(s) considered</li>
                            <li><i class="fa-solid fa-circle-check"></i>Likelihood of pet ownership</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pet Care Services</li>
                            <li><i class="fa-solid fa-circle-check"></i>Frequency of Vet Services</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PetList