import React from 'react'

const SportList = () => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="accordion-list">
            <ul>
              <li><i class="fa-solid fa-circle-check"></i>Sport Activity</li>
              <li><i class="fa-solid fa-circle-check"></i>Sports Websites</li>
              <li><i class="fa-solid fa-circle-check"></i>Sports Publications</li>
              <li><i class="fa-solid fa-circle-check"></i>Sports Networks</li>
              <li><i class="fa-solid fa-circle-check"></i>Sporting events attendance</li>
              <li><i class="fa-solid fa-circle-check"></i>Participation in sports activities</li>
              <li><i class="fa-solid fa-circle-check"></i>Ownership or intent to purchase sports equipment</li>
              <li><i class="fa-solid fa-circle-check"></i>Participation in fantasy sports</li>
              <li><i class="fa-solid fa-circle-check"></i>Sports Playing Level</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default SportList