import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

const FooterMain = () => {
    return (
        <>
            {/* CONTACT */}
            <div className="col-md-6 col-lg-6 g-5">
                <div className="content">
                    <h3 className='text-uppercase'>about frequent</h3>
                    <p>
                        Frequent Research is an ISO 9001:2015, 27001:2013 &amp;
                        20252:2019 certified firm with professionally qualified
                        full-service market research company. We deliver insights by
                        combining research outcomes, real-time data and smart
                        thinking to enable you to make insightful decisions. Also,
                        We outsource our services to help our clients around the
                        world with the right set of tools and services.
                    </p>
                    <div className="footer-social">
                        <a
                            href="https://www.facebook.com/frequentrfs/"
                            target="_blank"
                        >
                            <i className="fa-brands fa-facebook-f"></i>
                        </a>
                        <a
                            href="https://in.linkedin.com/company/frequentresearch"
                            target="_blank"
                        >
                            <i className="fa-brands fa-linkedin-in"></i>
                        </a>
                        <a href="https://twitter.com/frequentrfs" target="_blank">
                            <i className="fa-brands fa-twitter"></i>
                        </a>
                        <a
                            href="https://www.youtube.com/channel/UCLxmn2kq4aACAHaL2eT6QIQ"
                            target="_blank"
                        >
                            <i className="fa-brands fa-youtube"></i>
                        </a>
                    </div>
                </div>
            </div>

            {/* QUICK LINK */}
            <div className="col-md-6 col-lg-6 g-5 d-flex justify-content-start justify-content-md-center">
                <div className="content ps-lg-0">
                    <h3 className="text-uppercase">quick links</h3>
                    <ul id="menu-footer" className="footer-menu">

                        <li>
                            <Link to={"/cati"} target="_blank">
                                CATI
                            </Link>
                        </li>

                        <li>
                            <a
                                target="_blank"
                                rel="noopener"
                                href="https://panels.frequentresearch.com/"
                                itemProp="url"
                            >
                                Panelbook
                            </a>
                        </li>

                        <li>
                            <Link to={'/esomar-37'} target="_blank">
                                ESOMAR 37
                            </Link>
                        </li>

                        <li>
                            <Link to={'/privacy-policy'} target="_blank">
                                Privacy Policy
                            </Link>
                        </li>

                        <li>
                            {/* <Link to={'/#industry'}>
                                Industry Coverage
                            </Link> */}

                            {/* <a href="#industry">
                                Industry Coverage
                            </a> */}

                            <HashLink smooth to={'/#industry'}>
                                Industry Coverage
                            </HashLink>
                        </li>


                    </ul>
                </div>
            </div>
        </>
    )
}

export default FooterMain