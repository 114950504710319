import React from 'react'
import ConsultancyContent from '../../media/ConsultancyContent.json'
import apiCall from '../../modules/apiCall'

const TaxCompliance = () => {
    const [data, setData] = React.useState([])
    React.useEffect(() => {
        apiCall("/comp/ConsultancyContent")
            .then((res) => console.log(res))
            .catch((err) => console.log(err))
    }, [])
    return (
        <>
            <div className="section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="entry-content">
                                <p>Our Tax compliance services involve assisting individuals and businesses in meeting their tax obligations and ensuring compliance with applicable tax laws and regulations. Our motive are focused on accurately preparing and filing tax returns, fulfilling reporting requirements, and complying with tax laws at the local, state, and federal levels.</p>
                            </div>
                        </div>
                    </div>
                    <div className="research-consultancy-list">
                        <div className="row">
                            {ConsultancyContent.taxcompliance.map((item, i) => (
                                <div className="col-lg-6 g-5">
                                    <div className="concultancy-item-box" data-aos="fade-up" data-aos-duration="800">
                                        <div className="image-box color-dark image-box-style2">
                                            <div className="caption">
                                                <div className="thumb-service">
                                                    <div className="thumb" style={{
                                                        backgroundImage: `${item.bgimg}`
                                                    }}></div>
                                                </div>
                                                <div className="title-wrap">
                                                    <h3>{item.title}</h3>
                                                    <div className="content">
                                                        <p>{item.content}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TaxCompliance