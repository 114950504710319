import React from 'react'
import Image from '../Common/Image'
import support from "../../media/cati/cati_support.json"
const CatiSupport = () => {
    return (
        <>
            <div id='why-area'>
                <div id='cs' className="section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div class="content-wrapper right">
                                    <div class="section-heading light">
                                        <h2>
                                            Why Choose our CATI Support </h2>
                                        <p>With a team of experienced project handlers, researchers, and automation experts we have the resource to interview your target audience for key data. Our Capabilities ensure you to choose us for the below reasons</p>
                                    </div>
                                    <div class="list">
                                        {support.Cati_Support.data.map((item) => (
                                            <div class="item">
                                                <div class="icon">
                                                    <Image link={item.img} folder="cati" className="icon-img" />
                                                </div>
                                                <div class="content">
                                                    <h5>{item.Heading}</h5>
                                                    <p>{item.text} </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div id='qs' className="section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div class="content-wrapper left">
                                    <div class="section-heading">
                                        <h2>
                                            Why Choose our Qualitative Support </h2>
                                    </div>
                                    <div class="list">
                                        {support.Qualitaive_Support.data.map((item) => (
                                            <div class="item">
                                                <div class="icon">
                                                    <Image link={item.img} folder="cati" className="icon-img" />
                                                </div>
                                                <div class="content">
                                                    <h5>{item.Heading}</h5>
                                                    <p>{item.text} </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CatiSupport