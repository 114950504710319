import React from 'react'

const DietaryList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Clinical Dietitian</li>
                            <li><i class="fa-solid fa-circle-check"></i>Registered Dietitian</li>
                            <li><i class="fa-solid fa-circle-check"></i>Nutritionist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Dietetic Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Dietary Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Food Service Supervisor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Food Service Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Food and Nutrition Services Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Menu Planner</li>
                            <li><i class="fa-solid fa-circle-check"></i>Diet Clerk</li>
                            <li><i class="fa-solid fa-circle-check"></i>Diet Office Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Clinical Nutrition Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Dietetic Assistant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Food Safety Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Food Service Worker</li>
                            <li><i class="fa-solid fa-circle-check"></i>Food Production Assistant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Culinary Chef</li>
                            <li><i class="fa-solid fa-circle-check"></i>Catering Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Kitchen Supervisor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cook</li>
                            <li><i class="fa-solid fa-circle-check"></i>Dietary Aide</li>
                            <li><i class="fa-solid fa-circle-check"></i>Nutrition Services Assistant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Dietary Consultant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Menu Development Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Quality Assurance Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Allergy Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Patient Services Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Dietetic Intern</li>
                            <li><i class="fa-solid fa-circle-check"></i>Community Nutritionist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Clinical Nutrition Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Dietetic Educator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Food and Nutrition Educator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Nutrition Support Dietitian</li>
                            <li><i class="fa-solid fa-circle-check"></i>Wellness Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pediatric Dietitian</li>
                            <li><i class="fa-solid fa-circle-check"></i>Renal Dietitian</li>
                            <li><i class="fa-solid fa-circle-check"></i>Diabetes Educator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Weight Management Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Bariatric Dietitian</li>
                            <li><i class="fa-solid fa-circle-check"></i>Geriatric Dietitian</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DietaryList