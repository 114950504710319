import React from 'react'
import CoreValueCard from './CoreValueCard'

const CoreValue = () => {
    return (
        <>
            <div className='section-padding' id='coreValue'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading text-center" data-aos="fade-up" data-aos-duration="800">
                                <h2>our core <span>values</span></h2>
                            </div>
                        </div>
                    </div>
                    <CoreValueCard />
                </div>
            </div>
        </>
    )
}

export default CoreValue