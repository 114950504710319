import React from 'react'

const NonProfessionalList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Business Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Finance Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Accounting Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Billing Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Contracts Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Materials Management Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Health Information Management (HIM) Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>IT Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Revenue Cycle Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Quality Improvement Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Risk Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Project Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Business Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Financial Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Human Resources Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Accounts Payable Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Accounts Receivable Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Payroll Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Credentialing Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Records Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>IT Support Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Compliance Auditor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Revenue Cycle Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Quality Improvement Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Risk Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Project Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Marketing Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Public Relations Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Patient Experience Coordinator</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NonProfessionalList