import AllRoutes from './Routes/AllRoutes';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from 'react';
import Popup from './PopupContext';
import { useLocation } from 'react-router-dom';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, [pathname]);

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="App">
      <Popup>
        <AllRoutes />
      </Popup>
    </div>
  );
}

export default App;
