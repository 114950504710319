import React from 'react'

const ContactMap = () => {
  return (
    <>
        <div className="col-12 col-md-6 g-5">
        <div class="contact-map" data-aos="fade-up" data-aos-duration="800">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.8222207512927!2d77.1090198147083!3d28.694964182393214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d055691b37e47%3A0xa652bd028161255c!2sFrequent%20Research%20Fieldwork%20Solutions%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1677043170802!5m2!1sen!2sin"
              width="100%"
              height="400"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
    </>
  )
}

export default ContactMap