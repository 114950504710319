import React from 'react'
import { Link } from 'react-router-dom'
import Solution from '../../media/SolutionCard.json'

const SolutionCards = () => {
    return (
        <>
            {/* 1 */}
            {Solution.solution.map((item, i) => (
                <div className="col-12 col-sm-6 col-lg-4 g-5" key={i} data-aos="fade-up" data-aos-duration="800">
                    <div className="solution-card">
                        <div className="icon">
                            <i className={item.icon}></i>
                        </div>
                        <h4>{item.h4}</h4>
                        <div className="card-content">
                            <p>
                               {item.para}
                            </p>
                            <Link to={item.targetlink} className="rm-btn2">
                                read more <i className={item.linkicon}></i>
                            </Link>
                        </div>
                    </div>
                </div>
            ))}


            {/* 5 */}
            {/* <div className="col-12 col-sm-6 col-lg-4 g-5" data-aos="fade-up" data-aos-duration="800">
                <div className="solution-card">
                    <div className="icon">
                        <i class="fa-solid fa-lightbulb"></i>
                    </div>
                    <h4>Online Marketing Solutions</h4>
                    <div className="card-content">
                        <p>
                            Search Engine Optimization This involves optimizing a website to improve its ranking in search engine results pages (SERPs). View More...
                        </p>
                        <Link to="/online-marketing-dolution" className="rm-btn2">
                            read more <i class="fa-solid fa-arrow-right-long"></i>
                        </Link>
                    </div>
                </div>
            </div> */}

            {/* 6 */}
            {/* <div className="col-12 col-sm-6 col-lg-4 g-5" data-aos="fade-up" data-aos-duration="800">
                <div className="solution-card">
                    <div className="icon">
                        <i class="fa-solid fa-lightbulb"></i>
                    </div>
                    <h4>Tax Consulting</h4>
                    <div className="card-content">
                        <p>
                            Tax planning Tax consulting can help you plan your finances in a way that minimizes your tax liability. This involves...
                        </p>
                        <Link to="/tax-consulting" className="rm-btn2">
                            read more <i class="fa-solid fa-arrow-right-long"></i>
                        </Link>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default SolutionCards