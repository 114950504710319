import API from "./API";
export default function apiCall(endpoint, method = "get", data = {}) {
    let api = new API();
    let range=localStorage.getItem("range");
    if(!!range)api.header={range};
    switch (method) {
        case "get":
            return api.get(endpoint)
            break;

        default:
            return api[method](endpoint, data)
            break;
    }

}