import React from 'react'

const EducationList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Diploma &amp; Certificate</li>
                            <li><i class="fa-solid fa-circle-check"></i>Associate’s Degree</li>
                            <li><i class="fa-solid fa-circle-check"></i>Bachelor’s Degree</li>
                            <li><i class="fa-solid fa-circle-check"></i>Master’s Degree</li>
                            <li><i class="fa-solid fa-circle-check"></i>Doctoral Degrees</li>
                            <li><i class="fa-solid fa-circle-check"></i>Professional Degrees</li>
                            <li><i class="fa-solid fa-circle-check"></i>Other Degrees</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EducationList