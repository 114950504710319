import React from 'react'
import FrequentSlider from '../Common/Slider'
import testimonial from "../../media/testimonial.json"

const TestimonialCards = () => {
    return (
        <>
            <div className="slider">
                <FrequentSlider>
                    {testimonial.testimonial.map((item) => (
                        <div className="testimonial-card">
                            <div className="content-wrapper">
                                <img src="assets/img/icon-quote.png" className='rotate' alt="" />

                                <p>{item.paragraph}</p>
                                <img src="assets/img/icon-quote.png" className='quote' alt="" />
                            </div>
                            <div className="testimonial-name">
                                <h2>{item.h2}</h2>
                                <h4 className='desg'>{item.h4}</h4>
                            </div>
                        </div>
                    ))}
                </FrequentSlider>
            </div>
        </>
    )
}

export default TestimonialCards