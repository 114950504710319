import React from 'react'
import Header from '../Components/Common/Header'
import InnerBanner from '../Components/Common/InnerBanner'
import Affiliations from '../Components/Common/Affiliations'
import Footer from '../Components/Common/Footer'
import EntryContent from '../Components/B2B/EntryContent'
import B2BImageCard from '../Components/B2B/B2BImageCard'
import KeyAttributesAccordionB2B from '../Components/B2B/KeyAttributesAccordionB2B'
import RecruimentProcess from '../Components/B2B/RecruimentProcess'
import DownloadPopup from '../Components/Popup/DownloadPopup'

const B2BPage = () => {
    return (
        <>
            <div id='popup'>
                <Header />
                <InnerBanner title={'business to business'} menu={'Business To Business'} />
                <DownloadPopup />
                <div className="section-padding">
                    <div className="container">
                        <EntryContent />
                        <B2BImageCard />
                    </div>
                </div>

                <KeyAttributesAccordionB2B />
                <RecruimentProcess />

                <Affiliations />
                <Footer />
            </div>
        </>
    )
}

export default B2BPage