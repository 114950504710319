import API from "./API"
export default async function submitForm(e) {
    let frm = e.target.form;
    if (!frm) return console.log("misconfigured");
    const action = frm.getAttribute("action");
    const method = frm.getAttribute("method") || "post";
    let data = {};
    let errors = [];
    frm.querySelectorAll("[name]").forEach((fld) => {
        if (!fld.validity.valid) errors.push(fld);
        if (["checkbox", "radio"].indexOf(fld.type) === -1) { data[fld.name] = fld.value; return; }
        if (fld.checked) data[fld.name] = fld.value
        if(fld.file){console.log("fld ",fld)}
    });
    if (errors.length) {
        errors[0].focus();
        return false;
    }
    let api = new API();
    return api[method](action, data)
}