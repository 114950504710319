import React from 'react'
import Header from '../../Components/Common/Header'
import InnerBanner from '../../Components/Common/InnerBanner'
import Affiliations from '../../Components/Common/Affiliations'
import Footer from '../../Components/Common/Footer'
import TaxPlanning from '../../Components/TaxConsulting/TaxPlanning'

const TaxPlaningPage = () => {
  return (
    <>
      <Header />
      <InnerBanner title={'tax planning'} menu={'Tax Planning'} />

      <TaxPlanning />
      <Affiliations />
      <Footer />
    </>
  )
}

export default TaxPlaningPage