import React from 'react'

const FinancialList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Finanical DM Ability</li>
                            <li><i class="fa-solid fa-circle-check"></i>Types of Finance Accounts</li>
                            <li><i class="fa-solid fa-circle-check"></i>Number of credit cards</li>
                            <li><i class="fa-solid fa-circle-check"></i>Credit card type(s)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Credit payment history</li>
                            <li><i class="fa-solid fa-circle-check"></i>Method of payment</li>
                            <li><i class="fa-solid fa-circle-check"></i>Household payment responsibility</li>
                            <li><i class="fa-solid fa-circle-check"></i>Financial products</li>
                            <li><i class="fa-solid fa-circle-check"></i>Current loan(s)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Insurance products</li>
                            <li><i class="fa-solid fa-circle-check"></i>Insurance companies</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FinancialList