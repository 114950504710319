import React from "react";
import cardContent from "../../media/ConsultingCard.json"
import apiCall from "../../modules/apiCall";

const MarketingCardImage = () => {
  const [data, setData] = useState([])
  React.useEffect(() => {
    apiCall("/comp/ConsultancyContent")
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
  }, [])
  return (
    <>
      <div className="section-padding" id="tax">
        <div className="container">
          <div className="row">

            {cardContent.MarketingSolution.map((item) => (
              <div className="col-md-6 col-lg-4 g-5">
                <div className="service-box">
                  <div className="service-content">
                    <div className="image-box">
                      <img src={item.image} className="img-fluid" alt="" />
                    </div>
                    <div className="content-inner">
                      <h2>{item.h2}</h2>
                      <p>{item.para}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingCardImage;
