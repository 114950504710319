import React from 'react'

const PersonnelList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul >
                            <li><i class="fa-solid fa-circle-check"></i>Human Resources Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Human Resources Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Human Resources Generalist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Recruitment Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Talent Acquisition Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Employee Relations Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Compensation and Benefits Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>HR Business Partner</li>
                            <li><i class="fa-solid fa-circle-check"></i>HR Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>HR Assistant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Training and Development Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Organizational Development Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>HRIS Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Payroll Administrator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Compliance Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Health and Safety Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Employee Wellness Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Labor Relations Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Employee Engagement Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>HR Operations Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Diversity and Inclusion Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>HR Data Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Workforce Planning Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>HR Consultant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Performance Management Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Succession Planning Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>HR Project Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Compensation Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Benefits Administrator</li>
                            <li><i class="fa-solid fa-circle-check"></i>HR Training Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>HR Administrator</li>
                            <li><i class="fa-solid fa-circle-check"></i>HR Compliance Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>HR Assistant Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>HR Business Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>HR Records Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Employee Assistance Program Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>HR Communications Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>HR Service Delivery Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>HR Policy and Procedure Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>HR Metrics and Analytics Specialist</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PersonnelList