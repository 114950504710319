import React from 'react'
import Header from '../Components/Common/Header'
import InnerBanner from '../Components/Common/InnerBanner'
import Affiliations from '../Components/Common/Affiliations'
import Footer from '../Components/Common/Footer'
import CareerInfo from '../Components/Career/CareerInfo'
import ApplyForm from '../Components/Career/ApplyForm'
import ContactMap from '../Components/Career/ContactMap'

const CareerPage = () => {
    return (
        <>
            <Header />
            <InnerBanner title={'career'} menu={'Career'} />
            <div className="section-padding">
                <div className="container">
                    <div className="row">
                        <CareerInfo />
                        <ApplyForm/>
                        <ContactMap/>
                    </div>
                </div>
            </div>
            <Affiliations />
            <Footer />
        </>
    )
}

export default CareerPage