import React from 'react'

const Language = () => {
    return (
        <>
            <div className="section-padding" id='language'>
                <img src="assets/img/cati/lang.png" className='lang' alt="" />
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading text-center">
                                <h2>Language Capabilities</h2>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <p>&nbsp;</p>
                            <ul class="obj">
                                <li><i class="fa-solid fa-square-check"></i>Well trained and experienced 10+ Multilingual Native &amp; Non-native callers with WFH/Office set-up with expertise in interviewing professionals</li>
                                <li><i class="fa-solid fa-square-check"></i>Technologically advanced Global CATI Infrastructure (Call recording facility)</li>
                                <li><i class="fa-solid fa-square-check"></i>Stringent Quality Assurance Norms</li>
                                <li><i class="fa-solid fa-square-check"></i>Catering both Global B2B and B2C markets</li>
                            </ul>
                        </div>
                        <div class="col-md-12">
                            <ul class="language">
                                <li class="list-title">Americans
                                    <ul>
                                        <li>English</li>
                                        <li>Spanish</li>
                                        <li>French</li>
                                        <li>Portuguese</li>
                                    </ul>
                                </li>
                                <li class="list-title">Western and Eastern Europe
                                    <ul>
                                        <li>German</li>
                                        <li>French</li>
                                        <li>Italian</li>
                                        <li>Spanish</li>
                                        <li>Turkish</li>
                                        <li>Russian</li>
                                    </ul>
                                </li>
                                <li class="list-title">Asia, Africa and Middle East
                                    <ul>
                                        <li>Mandarin</li>
                                        <li>Cantonese</li>
                                        <li>Korean</li>
                                        <li>Malay</li>
                                        <li>Vietnamese</li>
                                        <li>Bahasa</li>
                                        <li>Thai</li>
                                        <li>Taiwanese</li>
                                        <li>Arabic</li>
                                        <li>English</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Language