import React from "react";
import Image from "../Common/Image";
import countryflag from "../../media/country.json"
const CountryList = () => {
  return (
    <>
      <div className="row mb-5">
        {countryflag.country.map((n, i) => {
          return (<>
            <div className="col-6 col-md-4 d-flex align-items-center country" key={i}>
              <div className="icon-img me-4">
                <Image link={n.countrylink}  folder="country" />
              </div>
              <div className="icon-name">
                <span>{n.countryname}</span>
              </div>
            </div>
          </>)
        })}
      </div>
    </>
  );
};

export default CountryList;
