import React from 'react'

const SecurityList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Security Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Supervisor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Guard</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Dispatcher</li>
                            <li><i class="fa-solid fa-circle-check"></i>Access Control Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>CCTV Operator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Patrol Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Emergency Response Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Fire Safety Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Alarm Systems Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Safety and Security Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Loss Prevention Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Investigator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Crowd Control Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Parking Enforcement Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>First Responder</li>
                            <li><i class="fa-solid fa-circle-check"></i>Incident Response Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Training Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Consultant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Investigator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Background Screening Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Crisis Management Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Systems Administrator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Risk Assessment Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Operations Center (SOC) Operator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Visitor Management Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Emergency Preparedness Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Executive Protection Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Incident Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Workplace Violence Prevention Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Audit Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Policy and Procedure Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Technology Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Awareness and Training Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Vendor Manager</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SecurityList