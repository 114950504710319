import React from 'react'
import BriefCards from './BriefCards'

const AboutBrief = () => {
    return (
        <>
            <div className="section-padding-skew" id='about'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-5 g-5">
                            <div className="image-wrapper" data-aos="fade-right" data-aos-duration="800"    data-aos-offset="200">
                                <img src="assets/img/about.jpg" className='img-fluid' alt="" />
                            </div>
                        </div>

                        <div className='col-12 col-sm-12 col-lg-7 g-5'>
                            <div className="content-wrapper" data-aos="fade-left"
                                data-aos-offset="200"
                                data-aos-duration="800">
                                <div className="section-heading">
                                    <h6 className='sub-title'>who we are</h6>
                                    <h2>The Best Market Research Company in Delhi</h2>
                                </div>

                                <div className="row">
                                    <BriefCards />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutBrief