import React from 'react'
import Header from '../../Components/Common/Header'
import InnerBanner from '../../Components/Common/InnerBanner'
import Affiliations from '../../Components/Common/Affiliations'
import Footer from '../../Components/Common/Footer'
import TaxOptimization from '../../Components/TaxConsulting/TaxOptimization'

const TaxOptimizationPage = () => {
  return (
    <>
      <Header />
      <InnerBanner title={'tax optimization'} menu={'Tax Optimization'} />

      <TaxOptimization />

      <Affiliations />
      <Footer />
    </>
  )
}

export default TaxOptimizationPage