import React from 'react'

const PurchasingList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Purchasing Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Procurement Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Supply Chain Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Purchasing Agent</li>
                            <li><i class="fa-solid fa-circle-check"></i>Procurement Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Materials Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Contract Administrator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vendor Relations Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Inventory Control Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Logistics Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sourcing Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Contract Negotiator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Purchasing Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Category Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Strategic Sourcing Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Supplier Relationship Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Procurement Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cost Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Value Analysis Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Central Supply Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Receiving Supervisor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Warehouse Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Purchasing Assistant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Contract Compliance Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Replenishment Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Procurement Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Equipment Planner</li>
                            <li><i class="fa-solid fa-circle-check"></i>Contract Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Expediting Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Procurement Administrator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Supply Chain Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Contract Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Purchasing Clerk</li>
                            <li><i class="fa-solid fa-circle-check"></i>Purchasing Expeditor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Inventory Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Supplier Performance Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Purchasing Agent Assistant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Bid Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Procurement Support Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Strategic Sourcing Analyst</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PurchasingList