import React from 'react'

const AilmentList = () => {
    return (
        <>
            <div class="accordion-list">
                <ul class="multy-list">
                    <li><b>Allergy &amp; Immunology</b>
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Allergic Rhinitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Anaphylaxis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Angioedema</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cutaneous Drug Reactions</li>
                            <li><i class="fa-solid fa-circle-check"></i>Food Allergy</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sinusitis</li>
                        </ul>
                    </li>
                    <li><b>Blood &amp; Circulation</b>
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Allergic Rhinitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Anaphylaxis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Angioedema</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cutaneous Drug Reactions</li>
                            <li><i class="fa-solid fa-circle-check"></i>Food Allergy</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sinusitis</li>
                        </ul>
                    </li>
                    <li><b>Brain &amp; Nervous System</b>
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Alzheimer’s Disease</li>
                            <li><i class="fa-solid fa-circle-check"></i>Encephalitis, Viral</li>
                            <li><i class="fa-solid fa-circle-check"></i>Meningitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Migraine Headache</li>
                            <li><i class="fa-solid fa-circle-check"></i>Multiple Sclerosis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Parkinson’s Disease</li>
                            <li><i class="fa-solid fa-circle-check"></i>Seizure Disorders</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sinus Headache</li>
                            <li><i class="fa-solid fa-circle-check"></i>Stroke</li>
                            <li><i class="fa-solid fa-circle-check"></i>Syncope</li>
                            <li><i class="fa-solid fa-circle-check"></i>Tension Headache</li>
                            <li><i class="fa-solid fa-circle-check"></i>Transient Ischemic Attacks</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vertigo</li>
                        </ul>
                    </li>
                    <li><b>Cancer</b>
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Bone Cancer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Brain Cancer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Breast Cancer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cervical Dysplasia</li>
                            <li><i class="fa-solid fa-circle-check"></i>Colorectal Cancer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Leukemia</li>
                            <li><i class="fa-solid fa-circle-check"></i>Lung Cancer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Lymphoma</li>
                            <li><i class="fa-solid fa-circle-check"></i>Myeloproliferative Disorders</li>
                            <li><i class="fa-solid fa-circle-check"></i>Prostate Cancer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Skin Cancer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Kidney Cancer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Throat Cancer</li>
                        </ul>
                    </li>
                    <li><b>Digestive System</b>
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Appendicitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cirrhosis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Colorectal Cancer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Constipation</li>
                            <li><i class="fa-solid fa-circle-check"></i>Crohn’s Disease</li>
                            <li><i class="fa-solid fa-circle-check"></i>Diarrhea</li>
                            <li><i class="fa-solid fa-circle-check"></i>Diverticular Disease</li>
                            <li><i class="fa-solid fa-circle-check"></i>Dysphagia</li>
                            <li><i class="fa-solid fa-circle-check"></i>Food Poisoning</li>
                            <li><i class="fa-solid fa-circle-check"></i>Gallbladder Disease</li>
                            <li><i class="fa-solid fa-circle-check"></i>Gastritis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Gastroesophageal Reflux Disease</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hemorrhoids</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hepatitis, Viral</li>
                            <li><i class="fa-solid fa-circle-check"></i>Infantile Colic</li>
                            <li><i class="fa-solid fa-circle-check"></i>Intestinal Parasites</li>
                            <li><i class="fa-solid fa-circle-check"></i>Irritable Bowel Syndrome</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pancreatitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Peptic Ulcer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Peritonitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Proctitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pyloric Stenosis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Roundworms</li>
                            <li><i class="fa-solid fa-circle-check"></i>Ulcerative Colitis</li>
                        </ul>
                    </li>
                    <li><b>Endocrine System</b>
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Diabetes Mellitus</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hyperkalemia</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hyperparathyroidism</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hyperthyroidism</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hypoglycemia</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hypoparathyroidism</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hypothyroidism</li>
                            <li><i class="fa-solid fa-circle-check"></i>Obesity</li>
                            <li><i class="fa-solid fa-circle-check"></i>Thyroiditis</li>
                        </ul>
                    </li>
                    <li><b>Eye/Ear/Nose/Throat</b>
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Allergic Rhinitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cataracts</li>
                            <li><i class="fa-solid fa-circle-check"></i>Conjunctivitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Glaucoma</li>
                            <li><i class="fa-solid fa-circle-check"></i>Laryngitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Macular Degeneration</li>
                            <li><i class="fa-solid fa-circle-check"></i>Otitis Media</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharyngitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sinusitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Temporomandibular Joint Dysfunction</li>
                            <li><i class="fa-solid fa-circle-check"></i>Uveitis</li>
                        </ul>
                    </li>
                    <li><b>Heart &amp; Vascular Disorders</b>
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Angina</li>
                            <li><i class="fa-solid fa-circle-check"></i>Atherosclerosis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Congestive Heart Failure</li>
                            <li><i class="fa-solid fa-circle-check"></i>Edema</li>
                            <li><i class="fa-solid fa-circle-check"></i>Endocarditis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hypercholesterolemia</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hypertension</li>
                            <li><i class="fa-solid fa-circle-check"></i>Myocardial Infarction</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pericarditis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pulmonary Hypertension</li>
                            <li><i class="fa-solid fa-circle-check"></i>Shock</li>
                            <li><i class="fa-solid fa-circle-check"></i>Stroke</li>
                            <li><i class="fa-solid fa-circle-check"></i>Syncope</li>
                            <li><i class="fa-solid fa-circle-check"></i>Transient Ischemic Attacks</li>
                            <li><i class="fa-solid fa-circle-check"></i>Varicose Veins</li>
                        </ul>
                    </li>
                    <li><b>Infectious Diseases</b>
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Bronchitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Candidiasis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Common Cold</li>
                            <li><i class="fa-solid fa-circle-check"></i>Encephalitis, Viral</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hepatitis, Viral</li>
                            <li><i class="fa-solid fa-circle-check"></i>Herpes Simplex Virus</li>
                            <li><i class="fa-solid fa-circle-check"></i>Herpes Zoster and Varicella Viruses</li>
                            <li><i class="fa-solid fa-circle-check"></i>Histoplasmosis</li>
                            <li><i class="fa-solid fa-circle-check"></i>HIV and AIDS</li>
                            <li><i class="fa-solid fa-circle-check"></i>Influenza</li>
                            <li><i class="fa-solid fa-circle-check"></i>Intestinal Parasites</li>
                            <li><i class="fa-solid fa-circle-check"></i>Lyme Disease</li>
                            <li><i class="fa-solid fa-circle-check"></i>Measles</li>
                            <li><i class="fa-solid fa-circle-check"></i>Meningitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Mononucleosis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Mumps</li>
                            <li><i class="fa-solid fa-circle-check"></i>Osteomyelitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Peritonitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pertussis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharyngitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Proctitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Prostatitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Reiter’s Syndrome</li>
                            <li><i class="fa-solid fa-circle-check"></i>Roseola</li>
                            <li><i class="fa-solid fa-circle-check"></i>Roundworms</li>
                            <li><i class="fa-solid fa-circle-check"></i>Rubella</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sexually Transmitted Diseases</li>
                            <li><i class="fa-solid fa-circle-check"></i>Tuberculosis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Urinary Tract Infection in Women</li>
                            <li><i class="fa-solid fa-circle-check"></i>Warts</li>
                        </ul>
                    </li>
                    <li><b>Musculoskeletal</b>
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Bursitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Carpal Tunnel Syndrome</li>
                            <li><i class="fa-solid fa-circle-check"></i>Fibromyalgia</li>
                            <li><i class="fa-solid fa-circle-check"></i>Gout</li>
                            <li><i class="fa-solid fa-circle-check"></i>Low Back Pain</li>
                            <li><i class="fa-solid fa-circle-check"></i>Muscular Dystrophy</li>
                            <li><i class="fa-solid fa-circle-check"></i>Osteoarthritis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Osteomyelitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Osteoporosis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Raynaud’s Phenomenon</li>
                            <li><i class="fa-solid fa-circle-check"></i>Rheumatoid Arthritis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Scleroderma</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sprains and Strains</li>
                            <li><i class="fa-solid fa-circle-check"></i>Systemic Lupus Erythematosus</li>
                            <li><i class="fa-solid fa-circle-check"></i>Tendinitis</li>
                        </ul>
                    </li>
                    <li><b>Psychology</b>
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Alcoholism</li>
                            <li><i class="fa-solid fa-circle-check"></i>Anorexia Nervosa</li>
                            <li><i class="fa-solid fa-circle-check"></i>Anxiety</li>
                            <li><i class="fa-solid fa-circle-check"></i>Attention Deficit/Hyperactivity Disorder</li>
                            <li><i class="fa-solid fa-circle-check"></i>Bulimia Nervosa</li>
                            <li><i class="fa-solid fa-circle-check"></i>Dementia</li>
                            <li><i class="fa-solid fa-circle-check"></i>Depression</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hypochondriasis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Post Traumatic Stress Disorder</li>
                            <li><i class="fa-solid fa-circle-check"></i>Stress</li>
                        </ul>
                    </li>
                    <li><b>Reproductive System</b>
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Amenorrhea</li>
                            <li><i class="fa-solid fa-circle-check"></i>Benign Prostatic Hyperplasia</li>
                            <li><i class="fa-solid fa-circle-check"></i>Candidiasis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cervical Dysplasia</li>
                            <li><i class="fa-solid fa-circle-check"></i>Endometriosis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Herpes Simplex Virus</li>
                            <li><i class="fa-solid fa-circle-check"></i>Menopause</li>
                            <li><i class="fa-solid fa-circle-check"></i>Menstrual Pain</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pelvic Inflammatory Disease</li>
                            <li><i class="fa-solid fa-circle-check"></i>Preeclampsia</li>
                            <li><i class="fa-solid fa-circle-check"></i>Premenstrual Syndrome</li>
                            <li><i class="fa-solid fa-circle-check"></i>Prostate Cancer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Prostatitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sexual Dysfunction</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sexually Transmitted Diseases</li>
                            <li><i class="fa-solid fa-circle-check"></i>Urethritis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Urinary Incontinence</li>
                            <li><i class="fa-solid fa-circle-check"></i>Urinary Tract Infection in Women</li>
                            <li><i class="fa-solid fa-circle-check"></i>Vaginitis</li>
                        </ul>
                    </li>
                    <li><b>Respiratory</b>
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Asthma</li>
                            <li><i class="fa-solid fa-circle-check"></i>Bronchitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chronic Obstructive Pulmonary Disease</li>
                            <li><i class="fa-solid fa-circle-check"></i>Common Cold</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cough</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cystic Fibrosis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pertussis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pulmonary Edema</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pulmonary Hypertension</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sarcoidosis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sleep Apnea</li>
                        </ul>
                    </li>
                    <li><b>Skin, Nails &amp; Hair Disorders</b>
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Acne</li>
                            <li><i class="fa-solid fa-circle-check"></i>Alopecia</li>
                            <li><i class="fa-solid fa-circle-check"></i>Burns</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cellulitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cutaneous Drug Reactions</li>
                            <li><i class="fa-solid fa-circle-check"></i>Dermatitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Eczema</li>
                            <li><i class="fa-solid fa-circle-check"></i>Erythema</li>
                            <li><i class="fa-solid fa-circle-check"></i>Frostbite</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hair Disorders</li>
                            <li><i class="fa-solid fa-circle-check"></i>Herpes Simplex Virus</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hirsuitism</li>
                            <li><i class="fa-solid fa-circle-check"></i>Insect Bites and Stings</li>
                            <li><i class="fa-solid fa-circle-check"></i>Nail Disorders</li>
                            <li><i class="fa-solid fa-circle-check"></i>Photodermatitis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Psoriasis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Roseola</li>
                            <li><i class="fa-solid fa-circle-check"></i>Skin Cancer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Warts</li>
                            <li><i class="fa-solid fa-circle-check"></i>Wounds</li>
                        </ul>
                    </li>
                    <li><b>Other’s</b>
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Alcoholism</li>
                            <li><i class="fa-solid fa-circle-check"></i>Amyloidosis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chronic Fatigue Syndrome</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cystic Fibrosis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Edema</li>
                            <li><i class="fa-solid fa-circle-check"></i>Fever of Unknown Origin</li>
                            <li><i class="fa-solid fa-circle-check"></i>Fibromyalgia</li>
                            <li><i class="fa-solid fa-circle-check"></i>Frostbite</li>
                            <li><i class="fa-solid fa-circle-check"></i>Heat Exhaustion</li>
                            <li><i class="fa-solid fa-circle-check"></i>Haemorrhoids</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hypoglycaemia</li>
                            <li><i class="fa-solid fa-circle-check"></i>Insomnia</li>
                            <li><i class="fa-solid fa-circle-check"></i>Kidney Stones</li>
                            <li><i class="fa-solid fa-circle-check"></i>Motion Sickness</li>
                            <li><i class="fa-solid fa-circle-check"></i>Myeloproliferative Disorders</li>
                            <li><i class="fa-solid fa-circle-check"></i>Obesity</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiation Damage</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sarcoidosis</li>
                            <li><i class="fa-solid fa-circle-check"></i>Scleroderma</li>
                            <li><i class="fa-solid fa-circle-check"></i>Systemic Lupus Erythematosus</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default AilmentList