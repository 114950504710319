import React from 'react'
const ErrorPopupCareer = (props) => {
    const handleClose = () => {
        props.handleErrorToggle()
        window.location.href = ("/career")
    }
    return (
        <>
            <div class="success-popup-body" style={{ display: props.showError ? "block" : "none" }}>
                <div className="popup-success">
                    <div className="head">
                        <h3>Thank You</h3>
                    </div>
                    <div className="body">
                        <i class="fa-solid fa-hands-praying"></i>
                        <p>You have already submitted your request. Our HR representative will surely connect you soon.
                        </p>
                        <p>Thank you!</p>
                        <button type='button' className='success-close' onClick={handleClose}>OK</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ErrorPopupCareer