import React from 'react';
import ConsultancyContent from '../../media/ConsultancyContent.json'

const B2BImageCard = () => {
    return (
        <>
            <div className="research-consultancy-list">
                <div className="row">
                    {ConsultancyContent.b2bconsultancy.map((item, i) => (
                        <div className="col-lg-6 g-5" key={i}>
                            <div className="concultancy-item-box" data-aos="fade-up" data-aos-duration="800">
                                <div className="image-box color-dark image-box-style2">
                                    <div className="caption">
                                        <div className="thumb-service">
                                            <div className="thumb" style={{
                                                backgroundImage: `${item.bgimg}`
                                            }}></div>
                                        </div>
                                        <div className="title-wrap">
                                            <h3>{item.title}</h3>
                                            <div className="content">
                                                <p>{item.content}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}


                    {/* 6 */}
                    <div className="col-lg-6 g-5">
                        <div className="concultancy-item-box" data-aos="fade-up" data-aos-duration="800">
                            <div className="image-box color-dark image-box-style2">
                                <div className="caption">
                                    <div className="thumb-service">
                                        <div className="thumb" style={{
                                            backgroundImage: `url("../assets/img/PricingResearch.jpg")`
                                        }}></div>
                                    </div>
                                    <div className="title-wrap">
                                        <h3>Quality & Validation Checks</h3>
                                        <div className="content">
                                            <ul>
                                                <li> <i className="fa-solid fa-circle-check"></i> Double Opt-in </li>
                                                <li><i className="fa-solid fa-circle-check"></i> Age Validation</li>
                                                <li><i className="fa-solid fa-circle-check"></i> BOAT Validation</li>
                                                <li><i className="fa-solid fa-circle-check"></i> GEO IP Validation</li>
                                                <li><i className="fa-solid fa-circle-check"></i> CAPTCHA security code </li>
                                                <li><i className="fa-solid fa-circle-check"></i> Gate Survey</li>
                                                <li><i className="fa-solid fa-circle-check"></i> Security Question Validation</li>
                                                <li><i className="fa-solid fa-circle-check"></i> Screening Questions Validation</li>
                                                <li><i className="fa-solid fa-circle-check"></i> OE Quality Check</li>
                                                <li><i className="fa-solid fa-circle-check"></i> Red herring Questions Validation</li>
                                                <li><i className="fa-solid fa-circle-check"></i> Pre Screening Filter</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default B2BImageCard