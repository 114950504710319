import React from 'react';
import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from '../Pages/HomePage';
import AboutPage from '../Pages/AboutPage';
import ResearchSolutionPage from '../Pages/ResearchSolutionPage';
import ResearchConsultancyPage from '../Pages/ResearchConsultancyPage';
import TaxConsultingPage from '../Pages/TaxConsultingPage';
import AnalyticSolutionPage from '../Pages/AnalyticSolutionPage';
import MarketingSolutionPage from '../Pages/MarketingSolutionPage';
import WebsiteSolutionPage from '../Pages/WebsiteSolutionPage';
import B2BPage from '../Pages/B2BPage';
import B2Cpage from '../Pages/B2Cpage';
import HealthcarePage from '../Pages/HealthcarePage';
import EsomarPage from '../Pages/EsomarPage';
import CareerPage from '../Pages/CareerPage';
import TaxPlaningPage from '../Pages/TaxConsulting/TaxPlaningPage';
import TaxCompliancePage from '../Pages/TaxConsulting/TaxCompliancePage';
import TaxOptimizationPage from '../Pages/TaxConsulting/TaxOptimizationPage';
import PrivacyPage from '../Pages/PrivacyPage';
import DataExplorationPage from '../Pages/AnalyticSolution/DataExplorationPage';
import DescriptiveAnalyticPage from '../Pages/AnalyticSolution/DescriptiveAnalyticPage';
import TaxRepresentationPage from '../Pages/TaxConsulting/TaxRepresentationPage';
import InternationalTaxPage from '../Pages/TaxConsulting/InternationalTaxPage';
import TaxRiskPage from '../Pages/TaxConsulting/TaxRiskPage';
import DiagnosticAnalyticPage from '../Pages/AnalyticSolution/DiagnosticAnalyticPage';
import AdvanceAnalyticPage from '../Pages/AnalyticSolution/AdvanceAnalyticPage';
import CatiPage from '../Pages/CatiPage';


const AllRoute = () => {
  

  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/research-solution' element={<ResearchSolutionPage />} />
        <Route path='/research-consultancy' element={<ResearchConsultancyPage />} />
        <Route path='/tax-consulting' element={<TaxConsultingPage />} />
        <Route path='/analytic-solution' element={<AnalyticSolutionPage />} />
        <Route path='/software-website-solution' element={<WebsiteSolutionPage />} />
        <Route path='/online-marketing-solution' element={<MarketingSolutionPage />} />
        <Route path='/business-to-business' element={<B2BPage />} />
        <Route path='/business-to-consumer' element={<B2Cpage />} />
        <Route path='/healthcare' element={<HealthcarePage />} />
        <Route path='/esomar-37' element={<EsomarPage />} />
        <Route path='/career' element={<CareerPage />} />
        <Route path='/privacy-policy' element={<PrivacyPage />} />
        <Route path='/tax-planning' element={<TaxPlaningPage />} />
        <Route path='/tax-compliance' element={<TaxCompliancePage />} />
        <Route path='/tax-optimization' element={<TaxOptimizationPage />} />
        <Route path='/tax-representation' element={<TaxRepresentationPage />} />
        <Route path='/international-tax-consulting' element={<InternationalTaxPage />} />
        <Route path='/tax-risk-management' element={<TaxRiskPage />} />
        <Route path='/data-exploration-and-visualization' element={<DataExplorationPage />} />
        <Route path='/descriptive-analytics' element={<DescriptiveAnalyticPage />} />
        <Route path='/diagnostic-analytics' element={<DiagnosticAnalyticPage />} />
        <Route path='/advance-analytics' element={<AdvanceAnalyticPage />} />
        <Route path='/cati' element={<CatiPage/>} />
      </Routes>
    </>
  )
}

export default AllRoute