import React from 'react'

const HealthcareContent = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="entry-content" data-aos="fade-up" data-aos-duration="800">
                        <div class="entry-section-head text-center text-capitalize">
                            <h2>Healthcare Panel</h2>
                        </div>
                        <div class="content mt-5">
                            <p>We are very unique in online market research with our own healthcare panels in 30+ countries and partnerships with local panel providers in almost 10 additional countries provide us the freedom to provide customised solution for you, we work to ensure that right respondent will be available for right project, we provide the highest quality and most cost effective sampling in shortest possible time.
                                Our sampling methods and panels are built upon sophisticated technology, honest communication and respectful compensation to encourage participation, increase the response rate and to deliver high quality results.</p>
                                <p>We take great pride in the quality of the people we work with for our paid medical studies. To help us maintain those high standards. As Respondent integrity is very critical step in panel building, therefore we apply new and sophisticated technology to ensure the data we deliver to our client is of very high quality and reliable.</p>
                                <p>From payers to HCPs and KOLs, Frequent Healthcare Panel covers a great range of pharmaceutical &amp; Healthcare profiles according to specific demographics, specialties, department, GMC / NMC Number &amp; designations with specific panels filled with experts and an unrivalled list of dedicated finders, we have unique qualifications when it comes to validate healthcare professionals for market research across multiple methodologies.</p>
                                <p>We have 4,74000+ strong panel source covering, USA, Canada, EU5, India, Australia, Japan, China, Korea &amp; Malaysia. We always recruit the right people for your pharmaceutical market research project, whatever type of respondent you are looking for.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HealthcareContent