import React from 'react'
import Header from '../../Components/Common/Header'
import InnerBanner from '../../Components/Common/InnerBanner'
import Affiliations from '../../Components/Common/Affiliations'
import Footer from '../../Components/Common/Footer'
import DiagnosticAnalytic from '../../Components/AnalyticSolution/DiagnosticAnalytic'

const DiagnosticAnalyticPage = () => {
  return (
    <>
    <Header/>
    <InnerBanner title={'diagnostic analytics'} menu={'Diagnostic Analytics'}/>
    <DiagnosticAnalytic/>
    <Affiliations/>
    <Footer/>
    </>
  )
}

export default DiagnosticAnalyticPage