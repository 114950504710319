import React from 'react'
import { Oval } from 'react-loader-spinner'

const Loader = () => {
    return (
        <Oval
            visible={true}
            height="21"
            width="21"
            color="#fff"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
            strokeWidth="5"
        />

    )
}

export default Loader