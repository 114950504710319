import React from "react";

export default function Image(attr) {
	let imgpath = `/assets/img/${attr.folder}/${attr.link}`;
	return (
		<>
				<img
					src={`${imgpath}`}
					className={attr?.className ||"img-fluid logo"}
					alt={attr.countryname}
				/>
		</>
	);
}
