import React from 'react'
import submitForm from "../../modules/submitForm"
import SuccessPopupEnquiry from './SuccessPopupEnquiry';
const FormPopup = (props) => {
  async function submit(e) {
    let info = await submitForm(e);
    if (info.msg = "Email Sent Successful") {
      props.handleFormToggle();
      props.handleSuccessToggle();
      console.log("testing for success popup","success value",props.showSuccess,"form value",props.showForm);
      document.getElementById("contact-from").reset();
      e.target.reset();
    } else {
      document.getElementById("contact-from").reset();
    }
  }
  return (
    <>
      <SuccessPopupEnquiry showSuccess={props.showSuccess} handleSuccessToggle={props.handleSuccessToggle} />
      <div class="form-popup-body" style={{ display: props.showForm ? "block" : "none" }}>
        <div className='popup-form'>
          <div class="icon" onClick={()=>{props.handleFormToggle()}}>
            <i class="fa-solid fa-xmark"></i>
          </div>
          <h2>contact us</h2>
          <form id="contact-from" method={"post"}
            action={"/mail/send/enquiry"}
            onSubmit={(e) => {
              e.preventDefault();
            }} className="mt-5">
            <div className='form-group'>
              <label htmlFor="">Your Name</label>
              <input type="text" name="name" />
            </div>

            <div className='form-group'>
              <label htmlFor="">Your email</label>
              <input type="email" name="email" />
            </div>

            <div className='form-group'>
              <label htmlFor="">Your number</label>
              <input type="tel" name="phone" />
            </div>

            <div className='form-group'>
              <label htmlFor="">Position</label>
              <input type="text" name="position" />
            </div>

            <div className='form-group'>
              <label htmlFor="">Message</label>
              <textarea name="message" ></textarea>
            </div>

            <div className='form-group'>
              <button type="button" className={"form-btn"} onClick={submit}
              >send</button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default FormPopup