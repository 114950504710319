import React from "react";
import process from "../../media/Process.json"
import apiCall from "../../modules/apiCall";


const OurProcess = () => {
  const [data, setData] = React.useState([])
  React.useEffect(() => {
      apiCall("/comp/Process")
          .then((res) => console.log(res))
          .catch((err) => console.log(err))
  }, [])
  return (
    <>
      <div className="section-padding" id="ourProcess">
        <div className="container">
          <div className="our-process-bg">
            <div className="row">
              <div className="section-heading light text-center" data-aos="fade-up" data-aos-duration="800">
                <h2>our process</h2>
              </div>

              {process.processContent.map((content, id) => {
                return (
                  <div className="col-lg-6 g-5" key={id}  data-aos="fade-up" data-aos-duration="800">
                    <div className="process-box process-box-style3">
                      <div className="process-wrap">
                        <div className="number-step">
                          <h3>{content.number}</h3>
                        </div>
                        <h6>{content.h6}</h6>
                        <div className="content">
                          <p>{content.paragraph}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurProcess;
