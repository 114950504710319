import React, { useContext } from "react";

const DownloadPopup = (props) => {
  return (
    <>
      <div class="popup-body" style={{ display: props.showDownload?"block":"none" }}>
        <div class="popup-download">
          <div class="icon">
            <i class="fa-solid fa-xmark" onClick={()=>{props.handleDownloadToggle()}}></i>
          </div>
          <i class="fa-solid fa-book-open"></i>
          <h2>Download our Business To Business Panelbooks</h2>
          <div class="message">
          </div>
          <a className="btnfos btnfos-4">
            <span>Download</span>
          </a>
        </div>
      </div>
    </>
  );
};

export default DownloadPopup;
