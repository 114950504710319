import React from 'react'

const EmployeeList = () => {
    return (
        <>
            <div className="accordion-list">
                <ul className="multy-list">
                    <li><b>Micro Enterprise</b>
                        <ul>
                            <li><i className="fa-solid fa-circle-check"></i>Self Employee</li>
                            <li><i className="fa-solid fa-circle-check"></i>0-2 Employees</li>
                            <li><i className="fa-solid fa-circle-check"></i>4-6 Employees</li>
                            <li><i className="fa-solid fa-circle-check"></i>7-9 Employees</li>
                        </ul>
                    </li>
                    <li><b>Small Enterprise</b>
                        <ul>
                            <li><i className="fa-solid fa-circle-check"></i>10-20 Employees</li>
                            <li><i className="fa-solid fa-circle-check"></i>21-30 Employees</li>
                            <li><i className="fa-solid fa-circle-check"></i>31-49 Employees</li>
                        </ul>
                    </li>
                    <li><b>Medium Enterprise</b>
                        <ul>
                            <li><i className="fa-solid fa-circle-check"></i>50-99 Employees</li>
                            <li><i className="fa-solid fa-circle-check"></i>100-149 Employees</li>
                            <li><i className="fa-solid fa-circle-check"></i>150-199 Employees</li>
                            <li><i className="fa-solid fa-circle-check"></i>200-249 Employees</li>
                        </ul>
                    </li>
                    <li><b>Large Enterprise</b>
                        <ul>
                            <li><i className="fa-solid fa-circle-check"></i>250-499 Employees</li>
                            <li><i className="fa-solid fa-circle-check"></i>500-999 Employees</li>
                            <li><i className="fa-solid fa-circle-check"></i>1000-2499 Employees</li>
                            <li><i className="fa-solid fa-circle-check"></i>2500-4999 Employees</li>
                            <li><i className="fa-solid fa-circle-check"></i>5000-9999 Employees</li>
                            <li><i className="fa-solid fa-circle-check"></i>5,000 to 9,999 Employees</li>
                            <li><i className="fa-solid fa-circle-check"></i>10,000 to 24,999 Employees</li>
                            <li><i className="fa-solid fa-circle-check"></i>25,000 to 49,999 Employees</li>
                            <li><i className="fa-solid fa-circle-check"></i>50,000 to 99,999 Employees</li>
                            <li><i className="fa-solid fa-circle-check"></i>100,000 Employees or more</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default EmployeeList