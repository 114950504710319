import React from 'react'
import industry from "../../media/cati/cati_industry.json"
import Image from '../Common/Image'
import FrequentSlider from '../Common/Slider'
const Industry = () => {
    return (
        <>
            <div className="section-padding" id='cati-industry'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading text-center mb-5">
                                <h2>Industry we <span>serve</span></h2>

                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <FrequentSlider responsive="true" slides="3">
                            {industry.Industry.Components.map((item) => (
                                <div className="col-sm-6 col-md-3 g-5">
                                    <div className='item'>
                                        <Image link={item.img} folder="cati" />
                                        <p>{item.text}</p>
                                    </div>
                                </div>
                            ))}
                        </FrequentSlider>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Industry