import React from 'react'
import Header from '../Components/Common/Header'
import InnerBanner from '../Components/Common/InnerBanner'
import Affiliations from '../Components/Common/Affiliations'
import Footer from '../Components/Common/Footer'
import PrivacyContent from '../Components/Privacy/PrivacyContent'

const PrivacyPage = () => {
  return (
    <>
        <Header/>
        <InnerBanner title={'privacy policy'} menu={'Privacy Policy'}/>
        <PrivacyContent/>
        <Affiliations/>
        <Footer/>
    </>
  )
}

export default PrivacyPage