import React from 'react'

const MediaList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Television consumption / week</li>
                            <li><i class="fa-solid fa-circle-check"></i>Consumption of print media</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radio consumption / week</li>
                            <li><i class="fa-solid fa-circle-check"></i>Use of social media</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hours of television viewed during the week and on the weekend</li>
                            <li><i class="fa-solid fa-circle-check"></i>Television service (cable, satellite, etc.)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cable service providers</li>
                            <li><i class="fa-solid fa-circle-check"></i>TV Type</li>
                            <li><i class="fa-solid fa-circle-check"></i>TV Shows Genre</li>
                            <li><i class="fa-solid fa-circle-check"></i>TV access content</li>
                            <li><i class="fa-solid fa-circle-check"></i>Streaming Services</li>
                            <li><i class="fa-solid fa-circle-check"></i>Types of TV Programs</li>
                            <li><i class="fa-solid fa-circle-check"></i>DVR User</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sound Bars/Home Theaters User</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MediaList