import React from "react";

const CountryProgress = (props) => {
  console.log("Country props", props);
  return (
    <>
      <div className="row c-mb">
        {props.child.map((item) => (
          <div className="col-sm-12 col-lg-4 g-3">
            <div className="country-card d-flex justify-content-between align-items-center">
              <h4 className="country-name">{item.country}</h4>
              <div className="country-progress d-flex align-items-center justify-content-between">
                <div className="progress-full">
                  {item.progress &&
                    <div className="progress-contrast" style={{ width: `${item?.progress}%` }}></div>}
                </div>
                <div className="progress-percent">
                  {!!item.percent ? <h5>{item.percent}%</h5> : <h5>{item.number}</h5>}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CountryProgress;
