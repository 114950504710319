import React from 'react'
import team from "../../media/Team.json"
import apiCall from '../../modules/apiCall'
import axios from "axios"
const Team = () => {
    const [list, setList] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    React.useEffect(() => {
        setLoading(true)
        apiCall("/comp/Team")
            .then(res => { setList(res.data); setLoading(false) })
            .catch(err => { console.log(err) })
    }, [])
    return loading ? <h1>Loading...</h1> :
        <>
            <div className="section-padding" id='team'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading text-center" data-aos="fade-up" data-aos-duration="800">
                                <h2>meet our <span>team</span></h2>
                            </div>
                        </div>
                    </div>

                    <div className="about-our-team">
                        <div className="row">
                            {list.TeamMember.map((item, i) => (
                                <div className="col-sm-6 col-lg-4 col-xl-3 g-5" key={i}>
                                    <div className="box h-100 p-3 text-center" data-aos="fade-up" data-aos-duration="800">
                                        <div className="image-wrapper">
                                            <img src={item.image} alt={item.name} />
                                        </div>
                                        <div className="content-wrapper">
                                            <h6>{item.position}</h6>
                                            <h3>{item.name}</h3>
                                            <ul>
                                                <li>
                                                    <a href={item.LinkedinLink}>
                                                        <i className={item.LinkedinIcon}></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={item.EmailLink}>
                                                        <i className={item.EmailIcon}></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
}

export default Team