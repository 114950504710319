import React from "react";
import ConsultancyContent from '../../media/ConsultancyContent.json'
import apiCall from "../../modules/apiCall";

const ConsultancyCard = () => {
  const [data, setData] = React.useState([])
  React.useEffect(() => {
      apiCall("/comp/ConsultancyContent")
          .then((res) => console.log(res))
          .catch((err) => console.log(err))
  }, [])
  return (
    <>
      <div className="research-consultancy-list">
        <div className="row">
          {ConsultancyContent.researchconsultancy.map((item, i) => (
            <div className="col-lg-6 g-5" key={i}>
              <div className="concultancy-item-box" data-aos="fade-up" data-aos-duration="800">
                <div className="image-box color-dark image-box-style2">
                  <div className="caption">
                    <div className="thumb-service">
                      <div className="thumb" style={{
                        backgroundImage: `${item.bgimg}`
                      }}></div>
                    </div>
                    <div className="title-wrap">
                      <h3>{item.title}</h3>
                      <div className="content">
                        <p>{item.content}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          ))}
        </div>
      </div>
    </>
  );
};

export default ConsultancyCard;
