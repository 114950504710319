import React from 'react'

const OperationList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Anaesthesiologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Nurse</li>
                            <li><i class="fa-solid fa-circle-check"></i>Operating Room Nurse</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Technologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Anaesthesia Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Operating Room Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Scrub Nurse</li>
                            <li><i class="fa-solid fa-circle-check"></i>Circulating Nurse</li>
                            <li><i class="fa-solid fa-circle-check"></i>Recovery Room Nurse</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Assistant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Services Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Services Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Nurse Educator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Quality Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Instrument Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Equipment Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Perioperative Nurse</li>
                            <li><i class="fa-solid fa-circle-check"></i>Perioperative Services Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Perioperative Services Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Scheduler</li>
                            <li><i class="fa-solid fa-circle-check"></i>Anaesthesia Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Materials Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Equipment Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Supply Chain Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Data Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Perioperative Nursing Educator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Perioperative Quality Improvement Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Safety Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Operating Room Administrator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Billing and Coding Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Recovery Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Pre-Admission Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Pathology Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Research Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Instrumentation Supervisor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Operating Room Service Line Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Risk Manager</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OperationList