import React from 'react'
import { path } from '../../path'
import sector from '../../media/sector.json'
import Image from '../Common/Image'

const SectorList = () => {
  return (
    <>
      <div className="row mb-5">
        <div className="accordion-list">
          <ul className="multy-list">
            <li><b>Agriculture</b></li>
          </ul>
        </div>
        {sector.agriculture.map((n, i) => {
          return (<>
            <div className="col-6 col-md-4 d-flex align-items-center country" key={i}>
              <div className="icon-img me-4">
                <Image link={n.sectorlink} folder="sector" />
              </div>
              <div className="icon-name">
                <span>{n.sectorname}</span>
              </div>
            </div>
          </>)
        })}

        <div className="accordion-list">
          <ul className="multy-list">
            <li><b>Manufacturing</b></li>
          </ul>
        </div>
        {sector.manufacturing.map((n, i) => {
          return (<>
            <div className="col-6 col-md-4 d-flex align-items-center country" key={i}>
              <div className="icon-img me-4">
                <Image link={n.sectorlink} folder="sector" />
              </div>
              <div className="icon-name">
                <span>{n.sectorname}</span>
              </div>
            </div>
          </>)
        })}

        <div className="accordion-list">
          <ul className="multy-list">
            <li><b>Service Industry</b></li>
          </ul>
        </div>
        {sector.service.map((n, i) => {
          return (<>
            <div className="col-6 col-md-4 d-flex align-items-center country" key={i}>
              <div className="icon-img me-4">
                <Image link={n.sectorlink} folder="sector" />
              </div>
              <div className="icon-name">
                <span>{n.sectorname}</span>
              </div>
            </div>
          </>)
        })}

        <div className="accordion-list">
          <ul className="multy-list">
            <li><b>Govt,Public & Defense Industry</b></li>
          </ul>
        </div>
        {sector.govt.map((n, i) => {
          return (<>
            <div className="col-6 col-md-4 d-flex align-items-center country" key={i}>
              <div className="icon-img me-4">
                <Image link={n.sectorlink} folder="sector" />
              </div>
              <div className="icon-name">
                <span>{n.sectorname}</span>
              </div>
            </div>
          </>)
        })}
      </div>
    </>
  )
}

export default SectorList