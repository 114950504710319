import React from 'react'

const ComputerList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Video game console/ handheld</li>
                            <li><i class="fa-solid fa-circle-check"></i>Gaming platforms</li>
                            <li><i class="fa-solid fa-circle-check"></i>Video Game Genres</li>
                            <li><i class="fa-solid fa-circle-check"></i>Game intensity / week</li>
                            <li><i class="fa-solid fa-circle-check"></i>Single / multi player</li>
                            <li><i class="fa-solid fa-circle-check"></i>Computer game purchases</li>
                            <li><i class="fa-solid fa-circle-check"></i>Gaming accessories </li>
                            <li><i class="fa-solid fa-circle-check"></i>Gaming Websites</li>
                            <li><i class="fa-solid fa-circle-check"></i>Gaming Magazines</li>
                            <li><i class="fa-solid fa-circle-check"></i>Household members involved in games</li>
                            <li><i class="fa-solid fa-circle-check"></i>Types of video game</li>
                            <li><i class="fa-solid fa-circle-check"></i>Number of games purchased</li>
                            <li><i class="fa-solid fa-circle-check"></i>Number of games rented</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ComputerList