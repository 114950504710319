import React from 'react'
import TestimonialCards from './TestimonialCards'

const Testimonial = () => {
  return (
    <>
        <div className="section-padding" id='testimonial'>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="section-heading text-center">
                            <h2>client <span>feedback</span></h2>
                        </div>
                    </div>

                    <TestimonialCards/>
                </div>
            </div>
        </div>
    </>
  )
}

export default Testimonial