import React from 'react'

const CompanyLocationList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i className="fa-solid fa-circle-check"></i>One</li>
                            <li><i className="fa-solid fa-circle-check"></i>Two</li>
                            <li><i className="fa-solid fa-circle-check"></i>Three</li>
                            <li><i className="fa-solid fa-circle-check"></i>Four</li>
                            <li><i className="fa-solid fa-circle-check"></i>Five</li>
                            <li><i className="fa-solid fa-circle-check"></i>More than 5</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanyLocationList