import React from 'react'
import FooterTopCard from './FooterTopCard'
import FooterMain from './FooterMain'
import FooterBottom from './FooterBottom'


const Footer = () => {
  return (
    <>
      <footer className='' id='footer'>
        <div className="container-fluid">
          <FooterTopCard />
        </div>
        <div className="container">
          <div className="footer-main">
            <div className="row">
              <FooterMain />
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <FooterBottom />
        </div>
      </footer>
    </>
  )
}

export default Footer