import React from 'react'

const BeautyList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i className="fa-solid fa-circle-check"></i>Fashion Style</li>
                            <li><i className="fa-solid fa-circle-check"></i>Make-Up Products Users</li>
                            <li><i className="fa-solid fa-circle-check"></i>Facial Skin Care Products Users</li>
                            <li><i className="fa-solid fa-circle-check"></i>Hair Products</li>
                            <li><i className="fa-solid fa-circle-check"></i>Hair Replacement</li>
                            <li><i className="fa-solid fa-circle-check"></i>Nail Products User</li>
                            <li><i className="fa-solid fa-circle-check"></i>Foot Care Products</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BeautyList