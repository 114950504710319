import React from 'react'
import Header from '../../Components/Common/Header'
import InnerBanner from '../../Components/Common/InnerBanner'
import TaxCompliance from '../../Components/TaxConsulting/TaxCompliance'
import Affiliations from '../../Components/Common/Affiliations'
import Footer from '../../Components/Common/Footer'

const TaxCompliancePage = () => {
  return (
    <>
        <Header/>
        <InnerBanner title={'tax compliance'} menu={'Tax Compliance'}/>
        <TaxCompliance/>
        <Affiliations/>
        <Footer/>
    </>
  )
}

export default TaxCompliancePage