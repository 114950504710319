import React from 'react'
import brief from '../../media/BriefCard.json'

const BriefCards = () => {
    return (
        <>
            {brief.briefContent.map((item, i) => (
                <div className="col-12 col-sm-6">
                    <div className="brief-card">
                        <div className="about-icon">
                            <i className={item.icon}></i>
                        </div>
                        <h3>{item.h3}</h3>
                        <p>{item.para}</p>
                    </div>
                </div>
            ))}
        </>
    )
}

export default BriefCards