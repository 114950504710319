import React from 'react'

const ElectronicList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Product ownership</li>
                            <li><i class="fa-solid fa-circle-check"></i>Early adopters</li>
                            <li><i class="fa-solid fa-circle-check"></i>Main decision-maker</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ElectronicList