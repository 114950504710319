import React,{useContext}from 'react'
import FormPopup from '../Popup/FormPopup'
import { PopupContext } from '../../PopupContext';

const HeroContact = () => {
  const popup = useContext(PopupContext)
  return (
    <>
    <FormPopup {...popup} />
      <div className="col-md-12 hero">
        <div className='hero-contact'>
          <a onClick={()=>{popup.handleFormToggle()}} >Enquire Today</a>
        </div>
        <div className='hero-social'>
          <a href="https://www.facebook.com/frequentrfs/"
            target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
          <a href="https://in.linkedin.com/company/frequentresearch"
            target="_blank"><i class="fa-brands fa-linkedin-in"></i></a>
          <a href="https://twitter.com/frequentrfs" target="_blank">
            <i class="fa-brands fa-twitter"></i>
          </a>
          <a href="https://www.youtube.com/channel/UCLxmn2kq4aACAHaL2eT6QIQ"
            target="_blank"> <i class="fa-brands fa-youtube"></i></a>
        </div>
      </div>
    </>
  )
}

export default HeroContact