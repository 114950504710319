import React from 'react'

const MedicalRecordList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Health Information Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Health Information Administrator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Records Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Health Information Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Clinical Documentation Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Release of Information Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Data Quality Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Privacy Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Compliance Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>HIPAA Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Coding Auditor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Abstractor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cancer Registrar</li>
                            <li><i class="fa-solid fa-circle-check"></i>Tumor Registrar</li>
                            <li><i class="fa-solid fa-circle-check"></i>Clinical Data Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Records Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>HIM System Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>HIM Educator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Record Retrieval Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Document Imaging Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Electronic Health Record (EHR) Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chart Completion Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Information Governance Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Billing and Coding Supervisor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Health Information Management Consultant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Records Auditor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Health Information Privacy Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Data Entry Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Records Scanning Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Coding Compliance Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Records Release Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Records Archivist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Records Abstracting Clerk</li>
                            <li><i class="fa-solid fa-circle-check"></i>Health Information Researcher</li>
                            <li><i class="fa-solid fa-circle-check"></i>Health Information Support Staff</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MedicalRecordList