import React from 'react'

const DepartmentList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Non Medical Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Department</li>
                            <li><i class="fa-solid fa-circle-check"></i>Clinical Departments </li>
                            <li><i class="fa-solid fa-circle-check"></i>Nursing Department&nbsp;</li>
                            <li><i class="fa-solid fa-circle-check"></i>Supportive Departments&nbsp;</li>
                            <li><i class="fa-solid fa-circle-check"></i>Technical Departments</li>
                            <li><i class="fa-solid fa-circle-check"></i>Administrative Departments</li>
                            <li><i class="fa-solid fa-circle-check"></i>Paramedical Departments</li>
                            <li><i class="fa-solid fa-circle-check"></i>Physical Medicine and Rehabilitation Department</li>
                            <li><i class="fa-solid fa-circle-check"></i>Operation Theatre Complex (OT)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Department (X-ray Department)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Dietary Department</li>
                            <li><i class="fa-solid fa-circle-check"></i>Non-Professional Services (Business Management)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Purchasing Department</li>
                            <li><i class="fa-solid fa-circle-check"></i>Central Sterile Supply Department (CSSD)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Record Department (MRD)</li>
                            <li><i class="fa-solid fa-circle-check"></i>Personnel Department</li>
                            <li><i class="fa-solid fa-circle-check"></i>Security Department</li>
                            <li><i class="fa-solid fa-circle-check"></i>Maintenance Department</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacy Department</li>
                            <li><i class="fa-solid fa-circle-check"></i>Top Level Management Department</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DepartmentList