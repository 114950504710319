import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate()
  const [view, setView] = React.useState(false)
  const [submenu, setSubMenu] = React.useState("")
  const handleToggle = () => {
    setView(!view)
  }
  const handleSubToggle = (e) => {
    if (submenu === e) {
      setSubMenu("")
    } else {
      setSubMenu(e)
    }
  }
  return (
    <>
      <header className="header" id="header">
        <section className="wrapper container">
          <Link to='/' className="brand">
            <img src="/assets/img/logo.png" alt="" />
          </Link>
          <div className="burger" id="burger" onClick={() => { handleToggle() }}>
            <span className="burger-line"></span>
            <span className="burger-line"></span>
            <span className="burger-line"></span>
          </div>
          <span className={view ? "overlay active" : "overlay"}  ></span>
          <nav className={view ? "navbar-menu active" : "navbar-menu"} id="navbar">
            <div className='cancel-icon'>
              <i className="fa-solid fa-xmark" onClick={() => { handleToggle() }}></i>
            </div>
            <ul className="menu" id="menu">
              <Link to="/about">
                <li className="menu-item menu-dropdown">
                  <a className="menu-link" >About Us</a>
                </li>
              </Link>
              <li className={submenu === "services" ? "menu-item menu-dropdown" : "menu-item menu-dropdown active"} onClick={() => { handleSubToggle("services") }}>
                <span className="menu-link" data-toggle="submenu">Services<i className="fa-solid fa-angle-down"></i></span>
                <ul className="submenu" style={submenu === "services" ? { maxHeight: "250px" } : null}>
                  <Link to="/research-solution">
                    <li className="submenu-item"><a className="submenu-link">Research Solution</a>
                    </li>
                  </Link>
                  <Link to="/research-consultancy">
                    <li className="submenu-item"><a className="submenu-link">Research Consultancy</a></li>
                  </Link>
                  <Link to='/tax-consulting'>
                    <li className="submenu-item"><a className="submenu-link">Tax Consulting</a></li>
                  </Link>
                  <Link to='/analytic-solution'>
                    <li className="submenu-item"><a className="submenu-link">Analytic Solutions</a></li>
                  </Link>
                <!--  <Link to='/software-website-solution'>
                    <li className="submenu-item"><a className="submenu-link">Software & Website Solutions</a></li>
                  </Link>
                  <Link to='/online-marketing-solution'>
                    <li className="submenu-item"><a className="submenu-link">Online Marketing Solutions</a></li>
           -->       </Link>

                </ul>
              </li>
              <li className={submenu === "ourpanels" ? "menu-item menu-dropdown" : "menu-item menu-dropdown active"} onClick={() => { handleSubToggle("ourpanels") }}>
                <span className="menu-link" data-toggle="submenu">Our Panels<i className="fa-solid fa-angle-down"></i></span>
                <ul className="submenu" style={submenu === "ourpanels" ? { maxHeight: "200px" } : null}>
                  <Link to='/business-to-business'>
                    <li className="submenu-item"><a className="submenu-link">Business To Business</a></li>
                  </Link>
                  <Link to='/business-to-consumer'>
                    <li className="submenu-item"><a className="submenu-link">Business To Consumer</a></li>
                  </Link>
                  <Link to='/healthcare'>
                    <li className="submenu-item"><a className="submenu-link">Healthcare</a></li>
                  </Link>
                  <Link to='/esomar-37'>
                    <li className="submenu-item"><a className="submenu-link">ESOMAR 37</a></li>
                  </Link>
                </ul>
              </li>
              <li className={submenu === "joinpanels" ? "menu-item menu-dropdown" : "menu-item menu-dropdown active"} onClick={() => { handleSubToggle("joinpanels") }}>
                <span className="menu-link" data-toggle="submenu">Join Our Panels<i className="fa-solid fa-angle-down"></i></span>
                <ul className="submenu" style={submenu === "joinpanels" ? { maxHeight: "200px" } : null}>
                  <li className="submenu-item"><a href="https://turn2opinion.com/" className="submenu-link" target='_blank'>Turn2Opinion</a></li>
                  <li className="submenu-item"><a href="https://www.myhealthopinions.com/" className="submenu-link" target='_blank'>MyHealthOpinions</a></li>
                  <li className="submenu-item"><a href="https://myfrequentrewards.com/" className="submenu-link" target='_blank'>MyFrequentRewards</a></li>
                </ul>
              </li>
              <Link to='/career'>
                <li className="menu-item"><a className="menu-link">Career</a></li>
              </Link>
            </ul>
          </nav>
        </section >
      </header >
    </>
  )
}

export default Header
