import React from 'react';

const ProcessItem = () => {
  return (
    <>
      <div className="process-border row">
        <div className="col-6 col-sm-4 col-lg-2 g-5" data-aos="fade-right" data-aos-duration="500">
          <div className="process-item top">
            <div className="num">
              <span>01</span>
            </div>
            <div className="icon">
              <i className="fa-brands fa-searchengin"></i>
            </div>
            <h4>
              Discover & Explore your need
            </h4>
          </div>
        </div>

        <div className="col-6 col-sm-4 col-lg-2 g-5" data-aos="fade-right" data-aos-delay="800" data-aos-duration="500">
          <div className="process-item bottom">
            <div className="num">
              <span>02</span>
            </div>
            <div className="icon">
              <i className="fa-solid fa-bullseye"></i>
            </div>
            <h4>
              Strategy are designed
            </h4>
          </div>
        </div>

        <div className="col-6 col-sm-4 col-lg-2 g-5" data-aos="fade-right" data-aos-delay="1300" data-aos-duration="500">
          <div className="process-item top">
            <div className="num">
              <span>03</span>
            </div>
            <div className="icon">
              <i className="fa-solid fa-magnifying-glass-arrow-right"></i>
            </div>
            <h4>
              Best plan & Methodology are Implemented
            </h4>
          </div>
        </div>

        <div className="col-6 col-sm-4 col-lg-2 g-5" data-aos="fade-right" data-aos-delay="1800" data-aos-duration="500">
          <div className="process-item bottom">
            <div className="num">
              <span>04</span>
            </div>
            <div className="icon">
              <i className="fa-solid fa-magnifying-glass-chart"></i>
            </div>
            <h4>
              Actions are analysed
            </h4>
          </div>
        </div>

        <div className="col-6 col-sm-4 col-lg-2 g-5" data-aos="fade-right" data-aos-delay="2300" data-aos-duration="500">
          <div className="process-item top">
            <div className="num">
              <span>05</span>
            </div>
            <div className="icon">
              <i className="fa-solid fa-dna"></i>
            </div>
            <h4>
              Evaluation & review
            </h4>
          </div>
        </div>

        <div className="col-6 col-sm-4 col-lg-2 g-5" data-aos="fade-right" data-aos-delay="2800" data-aos-duration="500">
          <div className="process-item bottom">
            <div className="num">
              <span>06</span>
            </div>
            <div className="icon">
              <i className="fa-solid fa-square-poll-vertical"></i>
            </div>
            <h4>
              Results defined & reported
            </h4>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProcessItem