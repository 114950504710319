import React from "react";
import { Link } from "react-router-dom";
import cardContent from "../../media/ConsultingCard.json"
import apiCall from "../../modules/apiCall";

const CardImage = () => {
  const [data, setData] = React.useState([])
  React.useEffect(() => {
      apiCall("/comp/ConsultingCard")
          .then((res) => console.log(res))
          .catch((err) => console.log(err))
  }, [])
  return (
    <>
      <div className="section-padding" id="tax">
        <div className="container">
          <div className="row">
            {cardContent.TaxConsulting.map((item) => (
              <div className="col-md-6 col-lg-4 g-5">
                <div className="service-box">
                  <div className="service-content">
                    <div className="image-box">
                      <img src={item.image} className="img-fluid" alt="" />
                    </div>
                    <div className="content-inner">
                      <h2>{item.h2}</h2>
                      <p>{item.para}</p>
                      <Link to={ item.link} className="btnfos btnfos-4">
                        <span>{item.span}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </>
  );
};

export default CardImage;
