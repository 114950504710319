import React from 'react'

const NonMedicalList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Acupuncturist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Audiologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Ayurvedic Practitioner</li>
                            <li><i class="fa-solid fa-circle-check"></i>Chiropractor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Diagnostician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Homeopathic Doctor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Microbiologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Naturopathic Doctor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Nutritionist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pharmacist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Physiotherapist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Podiatrist / Chiropodist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Registered Massage Therapist</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NonMedicalList