import React, { useState, useEffect, useCallback } from "react";
import { PrevButton, NextButton } from "./RoadmapCarouselButton";
import useEmblaCarousel from "embla-carousel-react";
import Roadmap from '../../media/Roadmap.json'
import Image from "../Common/Image";

const PARALLAX_FACTOR = 1.2;

const RoadmapCarousel = ({ slides }) => {
    const [viewportRef, embla] = useEmblaCarousel({
        loop: false
    });
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
    const [parallaxValues, setParallaxValues] = useState([]);

    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

    const onSelect = useCallback(() => {
        if (!embla) return;
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
    }, [embla]);

    const onScroll = useCallback(() => {
        if (!embla) return;

        const engine = embla.internalEngine();
        const scrollProgress = embla.scrollProgress();

        const styles = embla.scrollSnapList().map((scrollSnap, index) => {
            if (!embla.slidesInView().includes(index)) return 0;
            let diffToTarget = scrollSnap - scrollProgress;

            if (engine.options.loop) {
                engine.slideLooper.loopPoints.forEach((loopItem) => {
                    const target = loopItem.getTarget();
                    if (index === loopItem.index && target !== 0) {
                        const sign = Math.sign(target);
                        if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress);
                        if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress);
                    }
                });
            }
            return diffToTarget * (-1 / PARALLAX_FACTOR) * 100;
        });
        setParallaxValues(styles);
    }, [embla, setParallaxValues]);

    useEffect(() => {
        if (!embla) return;
        onSelect();
        onScroll();
        embla.on("select", onSelect);
        embla.on("scroll", onScroll);
        embla.on("resize", onScroll);
    }, [embla, onSelect, onScroll]);

    return (
        <div className="section-padding" id="roadmap">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="embla">
                            <div className="embla__viewport" ref={viewportRef}>
                                <div className="embla__container">
                                    {Roadmap.roadmap.map((index) => (
                                        <div className="embla__slide" key={index}>
                                            <div className="ribbon">
                                                <h4 className="year"><span>{index.year}</span></h4>
                                            </div>
                                            <div className="embla__slide__inner">
                                                <div
                                                    className="embla__slide__parallax"
                                                    style={{ transform: `translateX(${parallaxValues[index]}` }}>
                                                    <div className="slider-overlay"></div>
                                                    <Image folder="roadmap" link={index?.imglink} />
                                                    <div className="embla-slide-content">
                                                        {!!index.logoimg && !!index.logoimg1 ?
                                                            <div>
                                                                <Image folder="roadmap" link={index?.logoimg} />
                                                                <Image folder="roadmap" link={index?.logoimg1} />
                                                            </div>

                                                            : !!index.logoimg ? <Image folder="roadmap" link={index?.logoimg} className={index.class} /> : null}
                                                        <h2>                                                        <i className={index.icon}></i>
                                                            {index.title} </h2>
                                                        <p>{index.text}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
                            <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RoadmapCarousel;
