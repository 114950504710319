import React from 'react'

const EnviromentList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Level of interest in environmental issues</li>
                            <li><i class="fa-solid fa-circle-check"></i>Level of concern for the environment</li>
                            <li><i class="fa-solid fa-circle-check"></i>Most concerning environmental problems faced</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EnviromentList