import React from 'react'
import Header from '../Components/Common/Header'
import InnerBanner from '../Components/Common/InnerBanner'
import Affiliations from '../Components/Common/Affiliations'
import Footer from '../Components/Common/Footer'
import MarketingCardImage from '../Components/MarketingSolution/MarketingCardImage'

const MarketingSolutionPage = () => {
  return (
    <>
        <Header/>
        <InnerBanner title={'online marketing solution'} menu={'Online Marketing Solution'}/>
        <MarketingCardImage/>
        <Affiliations/>
        <Footer/>
    </>
  )
}

export default MarketingSolutionPage