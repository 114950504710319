import React from 'react'

const MobileList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Mobile Device</li>
                            <li><i class="fa-solid fa-circle-check"></i>CellPhone Brands</li>
                            <li><i class="fa-solid fa-circle-check"></i>Mobile Apps</li>
                            <li><i class="fa-solid fa-circle-check"></i>Mobile OS</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cell phone features and activities</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MobileList