import React from 'react'

const B2CEntryContent = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="entry-content" data-aos="fade-up" data-aos-duration="800">
                        <div className="entry-section-head text-center text-capitalize">
                            <h2>Business 2 Consumer Panel</h2>
                        </div>
                        <div className="content mt-5">
                            <p>Frequent Research (www.frequentresearch.com), is a premier provider of technology and survey respondents used to obtain consumer and business insights.</p>
                            <p>By tapping into Frequent Research’s global panel, companies can make better, faster decisions about their products and services. Through Frequent Research’s proprietary technologies for automated sample, sample service, mobile insights, concept testing, and survey authoring, companies have on demand access to millions of profiled survey respondents.</p>
                            <p>We’re able to reach niche panellists across any consumer demographic from over 55+ countries in the Americas, Europe,Asia-Pacific &amp; MENA Markets.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default B2CEntryContent