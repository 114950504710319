import React from 'react'

const CentralSterileList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>CSSD Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Supervisor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterilization Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Central Sterile Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Instrument Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Decontamination Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Supply Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Educator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Instrument Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Lead</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Assistant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Tray Assembly Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Instrument Inspector</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Supply Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Materials Management Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Equipment Validation Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Auditor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Consultant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Quality Assurance Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Instrument Tracking Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Trainer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Endoscope Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Shift Supervisor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Inventory Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Data Analyst</li>
                            <li><i class="fa-solid fa-circle-check"></i>Instrumentation Supervisor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Team Leader</li>
                            <li><i class="fa-solid fa-circle-check"></i>Central Service Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Technician II</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgical Instrument Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Loaner Instrument Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Support Staff</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Technician III</li>
                            <li><i class="fa-solid fa-circle-check"></i>Instrument Room Clerk</li>
                            <li><i class="fa-solid fa-circle-check"></i>Sterile Processing Research Assistant</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CentralSterileList