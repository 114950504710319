import React from 'react'
import expertise from "../../media/cati/cati_expertise.json"
const CatiExpertise = () => {
    return (
        <>
            <div className="section-padding" id='cati-expertise'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading light text-center mb-4">
                                <h2>cati expertise</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {expertise.Expertise.Components.map((item) => (
                            <div className="col-sm-6 col-md-3 g-5">
                                <div className="expertise-item">
                                    <i class="fa-solid fa-star"></i>
                                    <p>{item}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CatiExpertise