import React from 'react'
import Header from '../Components/Common/Header'
import InnerBanner from '../Components/Common/InnerBanner'
import Affiliations from '../Components/Common/Affiliations'
import Footer from '../Components/Common/Footer'
import HealthcareContent from '../Components/Healthcare/HealthcareContent'
import HealthcareAccordion from '../Components/Healthcare/HealthcareAccordion'

const HealthcarePage = () => {
    return (
        <>
            <Header />
            <InnerBanner title={'healthcare'} menu={'Healthcare'} />
            <div className="section-padding" id='healthcare'>
                <div className="container">
                    <HealthcareContent/>
                </div>
            </div>
            <HealthcareAccordion/>

            <Affiliations />
            <Footer />
        </>
    )
}

export default HealthcarePage