import React from 'react'

const ClinicalList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Ambulatory Nurse</li>
                            <li><i class="fa-solid fa-circle-check"></i>Behavioral Health Charge Nurse</li>
                            <li><i class="fa-solid fa-circle-check"></i>Bereavement Counselor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cardiac Catheterization Lab Nurse</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cardiovascular Operating Room Nurse</li>
                            <li><i class="fa-solid fa-circle-check"></i>Certified Nursing Assistant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Charge Nurse</li>
                            <li><i class="fa-solid fa-circle-check"></i>Counselor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Dentist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Dermatology Nurse</li>
                            <li><i class="fa-solid fa-circle-check"></i>Dialysis Nurse</li>
                            <li><i class="fa-solid fa-circle-check"></i>Doctor</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClinicalList