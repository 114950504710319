import React from 'react'
import Header from '../Components/Common/Header'
import Affiliations from '../Components/Common/Affiliations'
import Footer from '../Components/Common/Footer'
import InnerBanner from '../Components/Common/InnerBanner'
import QuantitativeSolution from '../Components/ResearchSolution/QuantitativeSolution'
import QualitativeSolution from '../Components/ResearchSolution/QualitativeSolution'

const ResearchSolutionPage = () => {
  return (
    <>
        <Header/>
        <InnerBanner title={"research solutions"} menu={'Research Solutions'}/>
        <QuantitativeSolution/>
        <QualitativeSolution/>
        <Affiliations/>
        <Footer/>
    </>
  )
}

export default ResearchSolutionPage