import React from 'react'
import Header from '../../Components/Common/Header'
import InnerBanner from '../../Components/Common/InnerBanner'
import Affiliations from '../../Components/Common/Affiliations'
import Footer from '../../Components/Common/Footer'
import InternationalTax from '../../Components/TaxConsulting/InternationalTax'

const InternationalTaxPage = () => {
  return (
    <>
        <Header/>
        <InnerBanner title={'international tax consulting'} menu={'International Tax Consulting'}/>
        <InternationalTax/>
        <Affiliations/>
        <Footer/>
    </>
  )
}

export default InternationalTaxPage