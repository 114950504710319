import React from 'react'
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import MedicalList from './AccordionItem/MedicalList';
import NonMedicalList from './AccordionItem/NonMedicalList';
import MedicalDepartmentList from './AccordionItem/MedicalDepartmentList';
import ClinicalList from './AccordionItem/ClinicalList';
import NursingList from './AccordionItem/NursingList';
import OperationList from './AccordionItem/OperationList';
import RadiologyList from './AccordionItem/RadiologyList';
import DietaryList from './AccordionItem/DietaryList';
import NonProfessionalList from './AccordionItem/NonProfessionalList';
import PurchasingList from './AccordionItem/PurchasingList';
import CentralSterileList from './AccordionItem/CentralSterileList';
import MedicalRecordList from './AccordionItem/MedicalRecordList';
import PersonnelList from './AccordionItem/PersonnelList';
import SecurityList from './AccordionItem/SecurityList';
import MaintenanceList from './AccordionItem/MaintenanceList';
import PharmacyList from './AccordionItem/PharmacyList';
import TopLevelList from './AccordionItem/TopLevelList';

const AccordionItem = ({ header, ...rest }) => (
    <Item
        {...rest}
        header={
            <>
                {header}
                <div className='job-chevron-icon'>
                    <i class="fa-solid fa-angle-right chevron"></i>
                </div>
            </>
        }
        className={"jobitem"}
        buttonProps={{
            className: ({ isEnter }) =>
                `${"jobitemBtn key"} ${isEnter && "itemBtnExpanded"}`,
        }}
        contentProps={{ className: "jobContent" }}
        panelProps={{ className: "jobPanel" }}
    />
);

const JobRoleAccordion = () => {
    return (
        <>
            <div className={"styles.job-accordion"}>
                <Accordion transition transitionTimeout={350}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                        <AccordionItem header="clinical department">
                                <ClinicalList />
                            </AccordionItem>
                        <AccordionItem header="medical department">
                                <MedicalDepartmentList />
                            </AccordionItem>
                            
                            <AccordionItem header="non medical specialist">
                                <NonMedicalList />
                            </AccordionItem>
                            <AccordionItem header="medical specialist">
                                <MedicalList />
                            </AccordionItem>


                        

                           

                            <AccordionItem header="operation theatre complex (OT)">
                                <OperationList />
                            </AccordionItem>

                            <AccordionItem header="Radiology Department (X-ray Department)">
                                <RadiologyList/>
                            </AccordionItem>

                            <AccordionItem header="Dietary Department">
                                <DietaryList/>
                            </AccordionItem>

                            <AccordionItem header="Non-Professional Services (Business Management)">
                               <NonProfessionalList/>
                            </AccordionItem>

                            <AccordionItem header="Purchasing Department">
                               <PurchasingList/>
                            </AccordionItem>

                            <AccordionItem header="Central Sterile Supply Department (CSSD)">
                               <CentralSterileList/>
                            </AccordionItem>

                            <AccordionItem header="Medical Record Department (MRD)">
                              <MedicalRecordList/>
                            </AccordionItem>

                            <AccordionItem header="Personnel Department">
                                <PersonnelList/>
                            </AccordionItem>

                            <AccordionItem header="Security Department">
                                <SecurityList/>
                            </AccordionItem>

                            <AccordionItem header="Maintenance Department">
                               <MaintenanceList/>
                            </AccordionItem>

                            <AccordionItem header="Pharmacy Department">
                                <PharmacyList/>
                            </AccordionItem>

                            <AccordionItem header="Top Level Department">
                               <TopLevelList/>
                            </AccordionItem>
                        </div>
                    </div>
                </Accordion>
            </div>
        </>
    )
}

export default JobRoleAccordion