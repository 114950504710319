import React from 'react'

const HospitalList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Academic institution or teaching hospital</li>
                            <li><i class="fa-solid fa-circle-check"></i>Private clinic</li>
                            <li><i class="fa-solid fa-circle-check"></i>Community clinic</li>
                            <li><i class="fa-solid fa-circle-check"></i>University hospital</li>
                            <li><i class="fa-solid fa-circle-check"></i>General</li>
                            <li><i class="fa-solid fa-circle-check"></i>National hospital</li>
                        </ul>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HospitalList