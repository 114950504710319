import React from 'react'

const CarList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Car ownership</li>
                            <li><i class="fa-solid fa-circle-check"></i>Car brand</li>
                            <li><i class="fa-solid fa-circle-check"></i>Main decision-maker Car type</li>
                            <li><i class="fa-solid fa-circle-check"></i>Year the car was made </li>
                            <li><i class="fa-solid fa-circle-check"></i>Year the car was purchased</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CarList