import React from 'react'
import Header from '../../Components/Common/Header'
import InnerBanner from '../../Components/Common/InnerBanner'
import AdvanceAnalytic from '../../Components/AnalyticSolution/AdvanceAnalytic'
import Affiliations from '../../Components/Common/Affiliations'
import Footer from '../../Components/Common/Footer'

const AdvanceAnalyticPage = () => {
  return (
    <>
        <Header/>
        <InnerBanner title={"advanced analytics"} menu={'Advanced Analytics'}/>
        <AdvanceAnalytic/>
        <Affiliations/>
        <Footer/>
    </>
  )
}

export default AdvanceAnalyticPage