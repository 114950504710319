import React from 'react'
import Header from '../Components/Common/Header'
import InnerBanner from '../Components/Common/InnerBanner'
import Affiliations from '../Components/Common/Affiliations'
import Footer from '../Components/Common/Footer'
import OurProcess from '../Components/ResearchConsultancy/OurProcess'
import ConsultancyCard from '../Components/ResearchConsultancy/ConsultancyCard'


const ResearchConsultancyPage = () => {
    return (
        <>
            <Header />
            <InnerBanner title={'research consultancy'} menu={'Research Consultancy'} />
            <div className="section-padding">
                <div className="container">
                    <div className="row" data-aos="fade-up" data-aos-duration="800">
                        <div className="col-12">
                            <div className="entry-content">
                                <p>
                                    Frequent Research experience researcher &amp; expertise helps
                                    you to make the right decision for your business. Our highly
                                    experienced consultants and foresightedness always thrive to
                                    make you reach your business objectives with right decisions
                                    to gain growth. Our highly experienced and trained specialist
                                    analyse your business need and provide the best strategy to
                                    generate flawless results and growth for your business.
                                </p>
                                <p>
                                    Our research toolkit approaches and methodologies are designed
                                    to support clients through the different stages of the
                                    research cycle &#8211; whether it’s the discovery phase, idea
                                    generation and NPD, validating and optimizing, or in a more
                                    consultative role: We structure our methods into four areas.
                                    You can find out more about each below&#8230;
                                </p>
                            </div>
                        </div>
                        <div className="section-head text-center mt-4 mb-4">
                            <h3>Our Research consultancy service support includes:</h3>
                        </div>
                    </div>

                    <ConsultancyCard />
                </div>
            </div>
            <OurProcess />
            <Affiliations />
            <Footer />
        </>
    )
}

export default ResearchConsultancyPage