import React from 'react'
import SolutionCards from './SolutionCards'

const Solution = () => {
    return (
        <>
            <div className="section-padding-skew" id='solution'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading text-center" data-aos="fade-down" data-aos-duration="800">
                                <h2>our <span>solution</span></h2>
                            </div>
                        </div>
                        <SolutionCards />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Solution