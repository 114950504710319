import React from 'react'
import Header from '../Components/Common/Header'
import InnerBanner from '../Components/Common/InnerBanner'
import Affiliations from '../Components/Common/Affiliations'
import Footer from '../Components/Common/Footer'
import WebsiteCardImage from '../Components/WebsiteSolution/WebsiteCardImage'
const WebsiteSolutionPage = () => {
  return (
    <>
      <Header/>
      <InnerBanner title={'software & website solutions'} menu={'Software & Website Solutions'}/>
      <WebsiteCardImage/>
      <Affiliations/>
      <Footer/>
    </>
  )
}

export default WebsiteSolutionPage