import React from 'react'

const RadiologyList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Radiologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiologic Technologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Diagnostic Medical Sonographer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Magnetic Resonance Imaging (MRI) Technologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Computed Tomography (CT) Technologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Nuclear Medicine Technologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiation Therapist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Nurse</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Interventional Radiologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Mammographer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cardiovascular Technologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Fluoroscopy Technologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Picture Archiving and Communication System (PACS) Administrator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Information System (RIS) Administrator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Department Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Supervisor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Educator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Quality Assurance Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Research Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology IT Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Service Engineer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Transcriptionist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Receptionist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Scheduling Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Billing Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Coding Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Film Librarian</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Equipment Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Consultant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Administrator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Compliance Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Safety Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Assistant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Support Staff</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology PACS Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Teleradiology Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Ultrasound Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Imaging Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Film Processor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Technician&nbsp;</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Assistant&nbsp;</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Technologist/Radiographer&nbsp;</li>
                            <li><i class="fa-solid fa-circle-check"></i>Radiology Nurse&nbsp;</li>
                            <li><i class="fa-solid fa-circle-check"></i>Ultrasound Technician / Diagnostic Medical Sonographer&nbsp;</li>
                            <li><i class="fa-solid fa-circle-check"></i>MRI Technician&nbsp;</li>
                            <li><i class="fa-solid fa-circle-check"></i>CT Tech / CAT Scan Technologist / CT Scan Technologist&nbsp;</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RadiologyList