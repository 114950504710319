import React from 'react'

const HouseholdList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Living status</li>
                            <li><i class="fa-solid fa-circle-check"></i>Size of household</li>
                            <li><i class="fa-solid fa-circle-check"></i>Home Type</li>
                            <li><i class="fa-solid fa-circle-check"></i>No. of Residential &amp; Commercial Properties</li>
                            <li><i class="fa-solid fa-circle-check"></i>Solar Panel User</li>
                            <li><i class="fa-solid fa-circle-check"></i>Luxury Amentites </li>
                            <li><i class="fa-solid fa-circle-check"></i>Home Improvement Projects Planning</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HouseholdList