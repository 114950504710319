import React from 'react'

const KeyBenifit = () => {
    return (
        <>
            <div className="section-padding" id='keybenefit'>
                <div className="container">
                    <div className="row">
                        <div class="col-lg-5">
                            <div class="section-heading light">
                                <h6>Key Benefits</h6>
                                <h2 class="title">
                                    quick & accurate secondary research</h2>
                                <p>
                                </p><p>Our Quick &amp; Accurate Secondary Research service provides clients with comprehensive and reliable information on a variety of topics. Our research is conducted using reputable sources and advanced search techniques to ensure accuracy and completeness.</p>
                                <p></p>
                            </div>
                        </div>

                        <div class="col-lg-7">
                            <ul>
                                <li>
                                    Enough background information to make primary research stronger              </li>
                                <li>
                                    Quick turnaround with focused research for various industries              </li>
                                <li>
                                    Company profiling for sales intelligence              </li>
                                <li>
                                    Thorough view of the external market and existing trends              </li>
                                <li>
                                    Using both publically available &amp; paid databases sources              </li>
                                <li>
                                    Evaluation of the current status of the organization              </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KeyBenifit