import React from 'react'
import Header from '../Components/Common/Header'
import InnerBanner from '../Components/Common/InnerBanner'
import Affiliations from '../Components/Common/Affiliations'
import Footer from '../Components/Common/Footer'
import EsomarAccordion from '../Components/Esomar/EsomarAccordion'

const EsomarPage = () => {
  return (
    <>
        <Header/>
        <InnerBanner title={'esomar 37'} menu={'Esomar 37'}/>
        <EsomarAccordion/>
        <Affiliations/>
        <Footer/>
    </>
  )
}

export default EsomarPage