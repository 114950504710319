import React from 'react'
import experience from "../../media/cati/cati_experience.json"
const Experience = () => {
    return (
        <>
            <div className="section-padding" id='experience'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading light text-center mb-5">
                                <h2>Our Experience</h2>
                                <p>We have more than 4 years of experience on managing and producing CATI, online( B2C,B2B &amp; Healthcare studies).</p>
                                <p>The basis of our success and great growth is that we exceed the clients expectations every time, making sure the quality of studies is followed in every step of the field. We have a high criteria among our interviewers, all of them have over 5 years experience on conducting research studies; every single interviewer has also a huge responsibility on our field work. Even the most difficult projects will be done by our experienced team.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <p className='text-center follow'>Following is a summary of our recent accomplishments:</p>
                        {experience.Experience.data.map((item, index) => (
                            <div className="col-md-6 g-5">
                                <div className="ex-item">
                                    <p><span>{index + 1}. </span>{item}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Experience