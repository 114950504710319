import React from 'react'

const HealthList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Illnesses/Conditions</li>
                            <li><i class="fa-solid fa-circle-check"></i>Type of Allergies Suffering</li>
                            <li><i class="fa-solid fa-circle-check"></i>Surgery / Procedures</li>
                            <li><i class="fa-solid fa-circle-check"></i>Healthcare Providers Visit</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hearing Aid User</li>
                            <li><i class="fa-solid fa-circle-check"></i>Glasses Or Contact Lenses Usage</li>
                            <li><i class="fa-solid fa-circle-check"></i>Frequency of exercise</li>
                            <li><i class="fa-solid fa-circle-check"></i>Location of exercise</li>
                            <li><i class="fa-solid fa-circle-check"></i>Gym membership</li>
                            <li><i class="fa-solid fa-circle-check"></i>Reasons for dieting</li>
                            <li><i class="fa-solid fa-circle-check"></i>Doctors/healthcare professionals</li>
                            <li><i class="fa-solid fa-circle-check"></i>Frequency of vitamins, minerals and supplement usage</li>
                            <li><i class="fa-solid fa-circle-check"></i>Types of vitamins, mineral and supplements</li>
                            <li><i class="fa-solid fa-circle-check"></i>Frequency of prescription drug usage</li>
                            <li><i class="fa-solid fa-circle-check"></i>Current health condition</li>
                            <li><i class="fa-solid fa-circle-check"></i>Involvement in insurance selection</li>
                            <li><i class="fa-solid fa-circle-check"></i>Types of Health insurance</li>
                            <li><i class="fa-solid fa-circle-check"></i>Health Insurance companies</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HealthList