import React from 'react'
import CounterCard from './CounterCard'

const Counter = () => {
  return (
    <>
        <div className="" id='counter'>
            <div className="container">
                <div className="row">
                    <CounterCard/>
                </div>
            </div>
        </div>
    </>
  )
}

export default Counter