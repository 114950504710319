import React from "react";

const MissionVision = () => {
  const MissionVision = [
    {
      "image": "assets/img/mission.jpg",
      "heading": "Our Mission",
      "para": "Our Mission is to be your most reliable business partner worldwide and to increase value through high-quality innovative & advanced research solutions continuously."
    },
    {
      "image": "assets/img/vision.jpg",
      "heading": "Our Vision",
      "para": "To expand our reach globally as a leading global data collection research firm by nurturing and maintaining the quality and to bringing unique technologies and methodologies to our clients, which allow them to collect and interpret feedback more quickly, accurately, and affordably."
    }
  ]
  return (
    <>
      <div className="section-padding" id="mission-vision">
        <div className="container">
          <div className="row">
            <div className="col-md-12 g-5" data-aos="fade-up" data-aos-duration="800">
              <div className="row">
                <div className="col-lg-12">
                  <p>
                    Frequent Research Fieldwork Solutions Private Limited is an
                    ISO 9001:2015, 27001: 2015, 20252:2019 Certified Full
                    service market research consulting & I.T solutions Company
                    based in India.
                  </p>

                  <p>
                    We are specialize in market research solutions, Software
                    Development, Website design, Mobile Application Development,
                    Digital marketing services, Human Resource/Payroll ,
                    Accounts, Financing & Tax Solutions.
                  </p>

                  <p>
                    Founded in 2017, Frequent Research was established with a
                    vision of helping businesses worldwide to take right
                    decision for their ROI growth as well as to create and
                    develop powerful digital experiences by innovative approach
                    for transforming the way businesses use technology. We
                    strive to help clients reach their goals and provide
                    services tailored to their unique needs – always on time,
                    every time!
                  </p>

                  <p>
                    We provide tailored solutions to our clients for their
                    business success. Frequent Research experience researcher &
                    expertise helps you to make the right decision for your
                    business. Our highly experienced consultants and
                    foresightedness always thrive to make you reach your
                    business objectives with right decisions.
                  </p>

                  <p>
                    We are the best partner for your digital journey with a huge
                    team of experienced, talented, and workaholic techies. We
                    help you in building your own dedicated team of experts.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="about-page-section" data-aos="fade-up" data-aos-duration="800">
                <div className="row">
                  {MissionVision.map((item, i) => (
                    <div className="col-lg-6 g-5" key={i}>
                      <div className="box">
                        <div className="image-wrapper">
                          <img
                            src={item.image}
                            alt={item.heading}
                          />
                        </div>
                        <div className="content-wrapper">
                          <h6>{item.heading}</h6>
                          <p>
                       {item.para}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* Mission */}
                  {/* <div className="col-lg-4 g-5">
                    <div className="img-wrapper mb-4">
                      <img src="assets/img/mission.jpg" alt="" />
                    </div>

                    <div className="content-box">
                      <div className="icon mb-4">
                        <img src="assets/img/bow-and-arrow.png" alt="" />
                      </div>
                      <h6>our mission</h6>
                      <p>
                        Our Mission is to be your most reliable business
                        partner worldwide and to increase value through
                        high-quality innovative & advanced research solutions
                        continuously.
                      </p>
                    </div>
                  </div> */}

                  {/* Core Value */}
                  {/* <div className="col-lg-4 g-5">
                    <div className="content-box">
                      <div className="icon mb-4">
                      <img src="assets/img/growth.png" alt="" />
                      </div>
                      <h6>our core values</h6>
                      <p>
                      Equal blame belongs to those who fail in their duty through weakness of will which the same as saying.
                      </p>
                      <ul>
                        <li>Integrity</li>
                        <li>Commitment to Excellence</li>
                        <li>Consumer Focus</li>
                        <li>Accountability</li>
                        <li>Inclusiveness</li>
                      </ul>
                    </div>
                  </div> */}

                  {/* Vision */}
                  {/* <div className="col-lg-4 g-5">

                    <div className="content-box mb-4 h">
                      <div className="icon mb-4">
                      <img src="assets/img/binocular.png" alt="" />
                      </div>
                      <h6>our vision</h6>
                      <p>
                      To expand our reach globally as a leading global data collection research firm by nurturing and maintaining the quality and to bringing unique technologies and methodologies to our clients, which allow them to collect and interpret feedback more quickly, accurately, and affordably.
                      </p>
                    </div>
                    <div className="img-wrapper">
                      <img src="assets/img/vision.jpg" alt="" />
                    </div>

                  </div> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionVision;
