import React from 'react'
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';

const AccordionItem = ({ header, ...rest }) => (
    <Item
        {...rest}
        header={
            <>
                {header}
                <div className='chevron-icon'>
                    <i class="fa-solid fa-angle-right chevron"></i>
                </div>
            </>
        }
        className={"item"}
        buttonProps={{
            className: ({ isEnter }) =>
                `${"itemBtn"} ${isEnter && "itemBtnExpanded"}`,
        }}
        contentProps={{ className: "itemContent" }}
        panelProps={{ className: "itemPanel" }}
    />
);

const QualitativeSolution = () => {
    return (
        <>
            <div className='' id='quantitative-bg'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 quantitative-heading" data-aos="fade-up" data-aos-duration="800">
                            <h3>Qualitative Solution</h3>

                            <p>Qualitative research is a very powerful tool in exhaustively examining an issue to its core, and
                                uncovering underlying perceptions and rationalizations that manifest in different attitudes and
                                behaviours. Frequent Research conducts both focus groups and in-depth interviews to this effect.</p>
                            <h5>We support clients with all common Qualitative Research
                                Methods:</h5>
                        </div>
                    </div>

                    <div className={"styles.accordion"}>
                        <Accordion transition transitionTimeout={350}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <AccordionItem header="Focus Group Discussion">
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="content" id="dp">
                                                        <h6>
                                                            Focus Group Discussion
                                                        </h6>
                                                        <p>Focus groups are conducted at Frequent Research’s specially designed facilities under
                                                            the direction of a skill full and experienced moderator.</p>
                                                        <p>For a more comprehensive experience, representatives of the client are invited to
                                                            watch the focus groups, as they are carried out, through a closed circuit TV system
                                                            and a one-way mirror, and witness first-hand the group dynamics as the discussion
                                                            unfolds. Arrangements can be made to provide our customers with simultaneous
                                                            translation of the sessions to a different language.</p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-5">
                                                    <div className="image-wrapper">
                                                        <img src="assets/img/group-discuss.jpg" alt="cawi" />
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="content">
                                                        <p>Group interviews conducted by a trained facilitator in a specially prepared studio.
                                                            The discussion usually takes 6-8 people involved, selected by a special scheme of
                                                            recruitment. Studio focus usually is equipped with one-way mirror, which allows
                                                            direct observation of the meeting by researchers and customers. The discussion is
                                                            recorded with a video camera, so that you can then analyze all the comments and
                                                            reactions of the participants.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionItem>

                                    <AccordionItem header="In-Depth Interviews">
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="content" id="dp">
                                                        <h6>
                                                            In-Depth Interviews
                                                        </h6>
                                                        <p>Frequent Research also offers one to one in-depth interviews. These are conducted by
                                                            senior research executives of the company, who possess the knowledge and versatility
                                                            to extract all essential and valuable information from a respondent. In-depth
                                                            interviews can be extremely useful in product concept development as well as
                                                            determining habits and attitudes of specific or hard to find target groups.</p>
                                                        <p>Individual in-depth interviews conducted personally by the most trained interviewer
                                                            or moderator. Often used in studies of experts are to gather in-depth knowledge in
                                                            the area of interest to us. Often used as an exploratory study before designing the
                                                            questionnaire used in the quantitative or quantitative survey as a technique that
                                                            helps understand the results of such a study.</p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-5">
                                                    <div className="image-wrapper">
                                                        <img src="assets/img/in-depth.jpg" alt="cawi" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionItem>
                                </div>
                            </div>
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QualitativeSolution