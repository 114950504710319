import React from 'react'
import infrastructure from "../../media/cati/cati_infrastructure.json"
const Infrastructure = () => {
    console.log("infra", infrastructure);
    return (
        <>
            <div className="section-padding" id='cati-infra'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading text-center">
                                <h2>Infrastructure & process</h2>
                            </div>
                        </div>

                        <div className="col-md-12 g-5">
                            <div className="content-wrapper">
                                <ul>
                                    {infrastructure.Infrastructure.Components.map((item) => (
                                        <li>{item}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Infrastructure