import React from 'react'

const EthnicityList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>White/Caucian</li>
                            <li><i class="fa-solid fa-circle-check"></i>Black, or African American</li>
                            <li><i class="fa-solid fa-circle-check"></i>American Indian or Alaska Native</li>
                            <li><i class="fa-solid fa-circle-check"></i>Asian</li>
                            <li><i class="fa-solid fa-circle-check"></i>Pacific Islander</li>
                            <li><i class="fa-solid fa-circle-check"></i>Hispanic</li>
                            <li><i class="fa-solid fa-circle-check"></i>Other race</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EthnicityList