import React from 'react'

const ParentingList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Parenting Status</li>
                            <li><i class="fa-solid fa-circle-check"></i>Children in the household</li>
                            <li><i class="fa-solid fa-circle-check"></i>Children‘s year of birth</li>
                            <li><i class="fa-solid fa-circle-check"></i>Children‘s gender</li>
                            <li><i class="fa-solid fa-circle-check"></i>Baby Expecting</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ParentingList