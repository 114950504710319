import React from 'react'
import capabilities from "../../media/cati/cati_capabilities.json"
const CatiCapabilities = () => {
    return (
        <>
            <div className="section-padding" id='cati-capability'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading light text-center">
                                <h2>cati capabilities</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {capabilities.Capabilities.Components.map((item) => (
                            <div className="col-md-6 g-5">
                                <div className="capability-content">
                                    <p>{item}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CatiCapabilities