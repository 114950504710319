import React from 'react'
import Image from '../Common/Image'
import qualitative from "../../media/cati/cati_qualitative.json"
const OurQualitative = () => {
    return (
        <>
            <div className="section-padding" id='qualitative'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-heading text-center mb-5">
                                <h2>our qualitative</h2>
                                <p>We are a full service market research company and provide largest global respondent databases across 60+ markets and provides the highest quality of research recruiting with our in-house recruitment department! We will connect you with the perfect respondents. We offer one stop solution for all your qualitative needs with our highly experienced research team for Global Recruiting. Incentive Management. Moderation, Translation, Transcription. Our Qualitative research includes, Positioning and Communication Checks, Trend Research, Packaging Research, Customer Segmentation, Blue Sky Explorations, Trigger &amp; Barrier Studies, Brand Audits &amp; User and Attitude Research</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {qualitative.Qualitative.data.map((item) => (
                            <div className="col-sm-6 col-lg-3 g-5">
                                <div className="box">
                                    <Image link={item.img} folder="cati" className="img-fluid" />
                                    <h6>{item.text}</h6>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurQualitative