import React from 'react';
import ConsultancyContent from '../../media/ConsultancyContent.json'
import apiCall from '../../modules/apiCall'

const DescriptiveAnalytic = () => {
    const [data, setData] = React.useState([])
    React.useEffect(() => {
        apiCall("/comp/ConsultancyContent")
            .then((res) => console.log(res))
            .catch((err) => console.log(err))
    }, [])
    return (
        <>
            <div className="section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="entry-content">
                                <p>Data analytics solutions include the exploration and visualization of data to identify patterns, trends, and relationships. Data visualization tools and techniques are used to create interactive dashboards, charts, and graphs that enable users to understand complex data and extract meaningful insights quickly.</p>

                                <p>Our Data exploration and visualization services encompass a variety of techniques and tools to help businesses gain insights and derive meaning from their data. Our services focus on visually representing data in a way that is intuitive, interactive, and informative.</p>
                            </div>
                        </div>
                    </div>
                    <div className="research-consultancy-list">
                        <div className="row">
                            {ConsultancyContent.descriptiveanalytics.map((item, i) => (
                                <div className="col-lg-6 g-5">
                                    <div className="concultancy-item-box" data-aos="fade-up" data-aos-duration="800">
                                        <div className="image-box color-dark image-box-style2">
                                            <div className="caption">
                                                <div className="thumb-service">
                                                    <div className="thumb" style={{
                                                        backgroundImage: `${item.bgimg}`
                                                    }}></div>
                                                </div>
                                                <div className="title-wrap">
                                                    <h3>{item.title}</h3>
                                                    <div className="content">
                                                        <p>{item.content}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DescriptiveAnalytic