import React from 'react'
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import CountryList from './CountryList';
import GenderList from './GenderList';
import AgeList from './AgeList';
import IndustryList from './IndustryList';
import JobList from './JobList';
import OrganizationList from './OrganizationList';
import EmployeeList from './EmployeeList';
import CompanyLocationList from './CompanyLocationList';
import RevenueList from './RevenueList';
import SectorList from './SectorList';
import DepartmentList from './DepartmentList';

const AccordionItem = ({ header, ...rest }) => (
    <Item
        {...rest}
        header={
            <>
                {header}
                <div className='chevron-icon'>
                    <i className="fa-solid fa-angle-right chevron"></i>
                </div>
            </>
        }
        className={"item"}
        buttonProps={{
            className: ({ isEnter }) =>
                `${"itemBtn key"} ${isEnter && "itemBtnExpanded"}`,
        }}
        contentProps={{ className: "itemContent" }}
        panelProps={{ className: "itemPanel" }}
    />
);
const KeyAttributesAccordionB2B = () => {
    return (
        <>
            <div className="section-padding" id='keyAttribute'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading text-center" data-aos="fade-up" data-aos-duration="800">
                                <h2>Key <span>Attributes</span></h2>
                            </div>
                        </div>
                    </div>

                    <div className={"styles.accordion"}>
                        <Accordion transition transitionTimeout={350}>
                            <div className={"row"}>
                                <div className={"col-12"}>

                                    <AccordionItem header="gender" >
                                        <GenderList />
                                    </AccordionItem>

                                    <AccordionItem header="age" >
                                        <AgeList />
                                    </AccordionItem>

                                    <AccordionItem header="industry" >
                                        <IndustryList />
                                    </AccordionItem>

                                    <AccordionItem header="company location">
                                        <CompanyLocationList />
                                    </AccordionItem>


                                    <AccordionItem header="job roles" >
                                        <JobList />
                                    </AccordionItem>

                                    <AccordionItem header="organization type" >
                                        <OrganizationList />
                                    </AccordionItem>

                                    <AccordionItem header="employee size">
                                        <EmployeeList />
                                    </AccordionItem>

                                    <AccordionItem header="company revenue">
                                        <RevenueList />
                                    </AccordionItem>

                                    <AccordionItem header="department" >
                                        <DepartmentList />
                                    </AccordionItem>


                                    <AccordionItem header="country" >
                                        <CountryList />
                                    </AccordionItem>

                                    <AccordionItem header="sector" >
                                        <SectorList />
                                    </AccordionItem>
                                </div>
                            </div>
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KeyAttributesAccordionB2B