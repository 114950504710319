import React from "react";

const EntryContent = () => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="entry-content" data-aos="fade-up" data-aos-duration="800">
            <div className="entry-section-head text-center text-capitalize">
              <h2>Business 2 Business Panel</h2>
            </div>
            <div className="content mt-5">
              <p>
                Frequent Research has a wide experience to recruit the Business
                profiles we consists of participants who are professionals
                working in various industries and businesses. These panelists
                are typically decision-makers, influencers, or stakeholders
                within their organizations. They are sought after for their
                expertise, insights, and opinions on business-related topics. We
                engage our professional in market research studies, surveys, and
                other research activities to gather valuable feedback and data.
              </p>
              <p>
                To profile and recruit participants for a B2B panel, Frequent
                research has its own proprietary online recruitment platform
                named as www.turn2opinion.com as well as myfrequentrewards.com
                by employing several strategies.
              </p>
              <p>
                Currently we have 2.85M+ Business professionals registered on
                our portals we enforce targeted recruitment methods to identify
                and invite professionals from specific industries, job roles, or
                organizations that align with the research objectives. We
                leverage our existing panel database or employ targeted
                advertising, email campaigns, and social media outreach to
                attract our B2B panelists.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EntryContent;
