import React from 'react'
import { Link } from 'react-router-dom'

const InnerBanner = ({title, menu}) => {
    return (
        <>
            <div className="inner-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <div className="content-wrapper text-center">
                                <h3>{title}</h3>
                                <div className="bredcrumb mt-5">
                                    <ul className="d-flex justify-content-center align-items-center">
                                        <li>
                                            <Link to='/'>Home</Link>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-arrow-right"></i>
                                        </li>
                                        <li>{menu}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InnerBanner