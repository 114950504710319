import React from 'react'

const Affiliations = () => {
    return (
        <>
            <div className="affiliation-area">
                <div className="container">
                    <div className="row justify-content-center justify-content-lg-between align-items-center">
                        <div className="col-lg-3 d-flex justify-content-center justify-content-lg-start">
                            <h2>our affiliations...</h2>
                        </div>

                        <div className="col-md-10 col-lg-6 mt-5 mt-lg-0">
                            <div className="affiliation-img d-flex justify-content-between">
                                <img src="assets/img/iso27001.png" alt="" />
                                <img src="assets/img/iso20252.jpg" alt="" />
                                <img src="assets/img/ISO.png" alt="" />
                                <img src="assets/img/esomar.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Affiliations