import React from 'react'

const CatiIntroduction = () => {
    return (
        <>
            <div className="section-padding" id='cati-intro'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading text-center">
                                <h2>CATI Introduction</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-7 g-5">
                            <div className="content-wrapper">
                            <p>We deliver the need of your data smoothly, on time, at a very competitive cost. We provide our clients as single full fledge source for multinational data collection online, by telephone, IDI, FGD, Mystery Shopping and/or face-to-face interviews. The entire process is managed in-house, with global reach. Our language experts are multilingual with native fluency that to meet your cultural nuance with the strict quality control process.</p>
                            <p>We have over 25 CATI stations, in housed . We have more than 15 multi language expertise base to assist you even the most complex and lengthy interviews with ease. Our team work across global time zones with 24/7 availability. We ensure the resulting data with highest quality just because of our world-class expertise in action to bring high-performance insights at your fingertips. Our automated predictive dialler centre interviews customer by leveraging Auto Dialer. Our CATI services are fully comprehensive, covering all your needs for questionnaire design, design functions, automatic call scheduling, interviewer training, and enterprise reporting.</p>
                            </div>
                        </div>

                        <div className="col-md-5 g-5">
                            <div className="image-wrapper">
                                <img src="/assets/img/cati/about1.webp"  alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CatiIntroduction