import React from 'react'
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import CountryList from '../B2B/CountryList';
import GenderList from '../B2B/GenderList';
import AgeList from '../B2B/AgeList';
import MaritalList from '../B2C/AccordionItem/MaritalList';
import LanguageList from '../B2C/AccordionItem/LanguageList';
import EthnicityList from '../B2C/AccordionItem/EthnicityList';
import AilmentList from './AccordionItem/AilmentList';
import HospitalBedList from './AccordionItem/HospitalBedList';
import HospitalList from './AccordionItem/HospitalList';
import EducationList from './AccordionItem/EducationList';
import DepartmentList from './AccordionItem/DepartmentList';
import MarketList from './AccordionItem/MarketList';
import JobRoleAccordion from './JobRoleAccordion';


const AccordionItem = ({ header, ...rest }) => (
    <Item
        {...rest}
        header={
            <>
                {header}
                <div className='chevron-icon'>
                    <i class="fa-solid fa-angle-right chevron"></i>
                </div>
            </>
        }
        className={"item"}
        buttonProps={{
            className: ({ isEnter }) =>
                `${"itemBtn key"} ${isEnter && "itemBtnExpanded"}`,
        }}
        contentProps={{ className: "itemContent" }}
        panelProps={{ className: "itemPanel" }}
    />
);
const HealthcareAccordion = () => {
    return (
        <>
            <div className="section-padding" id='keyAttribute'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading text-center">
                                <h2>Key <span>Attributes</span></h2>
                            </div>
                        </div>
                    </div>

                    <div className={"styles.accordion"}>
                        <Accordion transition transitionTimeout={350}>
                            <div className={"row"}>
                                <div className={"col-12"}>

                                    <AccordionItem header="gender">
                                        <GenderList/>
                                    </AccordionItem>

                                    <AccordionItem header="age">
                                        <AgeList/>
                                    </AccordionItem>

                                    <AccordionItem header="Marital Status">
                                        <MaritalList/>
                                    </AccordionItem>

                                    <AccordionItem header="Primary Language">
                                        <LanguageList/>
                                    </AccordionItem>

                                    <AccordionItem header="ethnicity">
                                        <EthnicityList/>
                                    </AccordionItem>

                                    <AccordionItem header="hospital bed type">
                                        <HospitalBedList/>
                                    </AccordionItem>

                                    <AccordionItem header="hospital type">
                                        <HospitalList/>
                                    </AccordionItem>

                                    <AccordionItem header="education">
                                        <EducationList/>
                                    </AccordionItem>

                                    <AccordionItem header="department">
                                        <DepartmentList/>
                                    </AccordionItem>

                                    <AccordionItem header="market research">
                                        <MarketList/>
                                    </AccordionItem>

                                    <AccordionItem header="job role">
                                        <JobRoleAccordion/>
                                    </AccordionItem>

                                    <AccordionItem header="country">
                                        <CountryList/>
                                    </AccordionItem>

                                    <AccordionItem header="ailments">
                                        <AilmentList/>
                                    </AccordionItem>
                                </div>
                            </div>
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HealthcareAccordion