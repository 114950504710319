import React from 'react'

const InternetList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Number of years active online</li>
                            <li><i class="fa-solid fa-circle-check"></i>Number of hours spent onlineat home and at work</li>
                            <li><i class="fa-solid fa-circle-check"></i>Internet Service Providers (ISP)</li>
                            <li><i class="fa-solid fa-circle-check"></i>ISP company</li>
                            <li><i class="fa-solid fa-circle-check"></i>Brand of computer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Online activities</li>
                            <li><i class="fa-solid fa-circle-check"></i>Use of Instant Messenger</li>
                            <li><i class="fa-solid fa-circle-check"></i>Other messenger applications</li>
                            <li><i class="fa-solid fa-circle-check"></i>Internet Browser</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InternetList