import React from 'react'
import FrequentSlider from '../Common/Slider'
import Image from '../Common/Image'
import database from "../../media/cati/cati_databases.json"
const CompanySlider = () => {
    return (
        <>
            <div className="section-padding" id='company'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading text-center">
                                <h2>Experience of working across various premium/ industry specific databases</h2>
                            </div>
                            <FrequentSlider responsive="true" slides="4" >
                                    {database.databases.data.map((item) => (
                                        <div className='company-item'>
                                            <Image link={item} folder="cati" />
                                        </div>
                                    ))}
                                </FrequentSlider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanySlider