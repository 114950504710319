import React from 'react'
import Header from '../../Components/Common/Header'
import InnerBanner from '../../Components/Common/InnerBanner'
import Affiliations from '../../Components/Common/Affiliations'
import Footer from '../../Components/Common/Footer'
import DescriptiveAnalytic from '../../Components/AnalyticSolution/DescriptiveAnalytic'

const DescriptiveAnalyticPage = () => {
    return (
        <>
            <Header />
            <InnerBanner title={'descriptive analytics'} menu={'Descriptive Analytics'} />
            <DescriptiveAnalytic />
            <Affiliations />
            <Footer />
        </>
    )
}

export default DescriptiveAnalyticPage