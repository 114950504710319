import React from 'react'
import CountUp from "react-countup";


const counterdata = [
    {
        "duration": 30,
        "end": 7841,
        "title": "projects completed",
        "icon": "fa-solid fa-list-check"
    }, {
        "duration": 30,
        "end": 2252782,
        "title": "consumer panelist",
        "icon": "fa-solid fa-people-group"

    }, {
        "duration": 30,
        "end": 916279,
        "title": "business panellist",
        "icon": "fa-solid fa-user-tie"

    }, {
        "duration": 30,
        "end": 145015,
        "title": "healthcare panellist",
        "icon": "fa-solid fa-user-doctor"

    }
]



const CounterCard = () => {
    return (
        <>
            {counterdata.map((item) => (
                <div className="col-sm-6 col-md-6 col-lg-3 g-5">
                    <div class="counter"  data-aos="fade-up" data-aos-duration="800">
                        <div class="counter-icon">
                            <i class={item.icon}></i>
                        </div>
                        <CountUp duration={item.duration} className={"counter-value"} end={item.end} />
                        <h3>{item.title}</h3>
                    </div>
                </div>
            ))}

        </>
    )
}

export default CounterCard