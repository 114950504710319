import React from "react";
import Rp from '../../media/RecruimentProcess.json'

const RecruimentProcess = () => {
  return (
    <>
      <div className="section-padding" id="rp">
        <div className="container">
          <div className="row">
            <div
              className="section-heading text-center"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <h2>
                recruiment<span> process</span>
              </h2>
            </div>
            {Rp.Recruiment.map((item, id) => (
              <div className="col-md-6 col-lg-4 g-5" key={id}>
                <div className="single-service-box-item" data-aos="fade-up" data-aos-duration="800">
                  <div className="number">{item.num}</div>
                  <div className="icon me-3">
                   <i className={item.icon}></i>
                  </div>
                  <div className="title">
                    <h3>{item.h3}</h3>
                  </div>
                  <div className="content mt-3">
                    <p>
                    {item.para}
                    </p>
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </>
  );
};

export default RecruimentProcess;
