import React from 'react'
import heroSlideText from '../../media/cati/cati_banner.json'
import FrequentSlider from '../Common/Slider'


const HeroSlider = () => {
    return (
        <>
            <div className="banner-slider">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-6 g-0">
                            <div className="video-overlay">
                                <div className="banner-video1">
                                    <video preload="auto" loop autoPlay muted>
                                        <source src="assets/img/cati/video1.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 g-0">
                            <div className="banner-video2">
                                <video preload="auto" loop autoPlay muted>
                                    <source src="assets/img/cati/video2.mp4" type="video/mp4" />
                                </video>
                            </div>
                        </div>

                        {/* <!------------------ BANNER TEXT SLIDER ------------------> */}

                        <div className="col-12 col-lg-8 banner-text">
                            <FrequentSlider >
                                {heroSlideText.HeroSlideText.map((item) => (
                                    <div>
                                        <h2>{item}</h2>
                                    </div>
                                ))}
                            </FrequentSlider>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default HeroSlider