import React from 'react'

const BusinessList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i className="fa-solid fa-circle-check"></i>Employment status</li>
                            <li><i className="fa-solid fa-circle-check"></i>Industry</li>
                            <li><i className="fa-solid fa-circle-check"></i>Current occupation</li>
                            <li><i className="fa-solid fa-circle-check"></i>Job title</li>
                            <li><i className="fa-solid fa-circle-check"></i>Current position</li>
                            <li><i className="fa-solid fa-circle-check"></i>Business category</li>
                            <li><i className="fa-solid fa-circle-check"></i>Annual revenue</li>
                            <li><i className="fa-solid fa-circle-check"></i>Number of employees</li>
                            <li><i className="fa-solid fa-circle-check"></i>Number of years in business</li>
                            <li><i className="fa-solid fa-circle-check"></i>Direct and indirect reports</li>
                            <li><i className="fa-solid fa-circle-check"></i>Role in the purchase of business products and services</li>
                            <li><i className="fa-solid fa-circle-check"></i>Frequency of Internet use in research or purchase</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessList