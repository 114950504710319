import React from 'react'

const MedicalDepartmentList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Officer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Administrator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Superintendent</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Coordinator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Advisor</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Consultant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Practitioner</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Researcher</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Educator</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Writer</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Transcriptionist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Billing Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Coder</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Records Technician</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Receptionist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Secretary</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Administrative</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Assistant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Assistant or Receptionist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Associate</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Claims and Billing Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Office Assistant</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Office Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Office Specialist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical or Health Services Manager</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Records Clerk</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Records Director</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Sales</li>
                            <li><i class="fa-solid fa-circle-check"></i>Medical Technologist</li>
                            <li><i class="fa-solid fa-circle-check"></i>Mobile Director of Nursing Services</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MedicalDepartmentList