import React from 'react'
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import { Link } from 'react-router-dom';

const AccordionItem = ({ header, ...rest }) => (
    <Item
        {...rest}
        header={
            <>
                {header}
                <div className='chevron-icon'>
                    <i class="fa-solid fa-angle-right chevron"></i>
                </div>
            </>
        }
        className={"item"}
        buttonProps={{
            className: ({ isEnter }) =>
                `${"itemBtn"} ${isEnter && "itemBtnExpanded"}`,
        }}
        contentProps={{ className: "itemContent" }}
        panelProps={{ className: "itemPanel" }}
    />
);

const QuantitativeSolution = () => {
    return (
        <>
            <div className='section-padding' id='quantitative-bg'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 quantitative-heading" data-aos="fade-up" data-aos-duration="800">
                            <h3>Quantitative Solution</h3>
                            <p>
                                We are one of the preferred service providers of wide range of
                                Quantitative Research to our respected clients. We conduct a
                                range of quantitative research services, through which an
                                informed decision can be taken and we could obtain reliable
                                statistical results. On the basis of your research objective
                                &amp; utilizing our expertise, we could help you with the most
                                suitable approach.
                            </p>

                            <h5>We support clients with all common Quantitative Research
                                Methods:</h5>
                        </div>
                    </div>

                    <div className={"styles.accordion"}>
                        <Accordion transition transitionTimeout={350}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                <AccordionItem header="Data Collection Offline">
                                        <div className="accordion-body">
                                            <div className="res-navbar d-flex justify-content-center">
                                                <ul className="nav nav-tabs res-menu d-flex justify-content-between">
                                                    <li className="nav-item filter-menu">
                                                        <a
                                                            className="nav-link active"
                                                            data-bs-toggle="tab"
                                                            href="#f2f"
                                                        >
                                                            FACE TO FACE
                                                        </a>
                                                    </li>
                                                    <li className="nav-item filter-menu">
                                                        <a
                                                            className="nav-link"
                                                            data-bs-toggle="tab"
                                                            href="#capi"
                                                        >
                                                            CAPI
                                                        </a>
                                                    </li>
                                                    <li className="nav-item filter-menu">
                                                        <a
                                                            className="nav-link"
                                                            data-bs-toggle="tab"
                                                            href="#papi"
                                                        >
                                                            PAPI
                                                        </a>
                                                    </li>

                                                    <li className="nav-item filter-menu">
                                                        <a
                                                            className="nav-link"
                                                            data-bs-toggle="tab"
                                                            href="#papi-cap"
                                                        >
                                                            PAPI CAPABILITIES
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>

                                            {/* F2F */}
                                            <div className="tab-content">
                                                <div className="tab-pane active" id="f2f">
                                                    <div className="menu-main">
                                                        <div className="row">
                                                            <div className="col-12 g-5">
                                                                <div className="content">
                                                                    <h6>​​​FACE TO FACE</h6>
                                                                    <ul>
                                                                        <li>
                                                                            Frequent Research is equipped with fully
                                                                            trained higher end interviewers who are
                                                                            capable of acquiring data by conducting
                                                                            in-person/face to face interviews with
                                                                            all sector experts and respondents.
                                                                            Moreover, we have good field network to
                                                                            cover all major critical geographical
                                                                            locations across India and globally too.
                                                                        </li>
                                                                        <li>
                                                                            The Frequent Research team can conduct
                                                                            face-to-face surveys in multiple
                                                                            languages when necessary. Either
                                                                            respondents choose the flag
                                                                            corresponding to their preferred
                                                                            language, or we provide
                                                                            minority-language-speaking interviewers.
                                                                        </li>
                                                                        <li>
                                                                            We have an extensive arrangement for
                                                                            conducting face-to-face interviews
                                                                            across all global locations. At Frequent
                                                                            Research we take all our projects in a
                                                                            challenging way and assist our clients
                                                                            in accomplishing all their documentation
                                                                            as well as information needs.
                                                                        </li>
                                                                        <li>
                                                                            For Face to Face interviews, Frequent
                                                                            Research is partnered with experienced
                                                                            and certified field agencies in Asia and
                                                                            African countries
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* capi */}
                                            <div className="tab-content">
                                                <div className="tab-pane" id="capi">
                                                    <div className="menu-main">
                                                        <div className="row mb-5">
                                                            <div className="col-lg-7 g-5">
                                                                <div className="content">
                                                                    <h6>
                                                                        Computer Assisted Personal Interviewing
                                                                    </h6>
                                                                    <p>
                                                                        Through CAPI method, we conduct surveys
                                                                        face to face by using netbooks, tablets
                                                                        and turn them into valuable data. Our
                                                                        field services teams utilize the latest
                                                                        tablet-based CAPI software for efficient
                                                                        and effective personal interviewing with
                                                                        instant data uploads and performance
                                                                        monitoring information, allowing rapid
                                                                        turn-around of interview data results.
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-5 g-5">
                                                                <div className="image-wrapper">
                                                                    <img src="assets/img/capi.jpg" alt="capi" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* papi */}
                                            <div className="tab-content">
                                                <div className="tab-pane" id="papi">
                                                    <div className="menu-main">
                                                        <div className="row mb-5">
                                                            <div className="col-lg-7 g-5">
                                                                <div className="content">
                                                                    <h6>Pen and Paper Interviewing</h6>
                                                                    <p>
                                                                        PAPI, “pen and paper interview” is a data
                                                                        collection technique, the most
                                                                        conventional one: data is recorded on a
                                                                        paper questionnaire and subsequently
                                                                        entered in a database for further
                                                                        analysis. We have experienced interviewers
                                                                        who are able to properly lead the
                                                                        communication process and to control the
                                                                        interview environment.
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-5 g-5">
                                                                <div className="image-wrapper">
                                                                    <img src="assets/img/papi.jpg" alt="papi" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* papi capabilities */}
                                            <div className="tab-content">
                                                <div className="tab-pane" id="papi-cap">
                                                    <div className="menu-main">
                                                        <div className="row">
                                                            <div className="col-12 g-5">
                                                                <div className="content">
                                                                    <h6>OUR PAPI CAPABILITIES</h6>
                                                                    <ul>
                                                                        <li>
                                                                            Interviewing is executed using classical
                                                                            paper questionnaires.
                                                                        </li>
                                                                        <li>
                                                                            Respondents are selected according to
                                                                            client’s brief, covering the
                                                                            representative sample of the selected
                                                                            target group (whole population or
                                                                            selected segments).
                                                                        </li>
                                                                        <li>
                                                                            Experienced and well-trained
                                                                            interviewers carry out the interviews.
                                                                            Before they start to work as
                                                                            interviewers they pass several internal
                                                                            trainings proceeded in accordance with
                                                                            general interviewing standards and
                                                                            principles.
                                                                        </li>
                                                                        <li>
                                                                            The quality and promptness of data
                                                                            collection is ensured by regular
                                                                            cross-check of interviewers´ work.
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionItem>

                                    <AccordionItem header="Data Collection CATI">
                                        <div className="accordion-body">
                                            <div className="res-navbar d-flex justify-content-center">
                                                <ul className="nav nav-tabs res-menu d-flex justify-content-between">
                                                    <li className="nav-item filter-menu">
                                                        <a
                                                            className="nav-link active"
                                                            data-bs-toggle="tab"
                                                            href="#cati"
                                                        >
                                                            CATI
                                                        </a>
                                                    </li>

                                                    <li className="nav-item filter-menu">
                                                        <a
                                                            className="nav-link"
                                                            data-bs-toggle="tab"
                                                            href="#cati-cap"
                                                        >
                                                            CATI CAPABILITIES
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>

                                            {/* cati */}
                                            <div className="tab-content">
                                                <div className="tab-pane active" id="cati">
                                                    <div className="menu-main">
                                                        <div className="row mb-5">
                                                            <div className="col-lg-7 g-5">
                                                                <div className="content">
                                                                    <h6>
                                                                        Computer Assisted Telephone Interviewing
                                                                    </h6>
                                                                    <p>
                                                                        {" "}
                                                                        Frequent Research, one of the Virtual
                                                                        Assistant in India enables Computer-Aided
                                                                        Telephone Interviewing. It allows you to
                                                                        rapidly access your customers. The
                                                                        Computer-Aided Telephone Interviewing
                                                                        (CATI) software we use is integrated with
                                                                        a completely automated system. The
                                                                        rapid-dial feature is four times as
                                                                        productive as manual dialing. It will
                                                                        allow you to save much time and get more
                                                                        jobs done.
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-5 g-5">
                                                                <div className="image-wrapper">
                                                                    <img src="assets/img/cati.jpg" alt="cati" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* cati capabilities */}
                                            <div className="tab-content">
                                                <div className="tab-pane" id="cati-cap">
                                                    <div className="menu-main">
                                                        <div className="row">
                                                            <div className="col-12 g-5">
                                                                <div className="content">
                                                                    <h6>OUR CATI CAPABILITIES</h6>
                                                                    <ul>
                                                                        <li>25+Seats availability.</li>
                                                                        <li>
                                                                            100% audio recordings-ability to record
                                                                            all interviews.
                                                                        </li>
                                                                        <li>
                                                                            Own server, robust dialer, supervisor
                                                                            monitoring, remote barge-in facility.
                                                                        </li>
                                                                        <li>
                                                                            Our enterprise reporting system collects
                                                                            info real time and provides access to
                                                                            data analysis.
                                                                        </li>
                                                                        <li>
                                                                            Our process is fully translucent and
                                                                            thus you stay comfortable throughout.
                                                                        </li>
                                                                        <li>
                                                                            We offer services to almost all
                                                                            industries-healthcare, insurance, real
                                                                            estate, broadcasting advertising…
                                                                        </li>
                                                                        <li>
                                                                            We provide CATI, CAPI, and CAWI in 30
                                                                            plus different languages.
                                                                        </li>
                                                                        <li>
                                                                            Our auto dialing system assures limited
                                                                            human errors as well as more valuable
                                                                            responses with recordings
                                                                        </li>
                                                                        <li>
                                                                            We ensure absolute data security and
                                                                            confidentiality.
                                                                        </li>
                                                                        <li>
                                                                            Our CATI Centre staff are multilingual,
                                                                            and we always allocate native speakers
                                                                            to projects fielded outside of
                                                                            English-speaking countries to ensure the
                                                                            utmost accuracy in our research and
                                                                            translation.
                                                                        </li>
                                                                        <li>
                                                                            By keeping all our language and
                                                                            translation services, project management
                                                                            and quality control in-house, we are
                                                                            able to guarantee consistency and
                                                                            quality across every country involved in
                                                                            your study.
                                                                        </li>
                                                                        <li>
                                                                            We have capabilities to dial out B2B
                                                                            professionals (CXO, Directors, Senior
                                                                            and Middle Management Staff, Dealers,
                                                                            Distributors, Retailers), Healthcare
                                                                            professionals (across all stake holders.
                                                                            i.e. physicians across specialties,
                                                                            patients, caretakers, pharmacists,
                                                                            nurses, etc.), IT professionals (CIO,
                                                                            CTO, Technology leaders, IT heads, IT
                                                                            Manager) and Consumers.
                                                                        </li>
                                                                        <li>
                                                                            Frequent Research operate 24/7, meaning
                                                                            that we can connect with hard-to-reach
                                                                            individuals regardless of their time
                                                                            zone or shift pattern.
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionItem>

                                    <AccordionItem header="Data Collection Online">
                                        <div className="accordion-body">
                                            <div className="res-navbar d-flex justify-content-center">
                                                <ul className="nav nav-tabs res-menu d-flex justify-content-between">
                                                    <li className="nav-item filter-menu">
                                                        <a
                                                            className="nav-link active"
                                                            data-bs-toggle="tab"
                                                            href="#b2b"
                                                        >
                                                            Business To Business
                                                        </a>
                                                    </li>
                                                    <li className="nav-item filter-menu">
                                                        <a
                                                            className="nav-link"
                                                            data-bs-toggle="tab"
                                                            href="#b2c"
                                                        >
                                                            Business To Consumer
                                                        </a>
                                                    </li>
                                                    <li className="nav-item filter-menu">
                                                        <a
                                                            className="nav-link"
                                                            data-bs-toggle="tab"
                                                            href="#healthcare"
                                                        >
                                                            Health Care
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>

                                            {/* B2B */}
                                            <div className="tab-content">
                                                <div className="tab-pane active" id="b2b">
                                                    <div className="menu-main">
                                                        <div className="row">
                                                            <div className="col-lg-7 g-5">
                                                                <div className="content">
                                                                    <h6>Business To Business</h6>
                                                                    <p>
                                                                        {" "}
                                                                        We provide millions of business
                                                                        professionals worldwide within your reach.
                                                                    </p>
                                                                    <p>
                                                                        {" "}
                                                                        Get Opinions from Business Professionals
                                                                        in Industries Ranging From Financial
                                                                        Services to Real Estate To Consumer
                                                                    </p>
                                                                    <h6>
                                                                        More Than 2.85 Million+ B2B Panellist
                                                                        Globally
                                                                    </h6>
                                                                    <p>
                                                                        {" "}
                                                                        From small to large, from a target niche
                                                                        to a global representation, Frequent
                                                                        Research gives you access to the highest
                                                                        quality online B2B panel-lists around the
                                                                        world to gain the insights you need.
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-5 g-5">
                                                                <div className="image-wrapper">
                                                                    <img
                                                                        src="assets/img/business2business.jpg"
                                                                        alt="business2business"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-12 g-5">
                                                                <div className="content">
                                                                    <p>
                                                                        {" "}
                                                                        The proprietary Frequent panels are
                                                                        actively managed with continuous in-depth
                                                                        member profiling and rigorous quality
                                                                        control; we combine innovative and
                                                                        effective methods and techniques to
                                                                        identify, engage and retain members; and
                                                                        we offer extensive segmentation variables
                                                                        for sampling targeted audiences &#8211;
                                                                        all to deliver the best in the industry in
                                                                        terms of responsive, reliable panel-lists,
                                                                        and exceptional quality data.
                                                                    </p>

                                                                    <p>
                                                                        {" "}
                                                                        Based on the proven approach we’ve
                                                                        fine-tuned our online proprietary panels
                                                                        rely on deep profiling to carefully build
                                                                        and regularly update each panel based on
                                                                        the very specific demographics of your
                                                                        target audience. This ensures you have
                                                                        access to survey-ready panels that have
                                                                        been assembled for the sole purpose of
                                                                        providing you with the precise information
                                                                        and feedback you need to improve your
                                                                        business.
                                                                    </p>
                                                                    <Link to='/business-to-business' class="btnfos btnfos-4 mt-4 mb-4"><span>Read More</span></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* B2C */}
                                            <div className="tab-content">
                                                <div className="tab-pane" id="b2c">
                                                    <div className="menu-main">
                                                        <div className="row">
                                                            <div className="col-lg-7 g-5">
                                                                <div className="content">
                                                                    <h6>Business To Consumer</h6>
                                                                    <p>
                                                                        {" "}
                                                                        More Than 2.2 Million+ Panel-list In 90+
                                                                        Countries
                                                                    </p>
                                                                    <p>
                                                                        {" "}
                                                                        <strong>
                                                                            We Provide Exceptional Sample Quality
                                                                        </strong>
                                                                    </p>
                                                                    <p>
                                                                        {" "}
                                                                        We offer access to over 2.1 million+
                                                                        double opt-in consumer panel-lists, who
                                                                        have agreed to be invited to participate
                                                                        in online surveys. Our consumer panels
                                                                        deliver a representative sample of each
                                                                        country&#8217;s population, with extensive
                                                                        geographical, socio-economic and
                                                                        product/service preference profiling. Our
                                                                        online service offers precise research
                                                                        results for custom ad-hoc projects and
                                                                        long term tracking studies with expedited
                                                                        field schedules and cost-effectiveness.
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-5 g-5">
                                                                <div className="image-wrapper">
                                                                    <img
                                                                        src="assets/img/business2consumer.jpg"
                                                                        alt="business2consumer"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-12 g-5">
                                                                <div className="content">
                                                                    <p>
                                                                        {" "}
                                                                        We bring the right people with each
                                                                        research project is driven by data
                                                                        quality. To meet the changing needs of
                                                                        clients we are continuously refreshing our
                                                                        panel with new members. We actively
                                                                        recruit for our panel using social media,
                                                                        advertising, search marketing,
                                                                        incentivized panel referrals, local
                                                                        community groups, telephone and other
                                                                        snowballing methods.
                                                                    </p>
                                                                    <p>
                                                                        {" "}
                                                                        However, our most successful method of
                                                                        attracting new members is through our
                                                                        current panel membership. We actively
                                                                        reward members for introducing new people
                                                                        and manage a dedicated program to this
                                                                        effect. We also incentivize with cash, not
                                                                        just points. Over 800+ new consumers and
                                                                        businesses register with us each month.
                                                                    </p>
                                                                    <p>
                                                                        {" "}
                                                                        Our Consumer Panel&#8217;s focus is to
                                                                        provide a global community where consumers
                                                                        can openly voice their opinions and ideas,
                                                                        making sure their voices are heard all
                                                                        over the world.
                                                                    </p>
                                                                    <Link to='/business-to-consumer' class="btnfos btnfos-4 mt-4 mb-4"><span>Read More</span></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Health Care */}
                                            <div className="tab-content">
                                                <div className="tab-pane" id="healthcare">
                                                    <div className="menu-main">
                                                        <div className="row">
                                                            <div className="col-lg-7 g-5">
                                                                <div className="content">
                                                                    <h6>HEALTH CARE</h6>
                                                                    <p>
                                                                        {" "}
                                                                        145K+ Healthcare Panel-list (HCP&#8217;s,
                                                                        KOLs Patients, Caregivers, Nurses, Payers
                                                                        etc.)
                                                                    </p>
                                                                    <p>
                                                                        {" "}
                                                                        <strong>
                                                                            24,000+ Physicians Covering 10+
                                                                            Countries
                                                                        </strong>
                                                                    </p>
                                                                    <p>
                                                                        {" "}
                                                                        We are specialized in marketing research
                                                                        in the healthcare industry in India,
                                                                        Australia, Japan, Singapore, Malaysia,
                                                                        China, U.S.A, Canada, U.K, France,
                                                                        Germany, Italy &amp; Spain. We have a
                                                                        small healthcare panel which is in growing
                                                                        stage. However, having wide pool of 30+
                                                                        trusted partners, with the help of them we
                                                                        can provide extensive reach in Latin
                                                                        America, Europe, South Africa and in
                                                                        Middle East.
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-5 g-5">
                                                                <div className="image-wrapper">
                                                                    <img
                                                                        src="assets/img/healthcare.jpg"
                                                                        alt="health care"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-12 g-5">
                                                                <div className="content">
                                                                    <p>
                                                                        {" "}
                                                                        Our healthcare specialization provide
                                                                        access HCPs including Physicians, Allied
                                                                        Healthcare Professionals, Payers,
                                                                        Patients, Caregivers, Radiologists,
                                                                        Surgeons, Private Practitioners,
                                                                        Lab-Technicians, Lab-Managers, Nurses
                                                                        (i.e. Head Nurses, Wound Care Nurses,
                                                                        Surgical Theatre Nurses, Stoma Nurses,
                                                                        Diabetes Nurses, General Nurses.),
                                                                        Hospital Directors and key opinion leaders
                                                                        (KOLs) from the ASIA, US, Canada and
                                                                        Europe.
                                                                    </p>
                                                                    <p>
                                                                        {" "}
                                                                        We offer qualitative and quantitative
                                                                        insights with our Healthcare field network
                                                                        who are medically-experienced/
                                                                        knowledgeable moderators, interviewers,
                                                                        recruiters, ethnographers, analysts to
                                                                        deliver strategic recommendations to our
                                                                        clients and to achieve their business
                                                                        objectives.
                                                                    </p>
                                                                    <p>
                                                                        {" "}
                                                                        We also have highly specialized fieldwork
                                                                        consultancy and team of 250+ freelancer’s
                                                                        network dedicated to the healthcare
                                                                        industry.
                                                                    </p>
                                                                    <p>
                                                                        {" "}
                                                                        Our Panel includes variety of Healthcare
                                                                        Professionals from GP&#8217;s To
                                                                        Specialist in all fields.
                                                                    </p>
                                                                    <Link to='/healthcare' class="btnfos btnfos-4 mt-4 mb-4"><span>Read More</span></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionItem>

                                

                                    <AccordionItem header="Data Collection CAWI">
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="content">
                                                        <h6>
                                                            CAWI (Computer Assisted Web Interview)/ONLINE
                                                        </h6>
                                                        <p>
                                                            {" "}
                                                            Our panel capability and our experience enable
                                                            us to deliver hassle-free online surveys, backed
                                                            up with reliable data, statistical reporting,
                                                            valuable analysis and specialist consulting. If
                                                            you need to understand the needs of your
                                                            customers, staff or prospective clients and you
                                                            want to evaluate your products and services, we
                                                            can cater for all of your requirements reliably,
                                                            efficiently and cost-effectively.
                                                        </p>
                                                        <p>
                                                            {" "}
                                                            Through our online panels, we delivers a
                                                            valuable high-quality data to help our clients
                                                            make informed business decisions. We have a very
                                                            reliable partners with the help of them we can
                                                            reach to any hard to reach respondents.
                                                        </p>
                                                    </div>
                                                </div>

                                                <div className="col-lg-5">
                                                    <div className="image-wrapper">
                                                        <img src="assets/img/cawi.jpg" alt="cawi" />
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="content" id="cawi">
                                                        <p>
                                                            {" "}
                                                            With the help of our advanced panel recruitment
                                                            and profile categorization methods, we can offer
                                                            you b2b panel, consumer panel, and specialty
                                                            panels in following countries:
                                                        </p>
                                                        <ul>
                                                            <li>
                                                                <strong>Asia:</strong> India, China,
                                                                Indonesia, Russia, Bangladesh, Thailand,
                                                                Vietnam, Malaysia, Singapore, Sri Lanka,
                                                                Philippines, Maldives, Nepal, Hong Kong
                                                            </li>
                                                            <li>
                                                                <strong>USA:</strong> North &amp; South
                                                                America
                                                            </li>
                                                            <li>
                                                                <strong>Middle East:</strong> UAE, Saudi
                                                                Arabia, Turkey, Qatar, Bahrain, Lebanon,
                                                                Jordan, Kuwait.
                                                            </li>
                                                            <li>
                                                                <strong>Europe:</strong> France, Germany,
                                                                Italy, Spain, UK, Sweden.
                                                            </li>
                                                            <li>
                                                                <strong>North Africa:</strong> Egypt, Morocco.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionItem>

                                    <AccordionItem header="Data Processing &amp; Analysis">
                                        <div className="accordion-body">
                                            <div className="row">
                                                <div className="col-lg-7">
                                                    <div className="content" id="dp">
                                                        <h6>
                                                            Data Processing &amp; Analysis Service Includes
                                                        </h6>
                                                        <ul>
                                                            <li>
                                                                Data Preparation: Data cleaning, Data Entry,
                                                                merging, and summarization
                                                            </li>
                                                            <li>
                                                                Data Processing and Tabulation: Our analysts
                                                                are skilled in various tools like SPSS,
                                                                Quantum, and Excel. We can process the market
                                                                research data and handle tabulation for
                                                                clients.
                                                            </li>
                                                            <li>
                                                                Open End Coding/Verbatim Coding: Our
                                                                specialist processors are fully experienced in
                                                                coding responses to open-ended questions under
                                                                key headings. Verbatim comments can be
                                                                included if required to add further Data
                                                                Processing insight into the thinking behind
                                                                the responses.
                                                            </li>
                                                            <li>
                                                                Statistical Analysis: Calculation of
                                                                statistical error Market Segmentation,
                                                                Sampling techniques, Ratio estimation,
                                                                Principal Components Analysis, Cluster
                                                                Analysis, Benchmarking, Perform Multi-Variate
                                                                testing, Factor Analysis, Market Segmentation,
                                                                AE Analysis, Conjoint Analysis, Correlation
                                                                &amp; Regression etc.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className="col-lg-5">
                                                    <div className="image-wrapper">
                                                        <img src="assets/img/cawi.jpg" alt="cawi" />
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="content">
                                                        <p>
                                                            {" "}
                                                            With the help of our advanced panel recruitment
                                                            and profile categorization methods, we can offer
                                                            you b2b panel, consumer panel, and specialty
                                                            panels in following countries:
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionItem>

                                    <AccordionItem header="Verbatim Coding">
                                        <div className="accordion-body">
                                            <div className="res-navbar d-flex justify-content-center">
                                                <ul className="nav nav-tabs res-menu d-flex justify-content-between">
                                                    <li className="nav-item filter-menu">
                                                        <a
                                                            className="nav-link active"
                                                            data-bs-toggle="tab"
                                                            href="#vc"
                                                        >
                                                            Verbatim Coding
                                                        </a>
                                                    </li>

                                                    <li className="nav-item filter-menu">
                                                        <a
                                                            className="nav-link"
                                                            data-bs-toggle="tab"
                                                            href="#cp"
                                                        >
                                                            Coding Process
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>

                                            {/* VC */}
                                            <div className="tab-content">
                                                <div className="tab-pane active" id="vc">
                                                    <div className="menu-main">
                                                        <div className="row">
                                                            <div className="col-lg-7 g-5">
                                                                <div className="content">
                                                                    <h6 className="vc">Verbatim Coding</h6>
                                                                    <p>
                                                                        {" "}
                                                                        Open Ends Coding or Verbatim Coding is a
                                                                        systematic process of transforming textual
                                                                        or open-ended comments and responses to
                                                                        numeric codes for intelligent data
                                                                        processing. Data input sources could be
                                                                        from an Excel, Access, ASCII or .DAT raw
                                                                        data which are coded based on a standard
                                                                        set or universal code frames. The specific
                                                                        codes are assigned to specific questions
                                                                        to transform comments or verbatim
                                                                        responses that facilitates tabulation.
                                                                    </p>
                                                                    <p>
                                                                        {" "}
                                                                        We understand the importance of coding
                                                                        with our years of experience and expertise
                                                                        to coding Open-Ended. Our Coding teams
                                                                        work on brand coding and Message coding
                                                                        either on ascribe or manually. Manual
                                                                        coding is carried out, usually on
                                                                        &#8216;question&#8217; basis, whereby one
                                                                        coder will code one question in order to
                                                                        ensure consistency of interpretation.
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-5 g-5">
                                                                <div className="image-wrapper">
                                                                    <img
                                                                        src="assets/img/coding-charting.jpg"
                                                                        alt="coding-charting"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="content">
                                                                <p>
                                                                    {" "}
                                                                    We have the expertise and the scale to
                                                                    provide quality control in the coding
                                                                    process strongly to our clients with the
                                                                    highest quality market research Coding
                                                                    services at extremely competitive rate, fast
                                                                    and reliable service turnaround time no
                                                                    matter what survey sample size you may have.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* CP */}
                                            <div className="tab-content">
                                                <div className="tab-pane" id="cp">
                                                    <div className="menu-main">
                                                        <div className="row">
                                                            <div className="col-12 g-5">
                                                                <div className="content" id="cp">
                                                                    <h6 className="vc">Coding Process Overview:</h6>
                                                                    <ul>
                                                                        <li>
                                                                            Receive data file from you with open
                                                                            ended or verbatim responses
                                                                        </li>
                                                                        <li>
                                                                            Formulate the code frame and send back
                                                                            for client approval
                                                                        </li>
                                                                        <li>
                                                                            Punch appropriate codes for all open-
                                                                            end responses using updated code frame
                                                                        </li>
                                                                        <li>
                                                                            Resolve incomplete survey responses
                                                                        </li>
                                                                        <li>
                                                                            Perform quality check and proof reading
                                                                            on coded responses
                                                                        </li>
                                                                        <li>
                                                                            Finalize and submit completed code book
                                                                            for client approval
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionItem>

                                    <AccordionItem header="Translation & Transcription">
                                        <div className="accordion-body">
                                            <div className="res-navbar d-flex justify-content-center">
                                                <ul className="nav nav-tabs res-menu d-flex justify-content-between">
                                                    <li className="nav-item filter-menu">
                                                        <a
                                                            className="nav-link active"
                                                            data-bs-toggle="tab"
                                                            href="#profess"
                                                        >
                                                            Professionals
                                                        </a>
                                                    </li>
                                                    <li className="nav-item filter-menu">
                                                        <a
                                                            className="nav-link"
                                                            data-bs-toggle="tab"
                                                            href="#wc"
                                                        >
                                                            Why Choose
                                                        </a>
                                                    </li>
                                                    <li className="nav-item filter-menu">
                                                        <a
                                                            className="nav-link"
                                                            data-bs-toggle="tab"
                                                            href="#rl"
                                                        >
                                                            Range Of Languages
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>

                                            {/* professionals */}
                                            <div className="tab-content">
                                                <div className="tab-pane active" id="profess">
                                                    <div className="menu-main">
                                                        <div className="row">
                                                            <div className="col-12 g-5">
                                                                <div className="content" id="professional">
                                                                    <h6 className="vc">Our Professionals</h6>
                                                                    <ul>
                                                                        <li>
                                                                            Our team comprises of highly skilled
                                                                            language experts, who have excellent
                                                                            spoken and written skills in the
                                                                            languages of their specialization.
                                                                        </li>
                                                                        <li>
                                                                            Our professionals are trained in the
                                                                            domain of language translating,
                                                                            documentation and proofreading, voice
                                                                            over, software localization and the
                                                                            like. We have advanced tech-savvy
                                                                            employees, who understand the source and
                                                                            target language.
                                                                        </li>
                                                                        <li>
                                                                            Our emphasis lies on providing creative,
                                                                            accurate and clarity services to our
                                                                            clients. They will make sure that the
                                                                            final product is virtually flawless,
                                                                            grammatically, linguistically and
                                                                            culturally.
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* why choose */}
                                            <div className="tab-content">
                                                <div className="tab-pane" id="wc">
                                                    <div className="menu-main">
                                                        <div className="row">
                                                            <div className="col-md-12 g-5">
                                                                <div className="content" id="wcu">
                                                                    <h6 className="vc">Why Choose Us?</h6>
                                                                    <ul>
                                                                        <li>
                                                                            We understand the importance of
                                                                            delivering clear, accurate and creative
                                                                            service, which translates the need of
                                                                            the client.
                                                                        </li>
                                                                        <li>
                                                                            We pride ourselves in setting the bar
                                                                            high for quality, accuracy, price and
                                                                            timely delivery.
                                                                        </li>
                                                                        <li>
                                                                            A very strict selection procedure is
                                                                            followed by our recruiting team so as to
                                                                            ensure that only best brains is hired to
                                                                            handle the translations.
                                                                        </li>
                                                                    </ul>
                                                                    <p>
                                                                        Due to our strong network of native
                                                                        translators with Industry expertise and
                                                                        our internal quality process as well as
                                                                        the use of translation technology tools,
                                                                        we are able to offer top quality
                                                                        translation solution at most competitive
                                                                        rates.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* range of language */}
                                            <div className="tab-content">
                                                <div className="tab-pane" id="rl">
                                                    <div className="menu-main">
                                                        <div className="row">
                                                            <div className="col-md-12 g-5">
                                                                <div className="content" id="range">
                                                                    <h6 className="vc">Our range of languages includes:</h6>
                                                                    <ul className="row">
                                                                        <div className="col-md-6 g-0 g-lg-5">
                                                                            <li>African Language Translation</li>
                                                                            <li>American Language Translation</li>
                                                                            <li>
                                                                                Middle Eastern Language Translation
                                                                            </li>
                                                                        </div>
                                                                        <div className="col-md-6 g-0 g-lg-5">
                                                                            <li>
                                                                                Middle Eastern Language Translation
                                                                            </li>
                                                                            <li>European Language Translation</li>
                                                                            <li>Asian Language Translation</li>
                                                                        </div>
                                                                    </ul>
                                                                    <p>
                                                                        Our transcription team provides
                                                                        top-quality, accurate transcription of all
                                                                        audio and video recordings. Our dedicated
                                                                        and experienced linguists have exceptional
                                                                        word-processing
                                                                        <br />
                                                                        skills
                                                                        <br />
                                                                        and pay meticulous attention to detail.
                                                                    </p>
                                                                    <p>
                                                                        All transcriptions are carefully reviewed
                                                                        and proofread prior to the final
                                                                        submittal. We provide uncompromising
                                                                        quality, rates within your budget, highly
                                                                        accurate transcripts and timely &#038;
                                                                        convenient delivery. All transcripts
                                                                        submitted by our skilled and experienced
                                                                        transcriptionists in different languages
                                                                        are proofread and reviewed to ensure
                                                                        accuracy.
                                                                    </p>

                                                                    <p>
                                                                        We will handle all manner of transcribing,
                                                                        right from comprehensive and continuous
                                                                        transcription workflow, and dictation, and
                                                                        tracking, report delivery to even
                                                                        archiving and billing. Our transcription
                                                                        staff are equipped to handle the following
                                                                        Specialties:
                                                                    </p>
                                                                    <ul className="row">
                                                                        <div className="col-md-6 g-0 g-lg-5">
                                                                            <li>
                                                                                Audio and Media Transcription Services
                                                                            </li>
                                                                            <li>
                                                                                Financial Transcription Services
                                                                            </li>
                                                                            <li>Legal Transcription Services</li>
                                                                            <li>Medical Transcription Services</li>
                                                                            <li>
                                                                                Interview Transcription Services
                                                                            </li>
                                                                        </div>
                                                                        <div className="col-md-6 g-0 g-lg-5">
                                                                            <li>
                                                                                Investigation Transcription Services
                                                                            </li>
                                                                            <li>
                                                                                Call-in / Phone-in Dictation System
                                                                            </li>
                                                                            <li>
                                                                                Insurance Transcription Services
                                                                            </li>
                                                                            <li>Business Transcription Services</li>
                                                                        </div>
                                                                    </ul>
                                                                    <p>
                                                                        Guaranteed rapid turnaround and on-time
                                                                        delivery. Every single time!
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionItem>

                                    <AccordionItem header="Survey Scripting & Hosting">
                                        <div className="reasearch-solution-content">
                                            <h6>Survey Scripting &amp; Hosting</h6>
                                            <p>
                                                At Frequent Research, we are an experienced and
                                                qualified team of professionals who have proven
                                                expertise in research and programming on leading
                                                survey programming platforms. We have also worked
                                                across a wide variety of sectors and industries,
                                                programming their complex surveys at competitive
                                                prices.
                                            </p>
                                            <p>
                                                We have stringent quality assurance procedures in
                                                our survey programming process. Frequent Research
                                                provides Fastest ,easiest and just plain best survey
                                                solution to all its valuable clients for their
                                                Survey Programming needs in Decipher by getting the
                                                license from their End.
                                            </p>
                                            <p>
                                                Frequent Have the experienced and dedicated team of
                                                professionals in-house who have the deep knowledge
                                                of Market Research Process and always strives to
                                                provide the Robust and Flexible research solution to
                                                our clients.
                                            </p>
                                            <p>
                                                Frequent Research offers innovative, superior
                                                programming market surveys that are flexible,
                                                robust, dependable and scalable in supporting our
                                                clients’ ever-changing needs in this dynamic arena
                                                of market research.
                                                <strong> Our Survey design and development services include
                                                    :
                                                </strong>
                                            </p>

                                            <ul className="survey-box">
                                                <li>
                                                    <img
                                                        src="assets/img/survey-design.jpg"
                                                        alt="survey design"
                                                    />
                                                    <span className="text-center text-uppercase">
                                                        survey design
                                                    </span>
                                                </li>
                                                <li>
                                                    <img
                                                        src="assets/img/survey-programming.jpg"
                                                        alt="survey design"
                                                    />
                                                    <span className="text-center text-uppercase">
                                                        survey programming
                                                    </span>
                                                </li>
                                                <li>
                                                    <img
                                                        src="assets/img/survey-website-hosting.jpg"
                                                        alt="survey design"
                                                    />
                                                    <span className="text-center text-uppercase">
                                                        survey website hosting
                                                    </span>
                                                </li>
                                                <li>
                                                    <img
                                                        src="assets/img/database-support.jpg"
                                                        alt="survey design"
                                                    />
                                                    <span className="text-center text-uppercase">
                                                        database support
                                                    </span>
                                                </li>
                                                <li>
                                                    <img
                                                        src="assets/img/real-time.jpg"
                                                        alt="survey design"
                                                    />
                                                    <span className="text-center text-uppercase">
                                                        Online and real-time monitoring tools
                                                    </span>
                                                </li>
                                            </ul>

                                            <p>
                                                {" "}
                                                We can customize web-surveys as per your
                                                requirements for extensive graphics and software
                                                capabilities like Flash Shop-shelves, Page
                                                turnaround, movies, Card sort, magnifiers,
                                                Back-ground downloads, complex logics ,Games
                                                .Pictorial advertisements with related questions,
                                                passwords, can also be incorporated in the surveys.
                                            </p>
                                            <p>
                                                {" "}
                                                Our programmers possess the expertise of programming
                                                multilingual surveys in European and Oriental
                                                languages.We have devised several survey
                                                methodologies and system which are applicable as per
                                                our client’s requisites. For Online Survey
                                                Programming, we make use of methods like
                                                Internet-based collection and dissemination of
                                                survey data by programming questionnaires as well as
                                                establishing a secure infrastructure where responses
                                                can be stored and reported.
                                            </p>
                                            <p>
                                                {" "}
                                                Our programmers are trained and highly skilled in
                                                using programming languages and web technologies
                                                like Active Server Pages, HTML, DHTML, Front Page,
                                                VB Script, JavaScript, Active X Controls etc. Our
                                                Team has extensive experience in multiple
                                                programming packages that support multiple
                                                requirements of the clients.This has enabled us to
                                                customized the needs of our clients to provide them
                                                unparalleled services that will radically improve
                                                performance related to speed, quality and cost of
                                                business processes and services.
                                            </p>
                                            <p>
                                                {" "}
                                                We are proficient in using Confirm
                                                IT,Kinesis,SPSS-Dimesion,Decipher, Nebu etc. We are
                                                also flexible in adapting ourselves to any other
                                                survey programming software depending upon the
                                                current needs and requirement of our .This helps us
                                                in creating the perfect look, feel, navigation and
                                                flow to engage the interviewee with the survey.
                                                Thus, Frequent Research provides seamless integration of
                                                technology to online surveys.
                                            </p>
                                            <h6>Our Programming Capabilities</h6>
                                            <ul className="programming-capabilities row">
                                                <div className="col-sm-6 col-lg-3 g-4">
                                                    <li>
                                                        <i class="fa-solid fa-star"></i>
                                                        Multi-mode data collection (Web, Telephone,
                                                        Mobile, Paper)
                                                    </li>
                                                </div>
                                                <div className="col-sm-6 col-lg-3 g-4">
                                                    <li>
                                                        {" "}
                                                        <i class="fa-solid fa-star"></i>
                                                        Customized error messaging
                                                    </li>
                                                </div>
                                                <div className="col-sm-6 col-lg-3 g-4">
                                                    <li>
                                                        {" "}
                                                        <i class="fa-solid fa-star"></i>
                                                        Rolling back of incomplete surveys
                                                    </li>
                                                </div>
                                                <div className="col-sm-6 col-lg-3 g-4">
                                                    <li>
                                                        {" "}
                                                        <i class="fa-solid fa-star"></i>
                                                        Translation of survey in various European and
                                                        Asian Languages
                                                    </li>
                                                </div>
                                                <div className="col-sm-6 col-lg-3 g-4">
                                                    <li>
                                                        {" "}
                                                        <i class="fa-solid fa-star"></i>
                                                        Advanced quota and user re-direct settings
                                                    </li>
                                                </div>
                                                <div className="col-sm-6 col-lg-3 g-4">
                                                    <li>
                                                        {" "}
                                                        <i class="fa-solid fa-star"></i>
                                                        Real-time reporting and data analysis
                                                    </li>
                                                </div>
                                                <div className="col-sm-6 col-lg-3 g-4">
                                                    <li>
                                                        {" "}
                                                        <i class="fa-solid fa-star"></i>
                                                        Research data storage in a centralized
                                                        depository
                                                    </li>
                                                </div>
                                                <div className="col-sm-6 col-lg-3 g-4">
                                                    <li>
                                                        {" "}
                                                        <i class="fa-solid fa-star"></i>
                                                        Exporting and importing the data (SPSS, CSV,
                                                        ASCII and others)
                                                    </li>
                                                </div>
                                            </ul>
                                            <h6>Some Of Our Unique Delivery Standards Are:</h6>
                                            <ul className="Unique-Delivery row">
                                                <div className="col-md-6 g-5">
                                                    <li>
                                                        <i class="fa-regular fa-circle-check"></i>
                                                        Feature-rich, intuitive and flexible surveys
                                                    </li>
                                                </div>
                                                <div className="col-md-6 g-5">
                                                    <li>
                                                        <i class="fa-regular fa-circle-check"></i>
                                                        Programming of complex conjoint and max diff
                                                        designs
                                                    </li>
                                                </div>

                                                <div className="col-md-6 g-5">
                                                    <li>
                                                        <i class="fa-regular fa-circle-check"></i>
                                                        Management of complex quota solutions, including
                                                        least fill and priority selection logic
                                                    </li>
                                                </div>

                                                <div className="col-md-6 g-5">
                                                    <li>
                                                        <i class="fa-regular fa-circle-check"></i>
                                                        Embedded audio/video testing
                                                    </li>
                                                </div>

                                                <div className="col-md-6 g-5">
                                                    <li>
                                                        <i class="fa-regular fa-circle-check"></i>
                                                        Designing of Image maps / Text or Image
                                                        highlighter solutions utilized for ad and
                                                        concept testing
                                                    </li>
                                                </div>

                                                <div className="col-md-6 g-5">
                                                    <li>
                                                        <i class="fa-regular fa-circle-check"></i>
                                                        Programming of surveys in over 40 languages,
                                                        including double-byte languages
                                                    </li>
                                                </div>

                                                <div className="col-md-6 g-5">
                                                    <li>
                                                        <i class="fa-regular fa-circle-check"></i>
                                                        Building of custom sliders, drag &amp; drop,
                                                        image magnifier and page turner
                                                    </li>
                                                </div>

                                                <div className="col-md-6 g-5">
                                                    <li>
                                                        <i class="fa-regular fa-circle-check"></i>
                                                        Engaging user experience with HTML5 and CSS
                                                        customizations
                                                    </li>
                                                </div>

                                                <div className="col-md-6 g-5">
                                                    <li>
                                                        <i class="fa-regular fa-circle-check"></i>
                                                        Creating custom templates and survey layouts to
                                                        reflect client brand
                                                    </li>
                                                </div>

                                                <div className="col-md-6 g-5">
                                                    <li>
                                                        <i class="fa-regular fa-circle-check"></i>
                                                        Designing of tracking studies, with a strong
                                                        focus on consistency and accuracy in data
                                                        collection and data mapping
                                                    </li>
                                                </div>
                                            </ul>
                                            <h6>Our Survey Tool Expertise Includes:</h6>
                                            <p>
                                                {" "}
                                                Our survey programming team has good hands on
                                                experience in tools like:
                                            </p>
                                            <ul className="Tool-Expertise row">
                                                <div className="col-sm-6 col-md-3 g-5">
                                                    <li>Confirmit</li>
                                                </div>
                                                <div className="col-sm-6 col-md-3 g-5">
                                                    <li>Decipher</li>
                                                </div>

                                                <div className="col-sm-6 col-md-3 g-5">
                                                    <li>Kinesis</li>
                                                </div>

                                                <div className="col-sm-6 col-md-3 g-5">
                                                    <li>SPSS Dimensions</li>
                                                </div>

                                                <div className="col-sm-6 col-md-3 g-5">
                                                    <li>Qualtrics</li>
                                                </div>

                                                <div className="col-sm-6 col-md-3 g-5">
                                                    <li>Nebu</li>
                                                </div>

                                                <div className="col-sm-6 col-md-3 g-5">
                                                    <li>Sawtooth</li>
                                                </div>

                                                <div className="col-sm-6 col-md-3 g-5">
                                                    <li>Survey Gizmo</li>
                                                </div>
                                            </ul>
                                            <p> &nbsp;</p>
                                            <p>
                                                {" "}
                                                Our aim is to partner with every client by offering
                                                reliable and high-quality services at
                                                internationally competitive prices.
                                            </p>
                                            <p>
                                                {" "}
                                                Frequent Research Solutions is a trusted survey
                                                programming service providing the company with more
                                                than a decade worth of experience in catering to
                                                global clients. Our survey programming services are
                                                highly specialized such that we can increase the
                                                rate of response by effectively engaging the
                                                respondents and creating the best survey or
                                                questionnaire experience. If you are looking for
                                                affordable, high-quality, and reliable survey
                                                programming services, we are your right pick.
                                            </p>
                                        </div>
                                    </AccordionItem>
                                </div>
                            </div>
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuantitativeSolution