import React from 'react'
import Header from '../../Components/Common/Header'
import InnerBanner from '../../Components/Common/InnerBanner'
import Affiliations from '../../Components/Common/Affiliations'
import Footer from '../../Components/Common/Footer'
import DataExploration from '../../Components/AnalyticSolution/DataExploration'

const DataExplorationPage = () => {
  return (
    <>
        <Header/>
        <InnerBanner title={'data exploration and visualization'} menu={'Data Exploration And Visualization'}/>
        <DataExploration/>
        <Affiliations/>
        <Footer/>
    </>
  )
}

export default DataExplorationPage