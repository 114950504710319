import React from 'react'

const FoodList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i class="fa-solid fa-circle-check"></i>Dietary Preference</li>
                            <li><i class="fa-solid fa-circle-check"></i>Cuisine </li>
                            <li><i class="fa-solid fa-circle-check"></i>Restaurant Visitor</li>
                            <li><i class="fa-solid fa-circle-check"></i>favorite food or beverage brands?</li>
                            <li><i class="fa-solid fa-circle-check"></i>Gluten Sensitivity User</li>
                            <li><i class="fa-solid fa-circle-check"></i>Alchohol Cosnumption</li>
                            <li><i class="fa-solid fa-circle-check"></i>Frequency of non-alcoholic beverage consumption</li>
                            <li><i class="fa-solid fa-circle-check"></i>Frequency of regular and diet soda consumption</li>
                            <li><i class="fa-solid fa-circle-check"></i>Brands of regular and diet soda</li>
                            <li><i class="fa-solid fa-circle-check"></i>Frequency of alcohol consumption</li>
                            <li><i class="fa-solid fa-circle-check"></i>Frequency of beer consumption</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FoodList