import React from 'react'
import Header from '../../Components/Common/Header'
import InnerBanner from '../../Components/Common/InnerBanner'
import Affiliations from '../../Components/Common/Affiliations'
import Footer from '../../Components/Common/Footer'
import TaxRisk from '../../Components/TaxConsulting/TaxRisk'

const TaxRiskPage = () => {
  return (
    <>
        <Header/>
        <InnerBanner title={'tax risk management'} menu={'Tax Risk Management'}/>
        <TaxRisk/>
        <Affiliations/>
        <Footer/>
    </>
  )
}

export default TaxRiskPage