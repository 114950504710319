import React from 'react'

const PrivacyContent = () => {
    return (
        <>
            <div className="section-padding" id='privacy'>
                <div className="container">
                    <div className="row">
                        <div class="entry-content">
                            <h5>Last Updated: January 13, 2020</h5>
                            <p>This privacy policy (“Policy”) is posted by Frequent Research
                                Fieldwork Solutions (“we,” “us,” or “Frequent Research Fieldwork
                                Solutions”), and governs uses of Frequent Research Fieldwork Solution”s
                                main website, located at https://www.frequentresearch.com and including
                                all individual pages or links under that domain (the “Site”). This
                                Policy also governs our collection and use of personal information via
                                the Site or other communications&nbsp;or survey research&nbsp;under the
                                Frequent Research Fieldwork Solutions&nbsp;or “Survey
                                Surfing”&nbsp;brand names. We”ve developed this Policy to describe (1)
                                how we collect, use, transfer, and store information of individuals
                                (“you” or “your”); (2) your choices regarding your personal information;
                                and (3) how we ensure your privacy.</p>
                            <p>Please note that other Frequent Research Fieldwork Solutions products
                                or services, including but not limited to Frequent Research Fieldwork
                                Solution”s Insights platform and the Turn2Opinion or Myfrequentreward
                                website, may be governed by separate privacy policies. This Policy only
                                applies to the Site, and to the data that we collect through the Site or
                                other Frequent Research Fieldwork Solutions or Survey Surfing-branded
                                communications or surveys. It does not apply to any data that we collect
                                via other websites where this Policy is not posted.</p>
                            <p>We make the Site, including this Policy, accessible to individuals
                                with disabilities. If you are having trouble accessing the Site or this
                                Policy, please contact us as provided below.</p>
                            <p>By using the Site or participate in Frequent Research Fieldwork
                                Solutions branded or Survey Surfing branded research, you consent to the
                                terms of this Policy, and this Policy forms a binding agreement between
                                you and Frequent Research Fieldwork Solutions. If you do not agree with
                                the terms of this Policy, do not use the Site or participate in such
                                research. Continued use of the Site or participation in such research
                                constitutes your freely given, specific, and informed consent and
                                agreement to the terms herein.</p>
                            <h5>Notice to Survey Respondents</h5>
                            <p>You acknowledge and agree that your participation in a survey or
                                other market research program is completely voluntary, and that Frequent
                                Research Fieldwork Solution”s use of your personal information is
                                carried out with your consent. Frequent Research Fieldwork Solutions may
                                collect personal information directly from you when you voluntarily
                                provide it to Frequent Research Fieldwork Solutions, or Frequent
                                Research Fieldwork Solutions may obtain personal information from
                                unrelated parties we collaborate with, including third-party panels who
                                have obtained your consent.</p>
                            <p>You may also provide personal information to Frequent Research
                                Fieldwork Solutions via the Survey Surfing website, which respondents
                                may access directly, or via invitations or advertisements on third-party
                                websites. Information provided via Survey Surfing will be used by
                                Frequent Research Fieldwork Solutions to direct respondents to
                                third-party surveys, and may in some cases be used to recruit members
                                for Turn2Opinon, MyFrequentReward, Frequent Research Fieldwork
                                Solution”s proprietary panel.</p>
                            <p>Frequent Research Fieldwork Solutions also serves as a platform for
                                panellists who originate from third-party panels with the purpose of
                                sharing consumer opinions and data with Frequent Research Fieldwork
                                Solution”s clients. You acknowledge and agree that in such cases your
                                direct relationship is with the third-party panel, which determines the
                                purposes and means for processing your personal information. In such
                                cases, Frequent Research Fieldwork Solutions will only process your
                                personal information at the request of the third-party panel.</p>
                            <p>Any surveys and other market research programs administered by
                                Frequent Research Fieldwork Solutions clients, partners or other third
                                parties and the data collected in connection with those surveys and
                                other market research programs are not subject to this Policy. You are
                                strongly encouraged to review and understand the privacy policies of
                                such third parties.</p>
                            <p>By participating in any of our surveys or other market research
                                programs or any of our websites following any notice of a change to this
                                Policy, you freely and specifically give us your consent to collect,
                                use, transfer and disclose your personal information in the manner
                                specified in the then-current Privacy Policy.</p>
                            <h5>Your Personal Information&nbsp;</h5>
                            <p>Frequent Research Fieldwork Solutions conforms its privacy and
                                security practices to applicable laws, codes, and regulations,
                                including, without limitation, the General Data Protection Regulation
                                (“GDPR”)&nbsp;</p>
                            <p>As used in this Policy, “personal data” and “personal information”
                                mean information that identifies, relates to, describes, is capable of
                                being associated with, or could reasonably be linked with a particular
                                data subject, consumer, or household. Personal information includes
                                information such as real names, postal addresses, email addresses, and
                                social security numbers.</p>
                            <p>Frequent Research Fieldwork Solutions may collect personal
                                information when you voluntarily provide it, or when we receive it from
                                third parties who have secured your consent to share it. Your
                                participation in a survey or other research is voluntary. Surveys and
                                other research administered by Frequent Research Fieldwork Solution”s
                                clients, partners or other third parties and the information collected
                                therein are not subject to this Policy; provided, however, that if
                                Frequent Research Fieldwork Solutions collects personal information in
                                connection with a third party”s survey or research, such personal
                                information collection is subject to this Policy.</p>
                            <p>We use your information to operate, evaluate, and improve our
                                business, including for such purposes as developing new products and
                                services, managing our communications, and analysing our products. If
                                you provide us with your name and contact information, we will use this
                                information to answer requests from you, communicate with you regarding
                                any services we are providing to you, inform you about new products and
                                services (unless and until you opt-out of marketing information), and
                                deliver email updates, newsletters, or other communications to which
                                you”ve subscribed. We may also use information derived from cookies,
                                pixels, and other technologies to determine your interests in particular
                                surveys, advertisements, and other communications.</p>
                            <p>Any e-mail communications to you regarding new products and services,
                                or any email updates, newsletters, or other communications to which
                                you”ve subscribed, will contain a link with instructions for how to opt
                                out of future communications of that type.</p>
                            <h5>Combination</h5>
                            <p>We may combine information that your device transmits, information
                                that you actively submit, and information that we obtain from third
                                party sources, including suppliers of market research sample and data
                                marketing platforms that may direct you to our Site, as well as web
                                publishers, advertisers, and online service providers that may have
                                obtained information from you or your devices via cookies, pixels, and
                                other tracking technologies.</p>
                            <h5>Information Tracking Technologies&nbsp;</h5>
                            <p>We may use or allow third parties to use google analytics on our Site
                                to monitor the effectiveness of survey qualification. Google Analytics
                                are small, graphic images on web pages, web-based documents, or in email
                                messages that allow us or third parties to monitor who is visiting our
                                Site or if an email has been read. Google Analytics collect the IP
                                address of the device where the web beacon is sent, the URL of the page
                                where the web beacon originates, and the time it was viewed. Google
                                Analytics may be linked to your personal information. We may also use
                                third-party devices, services, links, or electronic mail to deliver
                                surveys to you, which may use cookies, Google Analytics, or other
                                technology to collect information about your visits to our Site (or
                                other sites) in order to present surveys that may be of interest to you.</p>
                            <p>We may use a pixel or cookie to examine what is happening on
                                third-party websites, including programs and sites with marketing and
                                advertising partners, to improve the understanding of site traffic and
                                market research effectiveness. Respondents or participants in survey or
                                survey panels that are hosted on, accessed by, or routed through the
                                Site should consult the privacy and other policies of third parties
                                through whom they are providing information.</p>
                            <h5>Use of Cookies&nbsp;</h5>
                            <p>For more information on our use of cookies, read Frequent Research
                                Fieldwork Solution”s Cookie Policy, which is incorporated herein by this
                                reference.</p>
                            <h5>Other Communications from Frequent Research Panel&nbsp;</h5>
                            <p>In addition to contacting individuals who have given us their
                                information, Frequent Research Fieldwork Solutions may also contact
                                individuals who have visited the Site or may otherwise have interest in
                                our products and services. All such communications from Frequent
                                Research Fieldwork Solutions will include a link allowing you to opt out
                                of future communications. You may also opt out by following the
                                instructions in the “Contacting Frequent Research Fieldwork Solutions”
                                section below.</p>
                            <h5>Information Retention and Accuracy&nbsp;</h5>
                            <p>We make efforts to ensure that personal information we receive,
                                process, or maintain is accurate, complete, and reliable. We rely on the
                                accuracy of the information provided directly to us but accept
                                responsibility for the management and confidentiality of the personal
                                information collected. In general, we keep personal information only as
                                long as we need it to provide you the services you requested. We may
                                also process data on behalf of third parties who have engaged us. We
                                keep personal information processed on behalf of third parties for as
                                long as needed to provide services to third party in question. However,
                                we reserve the right to retain personal information for any period
                                required by law or to comply with our legal obligations, resolve
                                disputes, and enforce our agreements.</p>
                            <h5>Protection of Personal Information&nbsp;</h5>
                            <p>Frequent Research Fieldwork Solutions uses reasonable security
                                measures in an effort to prevent loss, misuse, and alteration of
                                information under our control. However, we cannot guarantee the security
                                of information on or transmitted via the Internet. We rely on various
                                security procedures and systems to ensure the secure storage and
                                transmission of data, including encryption and authentication technology
                                licensed from third parties, to effect secure transmission of
                                confidential information. Additionally, we have absolutely no control
                                over the security of other sites you might visit, interact with, or do
                                business with.</p>
                            <p>If we learn of a security systems breach, we may attempt to notify
                                you electronically so that you can take appropriate protective steps, by
                                posting a notice on the Site if a security and/or sending an email to
                                you at the email address you have previously provided to us. Depending
                                on where you live, you may have a legal right to receive notice of a
                                security breach in writing. To receive a free written notice of a
                                security breach, or to withdraw your consent from receiving electronic
                                notice, you should notify us.</p>
                            <h5>Choice Regarding Collection, Use &amp; Distribution&nbsp;</h5>
                            <p>You can choose whether your personal information is (i) disclosed to a
                                third party or (ii) used for a purpose that is materially different
                                from the purpose(s) for which it was originally collected or
                                subsequently authorized by the individuals. If you choose to opt out,
                                please contact us via email at&nbsp;<a href="mailto:support@frequentresearch.com">support@frequentresearch.com</a>.
                                In such an event, you will be only able to access public areas of the
                                Site and may be limited in the use of the Site. In certain cases,
                                limiting the use and disclosure of your personal data may impact
                                functionality or prevent the use of Frequent Research Fieldwork
                                Solutions products or services.</p>
                            <h5>Third Party Websites and Content&nbsp;</h5>
                            <p>This Policy applies solely to the Site and to Frequent Research
                                Fieldwork Solution”s surveys and research, and not to any other product,
                                service, content, surveys or research of third parties.&nbsp;</p>
                            <p>The Site may also contain other links to external websites, and areas
                                where you can provide information to third parties. These are provided
                                for your convenience only, and we do not have control over the content
                                or privacy and security practices and policies of such third parties or
                                third-party sites. Any personal information you provide in such areas,
                                and on such linked pages, is provided directly to that third party and
                                is subject to that third party”s privacy policy. Please learn about the
                                privacy and security practices and policies of external websites and
                                third-parties before providing them with personal information.</p>
                            <h5>Access and Correction&nbsp;</h5>
                            <p>You may access your information by contacting us at the address
                                below. You have the right to correct, amend, or delete that information
                                where it is inaccurate, or has been processed in violation of the
                                Privacy Shield Principles, except where the burden or expense of
                                providing access would be disproportionate to the risks to the
                                individual”s privacy in the case in question, or where the rights of
                                persons other than the individual would be violated.</p>
                            <p>We maintain processes or mechanisms to allow you to review, update,
                                correct or delete personal information held by us. You may make changes
                                to your account information, access, correct, or delete personal
                                information held by Frequent Research Fieldwork Solutions by contacting
                                us at:</p>
                            <p><strong>Frequent Research Fieldwork Solutions</strong>
                                B 96, Pushpanjali Enclave, Pitampura Delhi – 110034</p>
                            <p>Delhi – 110085 (India) <br />
                                Email: <a href="mailto:Support@frequentresearch.com.">Support@frequentresearch.com.</a></p>
                            <p>To protect your privacy and security, Frequent Research Fieldwork
                                Solutions may also take reasonable steps to verify your identity before
                                making corrections to or deleting your information. We will respond to
                                your request for access to modify or delete your information within a
                                reasonable timeframe.</p>
                            <h5>Changes to this Privacy Policy&nbsp;</h5>
                            <p>We reserve the right to modify this Policy at any time. If we decide
                                to change our Policy, we will prominently post those changes here and
                                any other place we deem appropriate, so you are always aware of what
                                information we collect, how we use it, and under what circumstances, if
                                any, we disclose it. If we make any material changes, we will notify you
                                either by way of an email or by a notice on this Site. We will use
                                information in accordance with the Policy as it was in effect at the
                                time information was collected.</p>
                            <div class="entry-links"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyContent