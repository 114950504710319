import React from 'react'
import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';
import CountryList from '../B2B/CountryList';
import GenderList from '../B2B/GenderList';
import AgeList from '../B2B/AgeList';
import MaritalList from './AccordionItem/MaritalList';
import LanguageList from './AccordionItem/LanguageList';
import EthnicityList from './AccordionItem/EthnicityList';
import BusinessList from './AccordionItem/BusinessList';
import FinancialList from './AccordionItem/FinancialList';
import EducationList from './AccordionItem/EducationList';
import LifestyleList from './AccordionItem/LifestyleList';
import FoodList from './AccordionItem/FoodList';
import SmokingList from './AccordionItem/SmokingList';
import HealthList from './AccordionItem/HealthList';
import BeautyList from './AccordionItem/BeautyList';
import HouseholdList from './AccordionItem/HouseholdList';
import ParentingList from './AccordionItem/ParentingList';
import TravelList from './AccordionItem/TravelList';
import CarList from './AccordionItem/CarList';
import ComputerList from './AccordionItem/ComputerList';
import AutomativeList from './AccordionItem/AutomativeList';
import ElectronicList from './AccordionItem/ElectronicList';
import InternetList from './AccordionItem/InternetList';
import MobileList from './AccordionItem/MobileList';
import ShoppingList from './AccordionItem/ShoppingList';
import StoreList from './AccordionItem/StoreList';
import MarketList from './AccordionItem/MarketList';
import MediaList from './AccordionItem/MediaList';
import PetList from './AccordionItem/PetList';
import SportList from './AccordionItem/SportList';
import EnviromentList from './AccordionItem/EnviromentList';

const AccordionItem = ({ header, ...rest }) => (
    <Item
        {...rest}
        header={
            <>
                {header}
                <div className='chevron-icon'>
                    <i className="fa-solid fa-angle-right chevron"></i>
                </div>
            </>
        }
        className={"item"}
        buttonProps={{
            className: ({ isEnter }) =>
                `${"itemBtn key"} ${isEnter && "itemBtnExpanded"}`,
        }}
        contentProps={{ className: "itemContent" }}
        panelProps={{ className: "itemPanel" }}
    />
);
const KeyAttributesAccordionB2C = () => {
    return (
        <>
            <div className="section-padding" id='keyAttribute'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading text-center" data-aos="fade-up" data-aos-duration="800">
                                <h2>Key <span>Attributes</span></h2>
                            </div>
                        </div>
                    </div>

                    <div className={"styles.accordion"}>
                        <Accordion transition transitionTimeout={350}>
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <AccordionItem header="gender" >
                                        <GenderList />
                                    </AccordionItem>

                                    <AccordionItem header="age" >
                                        <AgeList />
                                    </AccordionItem>

                                    <AccordionItem header="Marital Status" >
                                        <MaritalList />
                                    </AccordionItem>

                                    <AccordionItem header="Primary Language" >
                                        <LanguageList />
                                    </AccordionItem>

                                    <AccordionItem header="ethnicity" >
                                        <EthnicityList />
                                    </AccordionItem>

                                    <AccordionItem header="business" >
                                        <BusinessList />
                                    </AccordionItem>

                                    <AccordionItem header="financial" >
                                        <FinancialList />
                                    </AccordionItem>

                                    <AccordionItem header="education" >
                                        <EducationList />
                                    </AccordionItem>

                                    <AccordionItem header="Lifestyle & Entertainment Profile" >
                                        <LifestyleList />
                                    </AccordionItem>


                                    <AccordionItem header="Food and Beverages" >
                                        <FoodList />
                                    </AccordionItem>

                                    <AccordionItem header="Smoking  & Tobacco" >
                                        <SmokingList />
                                    </AccordionItem>

                                    <AccordionItem header="Health, Fitness, and Medical" >
                                        <HealthList />
                                    </AccordionItem>

                                    <AccordionItem header="Beauty & Fashion" >
                                        <BeautyList />
                                    </AccordionItem>

                                    <AccordionItem header="household" >
                                        <HouseholdList />
                                    </AccordionItem>

                                    <AccordionItem header="parenting" >
                                        <ParentingList />
                                    </AccordionItem>

                                    <AccordionItem header="travel" >
                                        <TravelList />
                                    </AccordionItem>

                                    <AccordionItem header="car owners" >
                                        <CarList />
                                    </AccordionItem>

                                    <AccordionItem header="computer & video games" >
                                        <ComputerList />
                                    </AccordionItem>

                                    <AccordionItem header="automotive" >
                                        <AutomativeList />
                                    </AccordionItem>

                                    <AccordionItem header="Electronics and Technology Adoption" >
                                        <ElectronicList />
                                    </AccordionItem>

                                    <AccordionItem header="Computers and Internet Usage" >
                                        <InternetList />
                                    </AccordionItem>

                                    <AccordionItem header="Mobile & Technology" >
                                        <MobileList />
                                    </AccordionItem>

                                    <AccordionItem header="shopping" >
                                        <ShoppingList />
                                    </AccordionItem>

                                    <AccordionItem header="stores and restaurants" >
                                        <StoreList />
                                    </AccordionItem>

                                    <AccordionItem header="market research" >
                                        <MarketList />
                                    </AccordionItem>

                                    <AccordionItem header="media & telivision" >
                                        <MediaList />
                                    </AccordionItem>

                                    <AccordionItem header="pet ownership" >
                                        <PetList />
                                    </AccordionItem>

                                    <AccordionItem header="sports" >
                                        <SportList />
                                    </AccordionItem>

                                    <AccordionItem header="environment / green" >
                                        <EnviromentList />
                                    </AccordionItem>

                                    <AccordionItem header="country" >
                                        <CountryList />
                                    </AccordionItem>
                                </div>
                            </div>
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KeyAttributesAccordionB2C