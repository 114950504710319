import React from 'react'

const IndustryList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i className="fa-solid fa-circle-check"></i>Agriculture</li>
                            <li><i className="fa-solid fa-circle-check"></i>Manufacturing</li>
                            <li><i className="fa-solid fa-circle-check"></i>Service Industry</li>
                            <li><i className="fa-solid fa-circle-check"></i>Govt,Public &amp; Defense Industry</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default IndustryList