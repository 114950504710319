import React from 'react'
const SuccessPopupCareer = (props) => {
    const handleClose = () => {
        props.handleSuccessToggle()
        window.location.href=("/career")
    }
    return (
        <>
            <div class="success-popup-body" style={{ display: props.showSuccess ? "block" : "none" }}>
                <div className="popup-success">
                    <div className="head">
                        <h3>Thank You</h3>
                    </div>
                    <div className="body">
                        <i class="fa-solid fa-hands-praying"></i>
                        <p>Your submission has been received. Our Human Resource representative will contact you soon.</p>
                        <button type='button' className='success-close' onClick={handleClose}>OK</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SuccessPopupCareer