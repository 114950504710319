import React from 'react'
import corevalue from '../../media/CoreValue.json'
import apiCall from '../../modules/apiCall'

const CoreValueCard = () => {
    const [data, setData] = React.useState([])
    React.useEffect(() => {
        apiCall("/comp/CoreValue")
            .then((res) => console.log(res))
            .catch((err) => console.log(err))
    }, [])
    return (
        <>
            <div className="row core-value">
                {corevalue.corevalue.map((item, i) => (
                    <div className="col-12 g-5" key={i}>
                        <div className="core-value-card" data-aos="fade-up" data-aos-duration="800">
                            <div className="col-12 col-md-2">
                                <div className="image-wrapper">
                                    <img src={item.image} alt={item.title} />
                                </div>
                            </div>

                            <div className="col-12 col-md-10">
                                <div className="content-wrapper">
                                    <h4>{item.title}</h4>
                                    <p>
                                        {item.content}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default CoreValueCard