import React from 'react'
import department from "../../media/department.json"
import Image from '../Common/Image';
const DepartmentList = () => {
    return (
        <div className="row mb-5">
            {department.department.map((n, i) => {
                return (<>
                    <div className="col-6 col-md-4 d-flex align-items-center country" key={i}>
                        <div className="icon-img me-4">
                            <Image link={n.departmentlink} folder="department" />
                        </div>
                        <div className="icon-name">
                            <span>{n.departmentname}</span>
                        </div>
                    </div>
                </>)
            })}
        </div>
    )
}

export default DepartmentList