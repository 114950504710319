import React, { createContext } from 'react'

export const PopupContext = createContext()
const Popup = (props) => {
    const [showDownload, setShowDownload] = React.useState(false)
    const [showForm, setShowForm] = React.useState(false)
    const [showSuccess, setShowSuccess] = React.useState(false)
    const [showError, setShowError] = React.useState(false)

    const handleDownloadToggle = () => {
        setShowDownload(!showDownload)
    }

    const handleFormToggle = () => {
        setShowForm(!showForm)
    }

    const handleSuccessToggle = () => {
        setShowSuccess(!showSuccess)
    }

    const handleErrorToggle = () => {
        setShowError(!showError)
    }

    return (
        <PopupContext.Provider value={{ showDownload, showForm, showSuccess, showError, handleDownloadToggle, handleFormToggle, handleSuccessToggle, handleErrorToggle }}>
            {props.children}
        </PopupContext.Provider>

    )
}

export default Popup