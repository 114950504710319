import React from 'react'
import quality from "../../media/cati/cati_projects_control.json"
import Image from '../Common/Image'
const ProjectQuality = () => {
    return (
        <>
            <div className="section-padding" id='project'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-heading text-center mb-5">
                                <h2>Projects Quality Control</h2>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {quality.Projects.data.map((item) => (
                            <div className="col-sm-6 col-md-4 g-5">
                                <div class="project-item">
                                    <p> {item}  </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProjectQuality