import React from 'react'

const AutomativeList = () => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="accordion-list">
                        <ul>
                            <li><i className="fa-solid fa-circle-check"></i>Automobile Ownership</li>
                            <li><i className="fa-solid fa-circle-check"></i>Number of vehicles owned by individual and / or household</li>
                            <li><i className="fa-solid fa-circle-check"></i>Number of drivers in household</li>
                            <li><i className="fa-solid fa-circle-check"></i>Types of vehicles owned/leased</li>
                            <li><i className="fa-solid fa-circle-check"></i>Make of vehicles owned/leased</li>
                            <li><i className="fa-solid fa-circle-check"></i>Purchase vs. leased</li>
                            <li><i className="fa-solid fa-circle-check"></i>Ownership of motorcycles, RVs, ATVs, etc.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AutomativeList